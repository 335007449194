import React from 'react'
import img8 from '../../images/img8.png'
const Home13 = () => {
  return (
    <section className='home_0013' style={{ display: 'none' }}>
      <div className='container h-100'>
        <div className='row align-items-center h-100'>
          <div className='col-12 col-lg-6 col-md-6 or2 '>
            <img src={img8} alt='img8' className='img-fluid show_mob' />
          </div>
          <div className='col-12 col-lg-5 col-md-5 mx-auto or1'>
            <div className='z-in'>
              <h5>We offer</h5>
              <h2>
                Assessment <br />
                Based Solutions
              </h2>
              <h5 className='mb20'>
                Are muscle imbalances causing
                <br />
                your pain?
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home13