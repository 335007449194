import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { cross_origin, EXERCISE_TIMES } from '../../../utils/constants'
import { Button } from 'react-bootstrap'
import { connect, useDispatch } from 'react-redux'
import { updateUserProgramDay, updateProgramDayStatus } from '../../../actions/user_program'
import small_btn from '../../../css/beta.css'
import { useReactToPrint } from 'react-to-print';
import Broken from "../../images/NoImages.png";
import Table from "react-bootstrap/Table";
import { CommonPageData } from '../../../styles/common.style';
import { FaChevronRight } from "react-icons/fa";

const Assessment2Day1 = ({ exercises, updateUserProgramDay, programDayUpdateSuccess, dayId, programId, exercisePercentage, timePercentage, repsPercentage, updateProgramDayStatus, weeklySummary, week, setSelectDay, selectDay }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const printRef = useRef(null)
  const [finishBtn, setFinishBtn] = useState(false)

  console.log("selectDay", selectDay);

  useEffect(() => {
    if ((repsPercentage && repsPercentage != null && repsPercentage > 0) || (timePercentage && timePercentage != null && timePercentage > 0)) {
      setFinishBtn(true)
    }
  }, [exercisePercentage, timePercentage, repsPercentage])

  const handleFinishDayExercise = () => {
    updateUserProgramDay(dayId)
  }

  const changeDayId = (id, program_id) => {
    let dt = {
      is_active: 1,
      program_id: program_id,
    }
    updateProgramDayStatus(dt, id)
  }

  if (programDayUpdateSuccess) {
    dispatch({ type: 'USER_PROGRAM_UPDATE_PURGE' });
    if (localStorage.getItem('nextActiveDay') !== null && localStorage.getItem('nextActiveDay') !== undefined) {
      changeDayId(localStorage.getItem('nextActiveDay'), programId)
    }
    localStorage.setItem('lastActiveDayId', dayId)
    navigate(`/program/${programId}`, { replace: true });
  }

  const handleClick = (date) => {
    setSelectDay(date)
    // You can replace this with any action you want
  };

  const handleBack = () => {
    setSelectDay(null);
  };



  const handlePrintSummary = useReactToPrint({
    content: () => printRef.current,
  });

  const countAverageReps = (data) => {
    let averageReps = 0
    let repsCount = data.length
    data.map((item, key) => {
      averageReps += averageReps + item.reps
    })
    return Math.round(averageReps / repsCount)
  }

  function getSeconds(timeString) {
    if (timeString && timeString != 0) {
      const [minutes, seconds] = timeString.split(' ');
      const totalSeconds = parseInt(minutes) * 60 + parseInt(seconds);
      return totalSeconds;
    }
    return 0;
  }

  function getMinTime(totalSeconds) {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}min ${seconds}sec`;
  }

  const countAverageTime = (data) => {
    let averageTime = 0
    let timeCount = data.length
    data.map((item, key) => {
      averageTime += getSeconds(item.user_time)
    })
    if (averageTime != 0) {
      return getMinTime(averageTime / timeCount)
    }
    return 0
  }

  function getTimeValue(str) {
    let result = 0;
    var regex = /(\d+[a-z]+)/g;
    let match = regex.exec(str);
    while (match != null) {
      var match_str = match[0];
      var last_char = match_str[match_str.length - 1];
      if (last_char == 'h')
        result += parseInt(match_str) * 3600;
      if (last_char == 'min')
        result += parseInt(match_str) * 60;
      if (last_char == 'sec')
        result += parseInt(match_str);
      match = regex.exec(str);
    }
    return result;
  }

  const convertToTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const remainingSeconds = totalSeconds % 60;
    return `${minutes} min ${remainingSeconds} sec`;
  };

  const goToHome = () => {
    navigate(`/program/${programId}`, { replace: true });
  }

  // Function to generate date range
  const getDateRange = (start, end) => {
    const dates = [];
    let currentDate = new Date(start);
    const endDate = new Date(end);

    while (currentDate <= endDate) {
      // Format date as "January 1, 2025"
      const options = { month: "long", day: "numeric", year: "numeric" };
      dates.push(currentDate.toLocaleDateString("en-US", options));

      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };

  // Function to generate date range
  const generateDateRange = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const dateRange = [];

    let currentDate = startDate;
    while (currentDate <= endDate) {
      dateRange.push(new Date(currentDate).toISOString());
      currentDate.setDate(currentDate.getDate() + 1); // Increment by one day
    }

    return dateRange;
  };

  const dates = generateDateRange(week?.start_at, week?.end_at);

  const formatDate = (isoDate, weekNumber) => {
    const date = new Date(isoDate);
    const formattedDate = date.toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" });
    return formattedDate;
  };


  return (
    <CommonPageData>
      <section className='assestmentd1_002'>
        <div className='container'>
          <div id='print-content' ref={printRef}>
            <div className='row'>
              <div className='col-12 col-lg-12 col-md-12 text-center'>
                <ul className='suggested'>
                  <li className='active'>Suggested</li>
                  <li>Actual</li>
                </ul>
              </div>
              <div className='col-12 col-lg-4 col-md-4'>
                <div className='box_p shadow_box'>
                  <h6>Time</h6>
                  <div className='progress_bar'>
                    <div
                      role='progressbar'
                      aria-valuenow={
                        timePercentage > 0 && timePercentage < 100
                          ? Math.round(timePercentage)
                          : timePercentage > 100
                            ? 100
                            : 0
                      }
                      aria-valuemin='0'
                      aria-valuemax='100'
                      style={{
                        '--value':
                          timePercentage > 0 && timePercentage < 100
                            ? Math.round(timePercentage)
                            : timePercentage >= 100
                              ? 100
                              : 0
                      }}></div>
                  </div>
                </div>
              </div>

              <div className='col-12 col-lg-4 col-md-4'>
                <div className='box_p shadow_box'>
                  <h6>Exercises</h6>
                  <div className='progress_bar'>
                    <div
                      role='progressbar'
                      aria-valuenow={
                        exercisePercentage > 0 && exercisePercentage < 100
                          ? Math.round(exercisePercentage)
                          : exercisePercentage > 100
                            ? 100
                            : 0
                      }
                      aria-valuemin='0'
                      aria-valuemax='100'
                      style={{
                        '--value':
                          exercisePercentage > 0 && exercisePercentage < 100
                            ? Math.round(exercisePercentage)
                            : exercisePercentage >= 100
                              ? 100
                              : 0
                      }}></div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-lg-4 col-md-4'>
                <div className='box_p shadow_box'>
                  <h6>Reps</h6>
                  <div className='progress_bar'>
                    <div
                      role='progressbar'
                      aria-valuenow={
                        repsPercentage > 0 && repsPercentage < 100
                          ? Math.round(repsPercentage)
                          : repsPercentage > 100
                            ? 100
                            : 0
                      }
                      aria-valuemin='0'
                      aria-valuemax='100'
                      style={{
                        '--value':
                          repsPercentage > 0 && repsPercentage < 100
                            ? Math.round(repsPercentage)
                            : repsPercentage >= 100
                              ? 100
                              : 0
                      }}></div>
                  </div>
                </div>
              </div>
            </div>
            {weeklySummary === 1 && !selectDay && (
              <>
                <div className="container mt-4">
                  <div className='row g-3'>
                    {dates.map((date, index) => (
                      <div className='col-12 col-lg-3 col-md-3' key={index} >
                        <div
                          onClick={() => handleClick(date)}
                          style={{ cursor: 'pointer' }}
                          className="box_p shadow_box p-3 d-flex justify-content-between align-items-center border"
                        >
                          <span className="fw-bold">{formatDate(date)}</span>
                          <FaChevronRight />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )
            }

            {weeklySummary === 1 && selectDay && (
              <>
                <Button type="button" onClick={handleBack} className="common-btn-all-front">
                  Back
                </Button>
                <div className='row mt-3'>
                  <div className='col-12 col-lg-12 col-md-12'>
                    <ul className='nav nav-tabs row left_tabs slider_mobile ' role='tablist'>
                      {exercises.length > 0 ? (
                        exercises.map((item, key) => (
                          <li key={key} className="nav-item col-12 col-sm-12 col-lg- col-md-6">
                            <div className="summary_box summary_box-custom">
                              <h6>{item?.exercise ? item?.exerciseLevel?.file_name : ''}</h6>
                              <div className="common-box-bg-all">
                                <div className="summary_title_box">
                                  <Table className="text-center align-middle table-light">
                                    <thead>
                                      <tr>
                                        <th></th>
                                        <th>Circuit 1</th>
                                        <th>Circuit 2</th>
                                        <th>Circuit 3</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {!item?.userProgramDayExercisesAttempt?.length && (
                                        <>
                                          {item?.exerciseLevel?.suggested_weight_lbs && (
                                            <tr>
                                              <td className="text-danger fw-bold">S.W. :</td>
                                              <td>{item?.exerciseLevel?.suggested_weight_lbs} lbs</td>
                                              <td>--</td>
                                              <td>--</td>
                                            </tr>
                                          )}

                                          <tr>
                                            <td className="text-danger fw-bold">
                                              {item?.exerciseLevel?.metric === 10 ? "S.R. :" : "S.T. :"}
                                            </td>
                                            <td>
                                              {item?.exerciseLevel?.metric === 10
                                                ? item?.exerciseLevel?.reps || "-"
                                                : EXERCISE_TIMES[item?.exerciseLevel?.time] || "-"}
                                            </td>
                                            <td>--</td>
                                            <td>--</td>
                                          </tr>

                                          <tr>
                                            <td className="fw-bold">A.W. :</td>
                                            <td>{item?.user_lbs || "--"}</td>
                                            <td>--</td>
                                            <td>--</td>
                                          </tr>
                                        </>
                                      )}

                                      {item?.userProgramDayExercisesAttempt?.length > 0 && (
                                        <>
                                          <tr>
                                            <td className="fw-bold">{item?.exerciseLevel?.metric === 10 ? "A.R." : "A.T."} :</td>
                                            {Array.from({ length: 3 }).map((_, index) => (
                                              <td key={index}>
                                                {item?.userProgramDayExercisesAttempt[index]?.reps ||
                                                  item?.userProgramDayExercisesAttempt[index]?.duration ||
                                                  "--"}
                                              </td>
                                            ))}
                                          </tr>

                                          <tr>
                                            <td className="fw-bold">Notes:</td>
                                            {Array.from({ length: 3 }).map((_, index) => (
                                              <td key={`note-${index}`}>
                                                {item?.userProgramDayExercisesAttempt[index]?.note || "--"}
                                              </td>
                                            ))}
                                          </tr>
                                        </>
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))
                      ) : (
                        <div className="text-center mt-5 mb-5">
                          <h4 className='red-title'>No record found</h4>
                        </div>
                      )}
                    </ul>
                  </div>
                </div>
              </>


            )}

            {weeklySummary === 0 && (
              <div className='row mt-3'>
                <div className='col-12 col-lg-12 col-md-12'>
                  <ul className='nav nav-tabs row left_tabs slider_mobile ' role='tablist'>
                    {exercises.length > 0 ? (
                      exercises.map((item, key) => (
                        <li key={key} className="nav-item col-12 col-sm-12 col-lg- col-md-6">
                          <div className="summary_box summary_box-custom">
                            <h6>{item?.exercise ? item?.exerciseLevel?.file_name : ''}</h6>
                            <div className="common-box-bg-all">
                              <div className="summary_title_box">
                                <Table className="text-center align-middle table-light">
                                  <thead>
                                    <tr>
                                      <th></th>
                                      <th>Circuit 1</th>
                                      <th>Circuit 2</th>
                                      <th>Circuit 3</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {!item?.userProgramDayExercisesAttempt?.length && (
                                      <>
                                        {item?.exerciseLevel?.suggested_weight_lbs && (
                                          <tr>
                                            <td className="text-danger fw-bold">S.W. :</td>
                                            <td>{item?.exerciseLevel?.suggested_weight_lbs} lbs</td>
                                            <td>--</td>
                                            <td>--</td>
                                          </tr>
                                        )}

                                        <tr>
                                          <td className="text-danger fw-bold">
                                            {item?.exerciseLevel?.metric === 10 ? "S.R. :" : "S.T. :"}
                                          </td>
                                          <td>
                                            {item?.exerciseLevel?.metric === 10
                                              ? item?.exerciseLevel?.reps || "-"
                                              : EXERCISE_TIMES[item?.exerciseLevel?.time] || "-"}
                                          </td>
                                          <td>--</td>
                                          <td>--</td>
                                        </tr>

                                        <tr>
                                          <td className="fw-bold">A.W. :</td>
                                          <td>{item?.user_lbs || "--"}</td>
                                          <td>--</td>
                                          <td>--</td>
                                        </tr>
                                      </>
                                    )}

                                    {item?.userProgramDayExercisesAttempt?.length > 0 && (
                                      <>
                                        <tr>
                                          <td className="fw-bold">{item?.exerciseLevel?.metric === 10 ? "A.R." : "A.T."} :</td>
                                          {Array.from({ length: 3 }).map((_, index) => (
                                            <td key={index}>
                                              {item?.userProgramDayExercisesAttempt[index]?.reps ||
                                                item?.userProgramDayExercisesAttempt[index]?.duration ||
                                                "--"}
                                            </td>
                                          ))}
                                        </tr>

                                        <tr>
                                          <td className="fw-bold">Notes:</td>
                                          {Array.from({ length: 3 }).map((_, index) => (
                                            <td key={`note-${index}`}>
                                              {item?.userProgramDayExercisesAttempt[index]?.note || "--"}
                                            </td>
                                          ))}
                                        </tr>
                                      </>
                                    )}
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))
                    ) : (
                      <div className="text-center">
                        <p>No record found</p>
                      </div>
                    )}
                  </ul>
                </div>
              </div>
            )}

          </div>
          <div className='row'>
            <div className='col-12 col-lg-12 col-md-12 mt-3 display-flex-block-btn'>
              {finishBtn ? (
                <Button onClick={() => handleFinishDayExercise()} className='theme-common-btn-big'>
                  Finish
                </Button>
              ) : (
                <Button onClick={() => goToHome()} className='theme-common-btn-big'>
                  Go to Exercises
                </Button>
              )}
            </div>
          </div>
        </div>
      </section>
    </CommonPageData >
  );
}

const mapStateToProps = state => ({
  programDayUpdateRequest: state.programReducer.programDayUpdateRequest || null,
  programDayUpdateSuccess: state.programReducer.programDayUpdateSuccess || null,
  programDayUpdateFailure: state.programReducer.programDayUpdateFailure || null,
});
export default connect(mapStateToProps, { updateUserProgramDay, updateProgramDayStatus })(Assessment2Day1);