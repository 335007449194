import React, { useEffect, useState, useRef } from "react";
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import Header from "../../Layout/Header";
import { Col, Container, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import FadeLoader from "react-spinners/FadeLoader";
import { Link, useParams } from "react-router-dom";
import Footer from "../../Layout/Footer";
import { connect } from "react-redux";
import {
  getMusclesAnatomyData,
  getPainPointData,
} from "../../../actions/user_program";
import { Interweave } from "interweave";
import Broken from "../../images/NoImages.png";
import Modal from "react-bootstrap/Modal";
// import CustomVideoPlayer from "../AssessmentTimer/CustomVideoPlayer";
import TestAndMuscleVideoPlayer from "../AssessmentTimer/TestAndMuscleVideoPlayer";

import Gallery from "react-image-gallery";
import { FaPlay } from "react-icons/fa";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CommonPageData } from '../../../styles/common.style';
function DetailScreen({
  getMusclesAnatomyData,
  muscles_anatomy_detail,
  getPainPointData,
  pain_point_detail,
}) {
  const [hasMusclesAnatomy, setHasMusclesAnatomy] = useState(false);
  const [musclesAnatomyData, setMusclesAnatomyData] = useState(null);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [loader, setLoader] = useState(true);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const { id } = useParams();

  const [show, setShow] = useState(false);

  const [currentPlayVideo, setCurrentPlayVideo] = useState(0);

  const [muscleVideoArray, setMuscleVideoArray] = useState([]);
  const [painPointVideoArray, setPainPointVideoArray] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const settingsThumnail = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show one thumbnail at a time vertically
    slidesToScroll: 1,
    vertical: true, // Set the carousel to display vertically
  };

  useEffect(() => {
    const currentURL = window.location.href;
    const containsMusclesAnatomy = currentURL.includes("muscles-anatomy");
    setHasMusclesAnatomy(containsMusclesAnatomy);
    let count = 0;
    if (containsMusclesAnatomy) {
      getMusclesAnatomyData(id);
    } else {
      getPainPointData(id);
    }
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const topElement = document.querySelector(".videos"); // Replace 'top-element' with the actual ID of your top element
      if (topElement) {
        topElement.scrollIntoView({
          behavior: "smooth",
          block: "start", // Scroll to the top of the element
        });
      }
    }, 2000);
  }, []);

  // const videoModal = () => {
  //   console.log("test");
  // }

  const toggleVideoModal = (event) => {
    if (hasMusclesAnatomy) {
      let tempMuscleAnatomy = [];
      if (muscles_anatomy_detail?.data?.video) {
        let video1 = {
          uri: muscles_anatomy_detail?.data?.video,
          videoName: muscles_anatomy_detail?.data?.video_title,
          isSelected: true,
        };
        tempMuscleAnatomy.push(video1);
      }
      if (muscles_anatomy_detail?.data?.video2) {
        let video2 = {
          uri: muscles_anatomy_detail?.data?.video2,
          videoName: muscles_anatomy_detail?.data?.video_title2,
          isSelected: false,
        };
        tempMuscleAnatomy.push(video2);
      }
      if (muscles_anatomy_detail?.data?.video3) {
        let video3 = {
          uri: muscles_anatomy_detail?.data?.video3,
          videoName: muscles_anatomy_detail?.data?.video_title3,
          isSelected: false,
        };
        tempMuscleAnatomy.push(video3);
      }
      if (muscles_anatomy_detail?.data?.video4) {
        let video4 = {
          uri: muscles_anatomy_detail?.data?.video4,
          videoName: muscles_anatomy_detail?.data?.video_title4,
          isSelected: false,
        };
        tempMuscleAnatomy.push(video4);
      }
      setMuscleVideoArray(tempMuscleAnatomy);
      event.preventDefault();

      if (tempMuscleAnatomy.length > 0) {
        setIsVideoModalOpen(!isVideoModalOpen);
      }
    } else {
      let tempPainPoint = [];
      if (pain_point_detail?.data?.video) {
        let video1 = {
          uri: pain_point_detail?.data?.video,
          videoName: pain_point_detail?.data?.video_title,
          isSelected: true,
        };
        tempPainPoint.push(video1);
      }
      if (pain_point_detail?.data?.video2) {
        let video2 = {
          uri: pain_point_detail?.data?.video2,
          videoName: pain_point_detail?.data?.video_title2,
          isSelected: false,
        };
        tempPainPoint.push(video2);
      }
      if (pain_point_detail?.data?.video3) {
        let video3 = {
          uri: pain_point_detail?.data?.video3,
          videoName: pain_point_detail?.data?.video_title3,
          isSelected: false,
        };
        tempPainPoint.push(video3);
      }
      if (pain_point_detail?.data?.video4) {
        let video4 = {
          uri: pain_point_detail?.data?.video4,
          videoName: pain_point_detail?.data?.video_title4,
          isSelected: false,
        };
        tempPainPoint.push(video4);
      }

      setPainPointVideoArray(tempPainPoint);
      event.preventDefault();
      if (tempPainPoint.length > 0) {
        setIsVideoModalOpen(!isVideoModalOpen);
      }
    }
  };

  const renderItem = (item) => {
    if (item.original) {
      return <img src={item.original} alt={item.description} />;
    } else if (item.url) {
      return (
        // <video preload="auto" controls="" src={item?.url}></video>
        // <ReactPlayer url={item?.url} controls />
        <TestAndMuscleVideoPlayer
          autoPlay={true}
          videoUrl={item?.url ? item?.url : ""}
          className={"video"}
        />
        // <img src={item.original} alt={item.description} />
      );
    }

    return null;
  };


  const descriptionMucles = muscles_anatomy_detail?.data?.description || "No Data Available";

  // const modifiedDescriptionM = descriptionMucles.replace(
  //   /(<p>\s*<strong>BODY TYPE NOTE:<\/strong>)/g,
  //   '<p class="body-type-note"><strong>BODY TYPE NOTE:</strong>'
  // );
  
  const descriptionPainpoint = pain_point_detail?.data?.description || "No Data Available";

  // const modifiedDescriptionP = descriptionPainpoint.replace(
  //   /(<p>\s*<strong>BODY TYPE NOTES:<\/strong>)/g,
  //   '<p class="body-type-note"><strong>BODY TYPE NOTES:</strong>'
  // );

  return (
    <>
      <ScrollTopOnMount />
      <Header />
      <div className={'banner-recomended display-none'}>
        <Container className={'h-100'}>
          <Row className={'h-100'}>
            <Col className={'col-12 col-lg-6 col-md-6 m-auto'}>
              <div className='box_banner_inner abc text-center mx-auto'>
                <h3>{hasMusclesAnatomy ? 'Muscles Anatomy Details' : 'Trigger Point Pain Details'}</h3>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <CommonPageData>
        <div className='common-banner-block'>
          <div className='main-banner'>
            <img src='/images/banner-common-block.png' alt='img' />
          </div>
          <div className='container'>
            <div className='common-banner-block-inner'>
              <h1>{hasMusclesAnatomy ? 'Muscles Anatomy Details' : 'Trigger Point Pain Details'}</h1>
            </div>
          </div>
        </div>
        <section className='videos custom-width-vh videos-music-library-block'>
          <div className='container'>
            <div className='row'>
              {/* <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 '>
                <div className='videos_headings'>
                  <h2 className='detail_span' style={{ color: '#fff' }}>
                    {hasMusclesAnatomy ? 'Muscles Anatomy Details' : 'Trigger Point Pain Details'}
                  </h2>
                </div>
              </div> */}
              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 '>
                <Row className='wrapper mt-3 mb-3 custom-overflow-height'>
                  {hasMusclesAnatomy ? (
                    <>
                      <Col className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12  mb-md-6 mb-sm-6 mb-3'>
                        <>
                          <>
                            <div className='slider-main-block-details'>
                              <>
                                <div className='slider-main-block-details-inner-main'>
                                  <Slider
                                    asNavFor={nav2}
                                    ref={(slider1) => setNav1(slider1)}
                                    dots={true}
                                    infinite={true}
                                    fade={true}>
                                    <div className='library-inner-thumb-two-main '>
                                      <div className='library-inner-thumb-two common-box-bg-all'>
                                        <div className='block-img-two-sync'>
                                          <img
                                            src={muscles_anatomy_detail?.data?.image}
                                            onContextMenu={(e) => e.preventDefault()}
                                          />
                                        </div>
                                        <div className='detail-box'>
                                          <h6
                                            className='text-center'
                                            dangerouslySetInnerHTML={{
                                              __html: muscles_anatomy_detail?.data?.title || ''
                                            }}></h6>
                                          <h6
                                            className='text-center small-text '
                                            style={{ fontSize: 'small' }}
                                            dangerouslySetInnerHTML={{
                                              __html: muscles_anatomy_detail?.data?.title2 || ''
                                            }}></h6>
                                          {/* <div className='detail-box-img library-detail-img-box library-img-box'></div> */}
                                        </div>
                                      </div>
                                    </div>
                                    {muscles_anatomy_detail?.data?.image2 && (
                                      <div className='library-inner-thumb-two-main'>
                                        <div className='library-inner-thumb-two'>
                                          <div className='block-img-two-sync'>
                                            <img
                                              src={muscles_anatomy_detail?.data?.image2}
                                              onContextMenu={(e) => e.preventDefault()}
                                            />
                                          </div>
                                          <div className='detail-box'>
                                            <h6
                                              className='text-center'
                                              dangerouslySetInnerHTML={{
                                                __html: muscles_anatomy_detail?.data?.title || ''
                                              }}></h6>
                                            <h6
                                              className='text-center small-text '
                                              style={{ fontSize: 'small' }}
                                              dangerouslySetInnerHTML={{
                                                __html: muscles_anatomy_detail?.data?.title2 || ''
                                              }}></h6>
                                            {/* <div className='detail-box-img library-detail-img-box library-img-box'></div> */}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {muscles_anatomy_detail?.data?.image3 && (
                                      <div className='library-inner-thumb-two-main'>
                                        <div className='library-inner-thumb-two'>
                                          <div className='block-img-two-sync'>
                                            <img
                                              src={muscles_anatomy_detail?.data?.image3}
                                              onContextMenu={(e) => e.preventDefault()}
                                            />
                                          </div>
                                          <div className='detail-box'>
                                            <h6
                                              className='text-center'
                                              dangerouslySetInnerHTML={{
                                                __html: muscles_anatomy_detail?.data?.title || ''
                                              }}></h6>
                                            <h6
                                              className='text-center small-text '
                                              style={{ fontSize: 'small' }}
                                              dangerouslySetInnerHTML={{
                                                __html: muscles_anatomy_detail?.data?.title2 || ''
                                              }}></h6>
                                            {/* <div className='detail-box-img library-detail-img-box library-img-box'></div> */}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Slider>
                                  {/* <div className='detail-box mt-4'>
                                    <h6
                                      className='text-center mb-2'
                                      dangerouslySetInnerHTML={{
                                        __html: muscles_anatomy_detail?.data?.title || ''
                                      }}></h6>
                                    <h6
                                      className='text-center mb-3'
                                      style={{ fontSize: 'small' }}
                                      dangerouslySetInnerHTML={{
                                        __html: muscles_anatomy_detail?.data?.title2 || ''
                                      }}></h6>
                                    <div className='detail-box-img library-detail-img-box library-img-box'></div>
                                  </div> */}
                                </div>
                                <div className='slider-main-block-details-inner-thumb'>
                                  <div className='slider-main-block-details-inner-thumb-inner'>
                                    <Slider
                                      asNavFor={nav1}
                                      ref={(slider2) => setNav2(slider2)}
                                      // slidesToShow={2}
                                      slidesToShow={muscles_anatomy_detail?.imageCount}
                                      swipeToSlide={true}
                                      vertical={true}
                                      verticalSwiping={true}
                                      focusOnSelect={true}>
                                      <div className='library-inner-thumb'>
                                        <img
                                          src={muscles_anatomy_detail?.data?.image}
                                          onContextMenu={(e) => e.preventDefault()}
                                        />
                                      </div>
                                      {muscles_anatomy_detail?.data?.image2 && (
                                        <div className='library-inner-thumb'>
                                          <img
                                            src={muscles_anatomy_detail?.data?.image2}
                                            onContextMenu={(e) => e.preventDefault()}
                                          />
                                        </div>
                                      )}
                                      {muscles_anatomy_detail?.data?.image3 && (
                                        <div className='library-inner-thumb'>
                                          <img
                                            src={muscles_anatomy_detail?.data?.image3}
                                            onContextMenu={(e) => e.preventDefault()}
                                          />
                                        </div>
                                      )}
                                    </Slider>
                                  </div>
                                  <div
                                    className='library-inner-thumb-inner'
                                    onClick={(event) => toggleVideoModal(event)}>
                                    <div className='video-icon'>
                                      <FaPlay />
                                    </div>
                                    <div className='video-icon-inner'>
                                      <span className={'font-weight-bold'}>{muscles_anatomy_detail?.videoCount}</span>{' '}
                                      {muscles_anatomy_detail?.videoCount < 2 ? 'Video' : 'Videos'}
                                    </div>
                                  </div>
                                  {/* <div className={'text-center'}>
                                    <span className={'font-weight-bold'}>{muscles_anatomy_detail?.videoCount}</span>{' '}
                                    {muscles_anatomy_detail?.videoCount < 2 ? 'Video' : 'Videos'}
                                  </div> */}
                                </div>
                              </>
                            </div>
                          </>
                        </>
                      </Col>
                      <Col className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                        <div className='slide-content-details'>
                          <div className='slide-content-inner'>
                            <Interweave
                              content={
                                descriptionMucles
                              }
                            />
                          </div>
                        </div>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col className=' col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12  mb-md-6 mb-sm-6 mb-3 '>
                        <div className='slider-main-block-details'>
                          <>
                            <div className='slider-main-block-details-inner-main'>
                              <Slider
                                asNavFor={nav2}
                                ref={(slider1) => setNav1(slider1)}
                                dots={true}
                                infinite={true}
                                fade={true}>
                                <div className='library-inner-thumb-two-main'>
                                  <div className='library-inner-thumb-two common-box-bg-all'>
                                    <div className='block-img-two-sync'>
                                      <img src={pain_point_detail?.data?.image} />
                                    </div>
                                    <div className='detail-box mt-4'>
                                      <h6
                                        className='text-center mb-2'
                                        dangerouslySetInnerHTML={{
                                          __html: pain_point_detail?.data?.title || ''
                                        }}></h6>
                                      <h6
                                        className='text-center mb-3'
                                        style={{ fontSize: 'small' }}
                                        dangerouslySetInnerHTML={{
                                          __html: pain_point_detail?.data?.title2 || ''
                                        }}></h6>
                                      <div className='detail-box-img library-detail-img-box library-img-box'></div>
                                    </div>
                                  </div>
                                </div>
                                {pain_point_detail?.data?.image2 != null && (
                                  <div className='library-inner-thumb-two-main'>
                                    <div className='library-inner-thumb-two common-box-bg-all'>
                                      <div className='block-img-two-sync '>
                                        <img src={pain_point_detail?.data?.image2} />
                                      </div>
                                      <div className='detail-box mt-4'>
                                        <h6
                                          className='text-center mb-2'
                                          dangerouslySetInnerHTML={{
                                            __html: pain_point_detail?.data?.title || ''
                                          }}></h6>
                                        <h6
                                          className='text-center mb-3'
                                          style={{ fontSize: 'small' }}
                                          dangerouslySetInnerHTML={{
                                            __html: pain_point_detail?.data?.title2 || ''
                                          }}></h6>
                                        <div className='detail-box-img library-detail-img-box library-img-box'></div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {pain_point_detail?.data?.image3 != null && (
                                  <div className='library-inner-thumb-two-main'>
                                    <div className='library-inner-thumb-two common-box-bg-all'>
                                      <div className='block-img-two-sync '>
                                        <img src={pain_point_detail?.data?.image3} />
                                      </div>
                                      <div className='detail-box mt-4'>
                                        <h6
                                          className='text-center mb-2'
                                          dangerouslySetInnerHTML={{
                                            __html: pain_point_detail?.data?.title || ''
                                          }}></h6>
                                        <h6
                                          className='text-center mb-3'
                                          style={{ fontSize: 'small' }}
                                          dangerouslySetInnerHTML={{
                                            __html: pain_point_detail?.data?.title2 || ''
                                          }}></h6>
                                        <div className='detail-box-img library-detail-img-box library-img-box'></div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Slider>
                              {/* <div className='detail-box mt-4'>
                                <h6
                                  className='text-center mb-2'
                                  dangerouslySetInnerHTML={{
                                    __html: pain_point_detail?.data?.title || ''
                                  }}></h6>
                                <h6
                                  className='text-center mb-3'
                                  style={{ fontSize: 'small' }}
                                  dangerouslySetInnerHTML={{
                                    __html: pain_point_detail?.data?.title2 || ''
                                  }}></h6>
                                <div className='detail-box-img library-detail-img-box library-img-box'></div>
                              </div> */}
                            </div>
                            <div className='slider-main-block-details-inner-thumb'>
                              <div className='slider-main-block-details-inner-thumb-inner'>
                                <Slider
                                  asNavFor={nav1}
                                  ref={(slider2) => setNav2(slider2)}
                                  // slidesToShow={4}
                                  slidesToShow={pain_point_detail?.imageCount}
                                  swipeToSlide={true}
                                  vertical={true}
                                  verticalSwiping={true}
                                  focusOnSelect={true}
                                  infinite={true}>
                                  <div className='library-inner-thumb'>
                                    <img src={pain_point_detail?.data?.image} />
                                  </div>
                                  {pain_point_detail?.data?.image2 && (
                                    <div className='library-inner-thumb'>
                                      <img src={pain_point_detail?.data?.image2} />
                                    </div>
                                  )}
                                  {pain_point_detail?.data?.image3 && (
                                    <div className='library-inner-thumb'>
                                      <img src={pain_point_detail?.data?.image3} />
                                    </div>
                                  )}
                                </Slider>
                              </div>
                              <div className='library-inner-thumb-inner' onClick={(event) => toggleVideoModal(event)}>
                                <div className='video-icon'>
                                  <FaPlay />
                                </div>

                                <div className='video-icon-inner'>
                                  <span className={'font-weight-bold'}>{pain_point_detail?.videoCount}</span>{' '}
                                  {pain_point_detail?.videoCount < 2 ? 'Video' : 'Videos'}
                                </div>
                              </div>
                            </div>
                          </>
                        </div>
                      </Col>
                      <Col className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                        <div className='slide-content-details'>
                          <p>
                            <Interweave
                              content={descriptionPainpoint}
                            />
                          </p>
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
              </div>
            </div>
          </div>
        </section>
      </CommonPageData>

      {/* Video Modal */}
      {isVideoModalOpen && (
        <Modal
          show={isVideoModalOpen}
          onHide={handleClose}
          id='videoModal'
          className='modal fade custom-modal-style muscles-modal'
          aria-hidden='true'
          aria-labelledby='exampleModalLabel'
          size='xl'>
          <Modal.Header>
            {/* <Modal.Title>
              {hasMusclesAnatomy
                ? "Muscles Anatomy Details"
                : "Trigger Point Pain Details"}
            </Modal.Title> */}
            <h4>Videos</h4>
            <button type='button' className='close' data-dismiss='modal' aria-label='Close' onClick={toggleVideoModal}>
              <span aria-hidden='true'>&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body className='modal-body-custom'>
            <>
              {hasMusclesAnatomy ? (
                <Row>
                  <Col md={12}>
                    <div className='library-inner-thumb-two-main top-modal-inner-custom'>
                      <div className='library-inner-thumb-two custom-video'>
                        {muscleVideoArray[currentPlayVideo]?.videoName && (
                          <p className='mt-2'>{muscleVideoArray[currentPlayVideo]?.videoName}</p>
                        )}
                        <TestAndMuscleVideoPlayer
                          autoPlay={false}
                          videoUrl={muscleVideoArray[currentPlayVideo]?.uri}
                          className={'video'}
                          showTime={false}
                        />
                      </div>
                    </div>
                  </Col>
                  {muscleVideoArray.length > 1 && (
                    <Col md={12}>
                      <div className='related-video related-video-inner-modal'>
                        <h5>Additional Videos</h5>
                        <div className='flex-block-video'>
                          {muscleVideoArray.map((data, key) => {
                            console.log('key-->', key);
                            return (
                              <div
                                className='right-video'
                                onClick={() => {
                                  setCurrentPlayVideo(key);
                                }}>
                                <TestAndMuscleVideoPlayer
                                  isInList={true}
                                  autoPlay={false}
                                  videoUrl={data?.uri}
                                  className={'video'}
                                  showTime={true}
                                />
                                {data?.videoName ? (
                                  <div className='modal-title'>
                                    <h6>{data?.videoName}</h6>
                                  </div>
                                ) : (
                                  <div className='modal-title'>
                                    <h6>No title added</h6>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
              ) : (
                <Row className={'h-100'}>
                  <Col md={8}>
                    <div className='library-inner-thumb-two-main'>
                      <div className='library-inner-thumb-two custom-video'>
                        <TestAndMuscleVideoPlayer
                          autoPlay={false}
                          videoUrl={painPointVideoArray[currentPlayVideo]?.uri}
                          className={'video'}
                          showTime={false}
                        />
                        {painPointVideoArray[currentPlayVideo]?.videoName && (
                          <p className='mt-2'>{painPointVideoArray[currentPlayVideo]?.videoName}</p>
                        )}
                      </div>
                    </div>
                  </Col>
                  {painPointVideoArray.length > 1 && (
                    <Col md={4}>
                      <div className='related-video'>
                        <h5>Additional Videos</h5>
                        {painPointVideoArray.map((data, key) => {
                          return (
                            <div
                              className='right-video'
                              onClick={() => {
                                setCurrentPlayVideo(key);
                              }}>
                              <TestAndMuscleVideoPlayer
                                isInList={true}
                                autoPlay={false}
                                videoUrl={data?.uri}
                                className={'video'}
                                showTime={true}
                              />
                              {data?.videoName ? (
                                <div className='modal-title'>
                                  <h6>{data?.videoName}</h6>
                                </div>
                              ) : (
                                <div className='modal-title'>
                                  <h6>No title added</h6>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </Col>
                  )}
                </Row>
              )}
            </>
          </Modal.Body>
        </Modal>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        id='videoModal'
        centered
        className='modal fade custom-modal-style'
        aria-hidden='true'
        aria-labelledby='exampleModalLabel'
        size='xl'>
        <Modal.Header>
          {/* <Modal.Title>
            {hasMusclesAnatomy
              ? "Muscles Anatomy Details"
              : "Trigger Point Pain Details"}
          </Modal.Title> */}
          <button type='button' className='close' data-dismiss='modal' aria-label='Close' onClick={handleClose}>
            <span aria-hidden='true'>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <>
            {hasMusclesAnatomy ? (
              <div className='video-container'>
                <TestAndMuscleVideoPlayer
                  autoPlay={true}
                  videoUrl={muscles_anatomy_detail?.video ? muscles_anatomy_detail?.video : ''}
                  className={'video'}
                />
              </div>
            ) : (
              <div className='video-container'>
                <TestAndMuscleVideoPlayer
                  autoPlay={true}
                  videoUrl={pain_point_detail?.data?.video ? pain_point_detail?.data?.video : ''}
                  className={'video'}
                />
              </div>
            )}
          </>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    muscles_anatomy_detail: state.programReducer.muscles_anatomy_detail,
    pain_point_detail: state.programReducer.pain_point_detail,
    user: state.auth.user,
  };
};
export default connect(mapStateToProps, {
  getMusclesAnatomyData,
  getPainPointData,
})(DetailScreen);
