import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import CustomVideoPlayer from "../AssessmentTimer/CustomVideoPlayer";
import {cross_origin} from "../../../utils/constants";
import { CommonPageData } from '../../../styles/common.style';
const Welcome3 = ({ program, loading }) => {
    const [videoWelcomeError, setVideoWelcomeError] = useState(false);
    const [videoBodyError, setVideoBodyError] = useState(false);

    const handleVideoWelcomeError = () => {
        setVideoWelcomeError(true);
    };

    const handleVideoBodyError = () => {
        setVideoBodyError(true);
    };


    if (!program && !loading) {
        return (
          <CommonPageData>
            {/* <section className='welcome_003'>
              <div className='container'>
                <div className='row center-loader' style={{ margin: '0 auto' }}>
                  <p>No program assigned</p>
                </div>
              </div>
            </section> */}
            <div className="programe-not-assign">
            <h2 className='test-title-block'>No program assigned</h2>
            </div>
          </CommonPageData>
        );
    }
    return (
      <>
        {!program || loading ? (
          <CommonPageData>
            <section className='welcome_003'>
              <div className='container'>
                <div className='row center-loader'>
                  <FadeLoader color={'#FB1417'} height={10} />
                </div>
              </div>
            </section>
          </CommonPageData>
        ) : (
          <>
            <CommonPageData>
              <section className='welcome_002'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-12 col-lg-12 col-md-12'>
                      <ul className='breadcrumb main'>
                        <li className=''>
                          <Link to='/'>Home </Link>
                        </li>
                        <li className='active'>
                          <Link to='/welcome'> Welcome</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
              <section className='welcome_003'>
                <div className='container'>
                  <div className='row'>
                    {/* {videoError &&
                                    <div className="row center-loader" style={{ margin: "0 auto" }}>
                                        <p>Error loading the video.</p>
                                    </div>
                                } */}
                    {!videoWelcomeError && program && program?.welcome_video ? (
                      <div className='col-12 col-lg-6 col-md-6'>
                        <h4>Welcome</h4>
                        <div className='ssa common-box-bg box_video' style={{ textAlign: 'center' }} data-click='0'>
                          {/* <CustomVideoPlayer videoUrl={program.welcome_video} className={'video'} /> */}
                          <video
                            className='video'
                            controls
                            controlsList='nodownload'
                            // autoPlay={"autoplay"}
                            preLoad='auto'
                            crossOrigin={cross_origin ? 'anonymous' : null}
                            onError={(e) => handleVideoWelcomeError(e)}
                            onContextMenu={(e) => e.preventDefault()}>
                            <source src={program?.welcome_video + '#t=0.001'} type='video/mp4' />
                          </video>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {!videoBodyError && program && program.body_type_video ? (
                      <div className='col-12 col-lg-6 col-md-6'>
                        <h4>Your Body Type</h4>
                        <div
                          className=' ssa common-box-bg box_video box-bg box_video'
                          style={{ textAlign: 'center' }}
                          data-click='0'>
                          {/* <CustomVideoPlayer videoUrl={program.body_type_video} className={'video'} /> */}
                          <video
                            className='video'
                            controls
                            controlsList='nodownload'
                            preLoad='auto'
                            crossOrigin={cross_origin ? 'anonymous' : null}
                            onError={(e) => handleVideoBodyError(e)}
                            onContextMenu={(e) => e.preventDefault()}>
                            <source src={program?.body_type_video + '#t=0.001'} type='video/mp4' />
                          </video>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    <div className='col-12 col-lg-12 col-md-12 text-center'>
                      <Link to={`/program/${program?.id}`} className='theme-common-btn'>
                        Start your program
                      </Link>
                    </div>
                  </div>
                </div>
              </section>
            </CommonPageData>
          </>
        )}
      </>
    );
};

Welcome3.propTypes = {
    program: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    program: state.programReducer.program,
    loading: state.programReducer.loading
});

export default connect(mapStateToProps, {})(Welcome3);
