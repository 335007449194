import {
    USER_ASSESSMENT_REQUEST,
    USER_ASSESSMENT_SUCCESS,
    USER_ASSESSMENT_FAILURE,
    USER_ASSESSMENT_PRE_SIGNED_URL_REQUEST,
    USER_ASSESSMENT_PRE_SIGNED_URL_SUCCESS,
    USER_ASSESSMENT_PRE_SIGNED_URL_FAILURE,
    CREATE_ASSESSMENT_STEP_REQUEST,
    CREATE_ASSESSMENT_STEP_SUCCESS,
    CREATE_ASSESSMENT_STEP_FAILURE,
    GET_LAST_ASSESSMENT_REQUEST,
    GET_LAST_ASSESSMENT_SUCCESS,
    GET_LAST_ASSESSMENT_FAILURE,
    GET_ASSESSMENT_SUMMARY_REQUEST,
    GET_ASSESSMENT_SUMMARY_SUCCESS,
    GET_ASSESSMENT_SUMMARY_FAILURE

} from '../actions/types';
import { getLastAssessment } from '../actions/user_assessment';

const initialState = {
    skipTestReason: null
};

function assessmentReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        case USER_ASSESSMENT_REQUEST:
            return {
                ...state,
                userAssessmentRequest: true,
                userAssessmentSuccess: false,
                userAssessmentFailure: false,
                assessments: null
            }
        case USER_ASSESSMENT_SUCCESS:
            return {
                ...state,
                userAssessmentRequest: false,
                userAssessmentSuccess: true,
                userAssessmentFailure: false,
                assessments: payload
            }
        case USER_ASSESSMENT_FAILURE:
            return {
                ...state,
                userAssessmentRequest: false,
                userAssessmentSuccess: false,
                userAssessmentFailure: true,
                assessments: null
            }

        case USER_ASSESSMENT_PRE_SIGNED_URL_REQUEST:
            return {
                ...state,
                userAssessmentPreRequest: true,
                userAssessmentPreSuccess: false,
                userAssessmentPreFailure: false,
                stepFileUrl: null
            }
        case USER_ASSESSMENT_PRE_SIGNED_URL_SUCCESS:
            return {
                ...state,
                userAssessmentPreRequest: false,
                userAssessmentPreSuccess: true,
                userAssessmentPreFailure: false,
                stepFileUrl: payload
            }
        case USER_ASSESSMENT_PRE_SIGNED_URL_FAILURE:
            return {
                ...state,
                userAssessmentPreRequest: false,
                userAssessmentPreSuccess: false,
                userAssessmentPreFailure: true,
                stepFileUrl: null
            }
        case CREATE_ASSESSMENT_STEP_REQUEST:
            return {
                ...state,
                createAssessmentStepRequest: true,
                createAssessmentSuccess: false,
                createAssessmentFailure: false,
                assessmentStep: null
            }
        case CREATE_ASSESSMENT_STEP_SUCCESS:
            return {
                ...state,
                createAssessmentStepRequest: false,
                createAssessmentSuccess: true,
                createAssessmentFailure: false,
                assessmentStep: payload
            }
        case CREATE_ASSESSMENT_STEP_FAILURE:
            return {
                ...state,
                createAssessmentStepRequest: false,
                createAssessmentSuccess: false,
                createAssessmentFailure: true,
                assessmentStep: null
            }
        case GET_LAST_ASSESSMENT_REQUEST:
            return {
                ...state,
                getLastAssessmentRequest: true,
                getLastAssessmentSuccess: false,
                getLastAssessmentFailure: false,
                lastAssessment: null
            }
        case GET_LAST_ASSESSMENT_SUCCESS:
            return {
                ...state,
                getLastAssessmentRequest: false,
                getLastAssessmentSuccess: true,
                getLastAssessmentFailure: false,
                lastAssessment: payload
            }
        case GET_LAST_ASSESSMENT_FAILURE:
            return {
                ...state,
                getLastAssessmentRequest: false,
                getLastAssessmentSuccess: false,
                getLastAssessmentFailure: true,
                lastAssessment: null
            }
        case GET_ASSESSMENT_SUMMARY_REQUEST:
            return {
                ...state,
                assessmentSummaryRequest: true,
                assessmentSummarySuccess: false,
                assessmentSummaryFailure: false,
                assessment: null
            }
        case GET_ASSESSMENT_SUMMARY_SUCCESS:
            return {
                ...state,
                assessmentSummaryRequest: false,
                assessmentSummarySuccess: true,
                assessmentSummaryFailure: false,
                allAssessment: payload
            }
        case GET_ASSESSMENT_SUMMARY_FAILURE:
            return {
                ...state,
                assessmentSummaryRequest: false,
                assessmentSummarySuccess: false,
                assessmentSummaryFailure: true,
                allAssessment: null
            }

        default:
            return state;
    }
}

export default assessmentReducer;
