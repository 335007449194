import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Header from "../../Layout/Header";
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import Footer from "../../Layout/Footer";
import { getMusclesAnatomy } from "../../../actions/user_program";
import Button from "react-bootstrap/Button";
import Image from "../../images/img8.png";
import Banner from "../../images/MuscleLibraryCover.png";
import { Link, useLocation } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
import { cross_origin } from "../../../utils/constants";
import { CommonPageData } from '../../../styles/common.style';
function MusclesAnatomy({ getMusclesAnatomy, muscles_anatomy, user }) {
  const [musclesAnatomyData, setMusclesAnatomyData] = useState([]);

  const [tempMusclesAnatomyData, setTempMusclesAnatomyData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [keyword, setKeyword] = useState(null);
  const [loader, setLoader] = useState(true);
  const [productCat, setProductCat] = useState([]);

  let location = useLocation();

  // const [isShow, setVal] = useState(false)

  // Function to handle suggestion selection
  // const handleSuggestionClick = (suggestion) => {
  //     setVal(false);
  //     setKeyword(suggestion);
  // };

  let array = [
    {
      image: Image,
    },
    {
      image: Image,
    },
    {
      image: Image,
    },
    {
      image: Image,
    },
    {
      image: Image,
    },
    {
      image: Image,
    },
    {
      image: Image,
    },
    {
      image: Image,
    },
    {
      image: Image,
    },
    {
      image: Image,
    },
    {
      image: Image,
    },
  ];

  // useEffect(() => {
  //     getMusclesAnatomy(currentPage, itemsPerPage, 1, keyword)
  // }, [currentPage, itemsPerPage, keyword]);

  // useEffect(() => {
  //     setTotalPages(muscles_anatomy ? parseInt(muscles_anatomy.meta.last_page) : 1)
  //     if (muscles_anatomy?.data) {
  //         setLoader(false)
  //     }
  //     setMusclesAnatomyData(muscles_anatomy ? muscles_anatomy.data : [])
  //     // setMusclesAnatomyData(array)
  // }, [muscles_anatomy]);

  useEffect(() => {
    setMusclesAnatomyData(location?.state?.muscles_anatomy);
    setTempMusclesAnatomyData(location?.state?.muscles_anatomy);
    if (musclesAnatomyData) {
      setLoader(false);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const topElement = document.querySelector(".muscle-banner-recommended"); // Replace 'top-element' with the actual ID of your top element
      if (topElement) {
        topElement.scrollIntoView({
          behavior: "smooth",
          block: "start", // Scroll to the top of the element
        });
      }
    }, 2000);
  }, []);
  const handleItemsPerPageChange = (e) => {
    setLoader(true);
    setItemsPerPage(parseInt(e.target.value));
  };

  const handleSearchClick = (value) => {
    // getMusclesAnatomy(1, itemsPerPage, 1, value);
    // setLoader(true);
    setCurrentPage(1);

    if (value.length < 1) {
      setMusclesAnatomyData(tempMusclesAnatomyData);
    } else {
      const searchResults = musclesAnatomyData.filter((data) => {
        return data.title.toLowerCase().includes(value.toLowerCase());
      });
      setMusclesAnatomyData(searchResults);
    }
  };
  const handleSearch = (value) => {
    // setVal(true);
    setKeyword(value);
    if (keyword && value.length == 0) {
      getMusclesAnatomy(currentPage, itemsPerPage, 1, value);
    }
  };
  return (
    <>
      <ScrollTopOnMount />
      <Header />
      {/* <div className={"muscle-banner-recommended"}> */}
      {/* <Container className={"h-100"}> */}
      {/* <Row className={"h-100"}> */}
      {/* <div className="bannerImgWrap">
                            <img
                                src={Banner}
                                alt={'banner'}
                                crossOrigin={cross_origin ? "anonymous" : null}
                            />
                        </div> */}

      {/*<Col className={"col-12 col-lg-6 col-md-6 m-auto"}>*/}
      {/*    <div className="box_banner_inner text-center">*/}
      {/*        <h3>Muscle Library</h3>*/}
      {/*    </div>*/}
      {/*</Col>*/}
      {/* </Row> */}
      {/* </Container> */}
      {/* </div> */}
      <div className={'clearfix'}></div>
      <CommonPageData>
        <div className='common-banner-block'>
          <div className='main-banner'>
            <img src='/images/banner-common-block.png' alt='img' />
          </div>
          <div className='container'>
            <div className='common-banner-block-inner'>
              <h1>Muscle Library</h1>
            </div>
          </div>
        </div>
        <section className='videos mb-custom-block'>
          <div className='container'>
            <div className='row'>
              {/* <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 '>
                <div className='videos_headings'>
                  <h2 className='detail_span' style={{ color: '#fff' }}>
                    MUSCLE LIBRARY
                  </h2>
                </div>
              </div> */}
              <div className='search-block-custom'>
                <Form className='' onSubmit={(e) => e.preventDefault()}>
                  {/* <div className="autocomplete-container"> */}
                  <Form.Control
                    value={keyword}
                    //onChange={handleInputChange}
                    //value={search}
                    type='search'
                    placeholder='Search'
                    className='me-2 rounded-pill'
                    aria-label='Search'
                    onChange={(e) => {
                      handleSearch(e.target.value);
                      handleSearchClick(e.target.value);
                    }}
                  />
                  <button>
                    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M15.2713 14.9121L12.357 12.0064C13.2973 10.8085 13.8075 9.32921 13.8056 7.80636C13.8056 6.45015 13.4034 5.12439 12.65 3.99674C11.8965 2.86909 10.8255 1.99019 9.57257 1.47119C8.31959 0.95219 6.94085 0.816396 5.61069 1.08098C4.28054 1.34556 3.05871 1.99864 2.09972 2.95763C1.14073 3.91662 0.487654 5.13845 0.22307 6.4686C-0.0415146 7.79876 0.0942797 9.1775 0.61328 10.4305C1.13228 11.6835 2.01118 12.7544 3.13883 13.5079C4.26648 14.2613 5.59224 14.6635 6.94845 14.6635C8.4713 14.6654 9.95056 14.1552 11.1485 13.2149L14.0542 16.1292C14.1339 16.2096 14.2287 16.2733 14.3331 16.3168C14.4376 16.3604 14.5496 16.3828 14.6627 16.3828C14.7759 16.3828 14.8879 16.3604 14.9924 16.3168C15.0968 16.2733 15.1916 16.2096 15.2713 16.1292C15.3516 16.0495 15.4154 15.9547 15.4589 15.8503C15.5024 15.7458 15.5249 15.6338 15.5249 15.5206C15.5249 15.4075 15.5024 15.2955 15.4589 15.191C15.4154 15.0866 15.3516 14.9918 15.2713 14.9121ZM1.8056 7.80636C1.8056 6.7892 2.10722 5.79488 2.67232 4.94915C3.23743 4.10341 4.04063 3.44423 4.98037 3.05498C5.9201 2.66573 6.95416 2.56389 7.95178 2.76233C8.94939 2.96076 9.86576 3.45057 10.585 4.16982C11.3042 4.88906 11.7941 5.80543 11.9925 6.80304C12.1909 7.80066 12.0891 8.83472 11.6998 9.77445C11.3106 10.7142 10.6514 11.5174 9.80567 12.0825C8.95993 12.6476 7.96561 12.9492 6.94845 12.9492C5.58448 12.9492 4.27638 12.4074 3.31191 11.4429C2.34743 10.4784 1.8056 9.17033 1.8056 7.80636Z'
                        fill='black'
                      />
                    </svg>
                  </button>
                  {/* </div> */}
                  {/* <Button className="rounded-pill" variant="outline-primary" type={'submit'} onClick={handleSearchClick}>
                                    TextField  Search
                                </Button> */}
                </Form>
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 '>
                <div className={'Educational-info d-flex flex-column text-start mb-5'}>
                  <h3 className={'text-center'}>
                    {/*<p><b>Description section: </b>You can search the body part</p>*/}
                    <p>Search by muscle name or region (i.e. hamstring, leg, semitendinosus, etc.)</p>
                  </h3>
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 '>
                <div className='row mb-custom-block'>
                  {loader ? (
                    <div className='col-12 col-sm-12 col-md-12  col-lg-12 col-xl-12 '>
                      <div className={'empty-page'}>
                        <div className='spin-loader'>
                          <FadeLoader color={'#EC1246'} height={10} />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {' '}
                      {musclesAnatomyData?.length > 0 ? (
                        musclesAnatomyData.map((data, key) => {
                          if (data) {
                            return (
                              <div className='col-3 mb-custom-30'>
                                <div className='library-img-container-main-inner'>
                                  <div className='library-img-container-main  common-box-height ' key={key}>
                                    <Link
                                      className={'nav-link common-box-bg-all library-nav'}
                                      data-toggle='tab'
                                      to={'/muscles-anatomy/detail/' + data?.id}
                                      role='tab'>
                                      <div className='library-img-container'>
                                        <div className='library-img-box'>
                                          <img
                                            src={data?.image ? data?.image : ''}
                                            alt={data?.title ? data?.title : ''}
                                            crossOrigin={cross_origin ? 'anonymous' : null}
                                            onContextMenu={(e) => e.preventDefault()}
                                          />
                                        </div>
                                        <div className='library-img-container-inner'>
                                          {/* <h6 className='title-header' dangerouslySetInnerHTML={{ __html: data?.title }}></h6> */}
                                          <div>
                                            <div className='title-one'>{data?.title} </div>
                                            <div className='title-two'>{data?.title2}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })
                      ) : (
                        <div className='col-12 col-sm-12 col-md-12  col-lg-12 col-xl-12 '>
                          <div className={'library-empty-page'}>
                            <span className='itemsPerPagelabel'>Not Found</span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3'>
                <div className='pagination'>
                  <span className='itemsPerPagelabel'>Items Per Page:</span>
                  <div className={'perpagedropdown'}>
                    <select id='itemsPerPage' value={itemsPerPage} onChange={handleItemsPerPageChange}>
                      <option value={4}>4</option>
                      <option value={8}>8</option>
                      <option value={12}>12</option>
                      <option value={16}>16</option>
                      <option value={20}>20</option>
                    </select>
                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                      <path d='M7 10l5 5 5-5z'></path>
                      <path d='M0 0h24v24H0z' fill='none'></path>
                    </svg>
                  </div>
                  <span className='totalpages'>
                    {currentPage}-{totalPages} of {totalPages}
                  </span>
                  <div className='paginationbuttons'>
                    {currentPage > 1 ? (
                      <button
                        className={'button-previous'}
                        type='button'
                        onClick={() => {
                          setCurrentPage(currentPage - 1);
                          setLoader(true);
                        }}
                        disabled={currentPage === 1}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          aria-hidden='true'
                          role='presentation'>
                          <path d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z'></path>
                          <path d='M0 0h24v24H0z' fill='none'></path>
                        </svg>
                      </button>
                    ) : (
                      ''
                    )}
                    {currentPage < totalPages ? (
                      <button
                        type='button'
                        className={'button-next'}
                        onClick={() => {
                          setCurrentPage(currentPage + 1);
                          setLoader(true);
                        }}
                        disabled={currentPage === totalPages}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          aria-hidden='true'
                          role='presentation'>
                          <path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'></path>
                          <path d='M0 0h24v24H0z' fill='none'></path>
                        </svg>
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </CommonPageData>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    muscles_anatomy: state.programReducer.muscles_anatomy,
    user: state.auth.user,
  };
};
export default connect(mapStateToProps, { getMusclesAnatomy })(MusclesAnatomy);
