import React, { useEffect } from 'react'
import Header from "../../Layout/Header"
import Footer from "../../Layout/Footer"
import ChangeLevel1 from './ChangeLevel1'
import ChangeLevel2 from './ChangeLevel2'
import CompiledChangeLevel from './CompiledChangeLevel'
import Banner from '../AssessmentGridView/Banner'
import { useLocation } from 'react-router-dom'
import { CommonPageData } from '../../../styles/common.style';

const ChangeLevel = () => {
  const location = useLocation()
  const { exercise } = location?.state
  // const { activeDayId } = location.state
  const { programId } = location?.state
  const { redirectLink } = location?.state

  const compilationVideoId = exercise && exercise?.compilation_video_id ? exercise?.compilation_video_id : null

  return (
    <div>

      <Header />
      {/* <ChangeLevel1 curLevel={exercise && exercise.exerciseLevel.level ? exercise.exerciseLevel.level : null} />
      {compilationVideoId ? (
        
        <CompiledChangeLevel
        exerciseId={exercise && exercise.exercise_id ? exercise.exercise_id : null}
        curLevel={exercise && exercise.exerciseLevel.level ? exercise.exerciseLevel.level.id : null}
        activeId={exercise && exercise.id ? exercise.id : null}
        programId={programId ? programId : null}
        redirectLink={redirectLink}
        compilationVideoId={compilationVideoId}
      />
      ) : (
        <ChangeLevel2
        exerciseId={exercise && exercise.exercise_id ? exercise.exercise_id : null}
        curLevel={exercise && exercise.exerciseLevel.level ? exercise.exerciseLevel.level.id : null}
        activeId={exercise && exercise.id ? exercise.id : null}
        programId={programId ? programId : null}
        redirectLink={redirectLink}
        compilationVideoId={compilationVideoId}
      />
      )} */}

      {compilationVideoId ? (

        <CompiledChangeLevel
          exerciseId={exercise && exercise.exercise_id ? exercise.exercise_id : null}
          curLevel={exercise && exercise.exerciseLevel.level ? exercise.exerciseLevel.level.id : null}
          activeId={exercise && exercise.id ? exercise.id : null}
          programId={programId ? programId : null}
          redirectLink={redirectLink}
          compilationVideoId={compilationVideoId}
        />
      ) : (
        <>
          <CommonPageData>
            <div className='common-banner-block '>
              <div className='main-banner'>
                <img src='/images/banner-common-block.png' alt='img' />
              </div>
              <div className='container '>
                <div className='common-banner-block-inner'>
                  <h1>Change Movement</h1>
                </div>
                <>
                  <div className='middle-text-block text-center mt-5 mb-5'>
                    <h4>
                      There are no replacement options for this exercise
                    </h4>
                  </div>
                </>
              </div>
            </div>

          </CommonPageData>
        </>
        // <ChangeLevel2
        //   exerciseId={exercise && exercise.exercise_id ? exercise.exercise_id : null}
        //   curLevel={exercise && exercise.exerciseLevel.level ? exercise.exerciseLevel.level.id : null}
        //   activeId={exercise && exercise.id ? exercise.id : null}
        //   programId={programId ? programId : null}
        //   redirectLink={redirectLink}
        //   compilationVideoId={compilationVideoId}
        // />
      )}

      <Footer />
    </div>
  )
}

export default ChangeLevel