import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { FadeLoader } from "react-spinners";

import { CommonPageInner,CommonPageData } from '../../../styles/common.style';
const Welcome1 = ({ program, loading }) => {

  if (!program && !loading) {
    return (
      <CommonPageData>
       <div className='common-banner-block'>
              <div className='main-banner'>
                <img src='/images/banner-common-block.png' alt='img' />
              </div>
              <div className='container'>
                <div className='common-banner-block-inner'>
                  <h1>No program assigned</h1>
                </div>
              </div>
            </div>
            </CommonPageData>
      // <section className="welcome_001">
      //   <div className="container h-100">
      //     <div className="row h-100">
      //       <div className="col-12 col-lg-6 col-md-6 m-auto">
      //         <div className="box_banner_inner text-center">
      //           <p>No program assigned</p>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </section>
      )
  }
  else {
    return (
      <CommonPageInner>
        {!program || loading ? (
          <section className='welcome_001'>
            <div className='container h-100'>
              <div className='row h-100'>
                <div className='col-12 col-lg-6 col-md-6 m-auto'>
                  <div className='box_banner_inner text-center mx-auto'>
                    <FadeLoader color={'#FB1417'} height={10} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          // <section className="welcome_001">
          //   <div className="container h-100">
          //     <div className="row h-100">
          //       <div className="col-12 col-lg-6 col-md-6 m-auto">
          //         <div className="box_banner_inner text-center mx-auto">
          //           <div>
          //             <h1>{program.name}</h1>
          //             <h5> {program.description}</h5>
          //             <p>12 Week Corrective Exercise Program</p>
          //           </div>
          //         </div>
          //       </div>
          //     </div>
          //   </div>
          // </section>
          <div className='common-banner-block'>
            <div className='main-banner'>
              <img src='/images/banner-common-block.png' alt='img' />
            </div>
            <div className='container'>
              <div className='common-banner-block-inner'>
                <h1>{program.name}</h1>
                <p>12 Week Corrective Exercise Program</p>  
              </div>
            </div>
          </div>
        )}
      </CommonPageInner>
    );
  }

}
Welcome1.propTypes = {
  program: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  program: state.programReducer.program,
  loading: state.programReducer.loading
});

export default connect(mapStateToProps, {})(Welcome1);