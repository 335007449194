import React, { useEffect, useState } from "react";
import WeekSlider from "./WeekSlider";
import Header from "../../Layout/Header";
import Banner from "./Banner";
import BreadcrumbMenu from "../BreadcrumbMenu";
import Exercises from "./Exercises";
import Footer from "../../Layout/Footer";
import PropTypes from "prop-types";
import { connect, useStore } from "react-redux";
import Exercise from "./Exercise";
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import { getProgram } from "../../../actions/user_program";
import { useParams } from "react-router-dom";


const Index = ({
  program,
  getProgram,
}) => {
  const [start, setStart] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    getProgram(id);
  }, []);
  return (
    <>
      <ScrollTopOnMount />
      <Header />
      <Banner setStart={setStart} program={program} />
      <WeekSlider setStart={setStart} start={start} program={program} />
      <Footer />
    </>
  );
};

Index.propTypes = {
  program: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  program: state.programReducer.program,

});
export default connect(mapStateToProps, { getProgram })(Index);

