import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CommonPageData } from '../../styles/common.style';

const BreadcrumbMenu = ({ breadcrumbs, classNames = '' }) => {

    return (
        <CommonPageData>
            <ul className={`breadcrumb main ${classNames}`}>
                {breadcrumbs.map((item, index) => (
                    <li key={index} className={item.isActive ? 'active' : ''}>
                        <Link to={item.link}>{item.label}</Link>
                    </li>
                ))}
            </ul>
        </CommonPageData>
    );
};

export default BreadcrumbMenu;
