import React, { useEffect, useState } from 'react';
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import { connect } from "react-redux";
import { getEducationalVideos, getFaqs } from "../../../actions/user_program";
import { Col, Container, Form, Row } from "react-bootstrap";
import FadeLoader from "react-spinners/FadeLoader";
import { CommonPageData } from '../../../styles/common.style';
function Faqs({ getFaqs, faqs }) {
    const [keyword, setKeyword] = useState(null)
    const [loader, setLoader] = useState(true)

    const [faqData, setFaqData] = useState(null)

    useEffect(() => {
        getFaqs(keyword)
        setLoader(false);
    }, [keyword]);

    useEffect(() => {
        if (faqs?.data) {
            setLoader(false)
        }
        setFaqData(faqs ? faqs.data : [])
        // setMusclesAnatomyData(array)
    }, [faqs]);


    const handleAccordionClick = (accordionTab, contents) => {
        accordionTab.classList.toggle('active');
        contents.style.display = contents.style.display === 'block' ? 'none' : 'block';

        const otherTabs = Array.from(accordionTab.parentNode.children).filter(
            (tab) => tab !== accordionTab
        );
        otherTabs.forEach((tab) => {
            tab.classList.remove('active');
            tab.querySelector('.contents').style.display = 'none';
        });
    };

    const handleSearchClick = () => {
        getFaqs(keyword)
        setLoader(true)
    };
    const handleSearch = (value) => {
        setKeyword(value)
        if (keyword && value.length == 0) {
            getFaqs(value)
        }
    };

    return (
      <>
        <ScrollTopOnMount />
        <Header />
        <CommonPageData>
          <div className='common-banner-block'>
            <div className='main-banner'>
              <img src='/images/banner-common-block.png' alt='img' />
            </div>
            <div className='container'>
              <div className='common-banner-block-inner'>
                <h1>FAQs</h1>
              </div>
            </div>
          </div>
          <section className='faq-section-block section accordion-section'>
            <div className={'container h-100'}>
              <Row className={'h-100'}>
                {/* <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 '>
                  <div className='videos_headings'>
                    <h2 className='detail_span' style={{ color: '#fff' }}>
                      FREQUENTLY ASKED QUESTIONS
                    </h2>
                  </div>
                </div> */}
                <div className='col-12 col-sm-12 col-md-12'>
                  <div className='search-block-custom'>
                    <Form className='' onSubmit={(e) => e.preventDefault()}>
                      <Form.Control
                        value={keyword}
                        type='search'
                        placeholder='Search'
                        className='me-2 rounded-pill'
                        aria-label='Search'
                        onChange={(e) => {
                          handleSearch(e.target.value);
                          handleSearchClick(e.target.value);
                        }}
                      />
                      {/* <Button className="rounded-pill" variant="outline-primary" onClick={handleSearchClick}>
                                    Search
                                </Button> */}
                      <button>
                        <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M15.2713 14.9121L12.357 12.0064C13.2973 10.8085 13.8075 9.32921 13.8056 7.80636C13.8056 6.45015 13.4034 5.12439 12.65 3.99674C11.8965 2.86909 10.8255 1.99019 9.57257 1.47119C8.31959 0.95219 6.94085 0.816396 5.61069 1.08098C4.28054 1.34556 3.05871 1.99864 2.09972 2.95763C1.14073 3.91662 0.487654 5.13845 0.22307 6.4686C-0.0415146 7.79876 0.0942797 9.1775 0.61328 10.4305C1.13228 11.6835 2.01118 12.7544 3.13883 13.5079C4.26648 14.2613 5.59224 14.6635 6.94845 14.6635C8.4713 14.6654 9.95056 14.1552 11.1485 13.2149L14.0542 16.1292C14.1339 16.2096 14.2287 16.2733 14.3331 16.3168C14.4376 16.3604 14.5496 16.3828 14.6627 16.3828C14.7759 16.3828 14.8879 16.3604 14.9924 16.3168C15.0968 16.2733 15.1916 16.2096 15.2713 16.1292C15.3516 16.0495 15.4154 15.9547 15.4589 15.8503C15.5024 15.7458 15.5249 15.6338 15.5249 15.5206C15.5249 15.4075 15.5024 15.2955 15.4589 15.191C15.4154 15.0866 15.3516 14.9918 15.2713 14.9121ZM1.8056 7.80636C1.8056 6.7892 2.10722 5.79488 2.67232 4.94915C3.23743 4.10341 4.04063 3.44423 4.98037 3.05498C5.9201 2.66573 6.95416 2.56389 7.95178 2.76233C8.94939 2.96076 9.86576 3.45057 10.585 4.16982C11.3042 4.88906 11.7941 5.80543 11.9925 6.80304C12.1909 7.80066 12.0891 8.83472 11.6998 9.77445C11.3106 10.7142 10.6514 11.5174 9.80567 12.0825C8.95993 12.6476 7.96561 12.9492 6.94845 12.9492C5.58448 12.9492 4.27638 12.4074 3.31191 11.4429C2.34743 10.4784 1.8056 9.17033 1.8056 7.80636Z'
                            fill='black'
                          />
                        </svg>
                      </button>
                    </Form>
                  </div>
                </div>
                {/*<Col className={"col-12 col-lg-12 col-md-12 m-auto"}>*/}
                {/*    <div className="text-center">*/}
                {/*        <h2>Frequently Asked Questions*/}
                {/*        </h2>*/}
                {/*    </div>*/}
                {/*</Col>*/}
              </Row>
              <div className='faq-wrapper'>
                {/*<h3 className="sec-faq-heading">Frequently Asked Questions</h3>*/}
                <div className='accordion'>
                  {loader ? (
                    <div className='col-12 col-sm-12 col-md-12  col-lg-12 col-xl-12 '>
                      <div className={'empty-page'}>
                        <div className='spin-loader'>
                          <FadeLoader color={'#EC1246'} height={10} />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {faqData?.length > 0 &&
                        faqData.map((data, key) => {
                          if (data) {
                            return (
                              <div
                                className='accordion-tab'
                                key={key}
                                onClick={(e) =>
                                  handleAccordionClick(e.currentTarget, e.currentTarget.querySelector('.contents'))
                                }>
                                <div className='faq-heading'>{data?.question}</div>
                                <div className='contents'>{data?.answer}</div>
                              </div>
                            );
                          }
                        })}
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>
        </CommonPageData>
        <Footer />
      </>
    );
}

const mapStateToProps = state => {

    return {
        faqs: state.programReducer.faqs
    }
}
export default connect(mapStateToProps, { getFaqs })(Faqs)
