import imgNotFound from "../components/images/broken-hd.png"
export const cross_origin = false
export const SORE_FEEDBACK = {
    GOOD: 10,
    PAINFUL: 20
}
export const SKIP_TEST_REASONS = {
    PAINFUL: 10,
    DONT_UNDERSTAND: 20,
    SHORT_TIME: 30,
    SKIP_ONE_WEEK: 40,
}

export const SOCIAL_MEDIA_LINKS = {
    TWITTER: '',
    FACEBOOK: '',
    TIKTOK: '',
    WEB: '',
}

export const EXERCISE_TIMES = {
    "0.25": "15sec",
    "0.33": "20sec",
    "0.41": "25sec",
    "0.5": "30sec",
    "0.58": "35sec",
    "0.66": "40sec",
    "0.75": "45sec",
    "0.83": "50sec",
    "0.91": "55sec",
    "1": "1min",
    "1.25": "1min 15sec",
    "1.33": "1min 20sec",
    "1.41": "1min 25sec",
    "1.5": "1min 30sec",
    "1.58": "1min 35sec",
    "1.66": "1min 40sec",
    "1.75": "1min 45sec",
    "1.83": "1min 50sec",
    "1.91": "1min 55sec",
    "2": "2min"
}

export const weight_unit = {
    KG: 10,
    LBS: 20,
}

export const genders = {
    MALE: 10,
    FEMALE: 20,
}

export const s3Credential = {
    //s3EndPoint: "https://d3b940jott9rqr.cloudfront.net/",
    s3EndPoint: "https://d1rs42xew6aaeb.cloudfront.net/",
}

export const googleLoginCredential = {
    clientId: "559668091821-hl4e83cs4oqhb8cedoa7ii5r2ehl3oij.apps.googleusercontent.com"
}
