import {
    USER_ASSESSMENT_REQUEST,
    USER_ASSESSMENT_SUCCESS,
    USER_ASSESSMENT_FAILURE,
    USER_ASSESSMENT_PRE_SIGNED_URL_REQUEST,
    USER_ASSESSMENT_PRE_SIGNED_URL_SUCCESS,
    USER_ASSESSMENT_PRE_SIGNED_URL_FAILURE,
    CREATE_ASSESSMENT_STEP_REQUEST,
    CREATE_ASSESSMENT_STEP_SUCCESS,
    CREATE_ASSESSMENT_STEP_FAILURE,
    GET_LAST_ASSESSMENT_REQUEST,
    GET_LAST_ASSESSMENT_SUCCESS,
    GET_LAST_ASSESSMENT_FAILURE,
    GET_ASSESSMENT_SUMMARY_REQUEST,
    GET_ASSESSMENT_SUMMARY_SUCCESS,
    GET_ASSESSMENT_SUMMARY_FAILURE
} from "./types";
import api from '../utils/api';
import { setAlert } from "./alert";


export const getAssessment = () => async dispatch => {
    try {
        dispatch({ type: USER_ASSESSMENT_REQUEST });
        const token = localStorage.getItem('token')
        const res = await api.get("assessments?is_paginate=0&order=asc&order-column=order_no", {
            headers: {
                Authorization: 'Bearer ' + token
            },
        });
        dispatch({
            type: USER_ASSESSMENT_SUCCESS,
            payload: res.data.data
        });
    } catch (err) {
        const msg = err.response.data.message;
        dispatch({ type: USER_ASSESSMENT_FAILURE })
        dispatch(setAlert(msg, 'danger'))
    }
};

export const getAssessmentPresignedUrl = (fileType, fileName, folder_path) => async (dispatch) => {
    try {
        dispatch({
            type: USER_ASSESSMENT_PRE_SIGNED_URL_REQUEST
        })
        let token = localStorage.getItem('token')
        const res = await api.get('get-s3-token-updated?file_type=' + fileType + "&file_name=" + fileName + "&folder_path=" + folder_path, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
        dispatch({
            type: USER_ASSESSMENT_PRE_SIGNED_URL_SUCCESS,
            payload: res.data.data
        });
    } catch (err) {
        const msg = err && err.response ? err.response.data.message : null;
        if (msg) {
            dispatch(setAlert(msg, 'danger'))
        }
        dispatch({
            type: USER_ASSESSMENT_PRE_SIGNED_URL_FAILURE
        })
    }
}
//create assessment step
export const createAssessmentStep = (payload) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_ASSESSMENT_STEP_REQUEST });
        let token = localStorage.getItem('token')
        const res = await api.post('/create-assessment-step', payload, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
        console.log("res", res);

        dispatch({
            type: CREATE_ASSESSMENT_STEP_SUCCESS,
            payload: res.data
        })

        let msg = res.data.message
        dispatch(setAlert(msg, 'success'))
    } catch (err) {
        const msg = err.response.data.message;
        dispatch({ type: CREATE_ASSESSMENT_STEP_FAILURE })
        dispatch(setAlert(msg, 'danger'))
    }
}

export const getLastAssessment = (userId) => async dispatch => {
    try {
        dispatch({ type: GET_LAST_ASSESSMENT_REQUEST });
        const token = localStorage.getItem('token')
        const res = await api.get("get-last-assessment/" + userId, {
            headers: {
                Authorization: 'Bearer ' + token
            },
        });
        
        dispatch({
            type: GET_LAST_ASSESSMENT_SUCCESS,
            payload: res.data.data
        });
    } catch (err) {
        const msg = err.response.data.message;
        dispatch({ type: GET_LAST_ASSESSMENT_FAILURE })
        dispatch(setAlert(msg, 'danger'))
    }
}

export const getAssessmentSummary = (userId) => async dispatch => {
    try {
        dispatch({ type: GET_ASSESSMENT_SUMMARY_REQUEST });
        const token = localStorage.getItem('token')
        const res = await api.get("get-assessment-summary/" + userId, {
            headers: {
                Authorization: 'Bearer ' + token
            },
        });
        
        dispatch({
            type: GET_ASSESSMENT_SUMMARY_SUCCESS,
            payload: res.data.data
        });
    } catch (err) {
        const msg = err.response.data.message;
        dispatch({ type: GET_ASSESSMENT_SUMMARY_FAILURE })
        dispatch(setAlert(msg, 'danger'))
    }
}