import React from "react";
import Modal from "react-bootstrap/Modal";
import {useState} from "react";
import {Link} from "react-router-dom";

const DayCompleteModal = () => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    return (
      <>
        <button to='' className='margin-auto-btn theme-common-btn-big' onClick={handleShow}>
          Finish
        </button>
        <Modal show={show} onHide={handleClose} className='modal_test custom-modal-style small-modal-common' centered>
          <Modal.Header className='p-0'>
            <button type='button' className='close' data-dismiss='modal' aria-label='Close' onClick={handleClose}>
              <span aria-hidden='true'>&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body className='modal-body-custom'>
            <h4>
              Congratulations !!!
              <br />
              You have completed your day's exercises
            </h4>
            <Link to='/assessment-gridview' class='d-table theme-common-btn-big'>
              Finish
            </Link>
          </Modal.Body>
        </Modal>
      </>
    );
};

export default DayCompleteModal;
