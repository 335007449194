import React from 'react'
import { CommonPageData } from '../../../styles/common.style';
const Day1 = () => {
    return (
      <CommonPageData>
        <div className='common-banner-block'>
          <div className='main-banner'>
            <img src='/images/banner-common-block.png' alt='img' />
          </div>
          <div className='container'>
            <div className='common-banner-block-inner'>
              <h1>Daily Test</h1>
            </div>
          </div>
        </div>
      </CommonPageData>
      // <section className="assestmentd1_001">
      //     <div className="container">
      //         <div className="row">
      //             <div className="col-12 col-lg-12 col-md-12 text-center">
      //                 <h3>Daily Test </h3>
      //             </div>
      //         </div>
      //     </div>
      // </section>
    );
}

export default Day1