import React from 'react'
import Slider from 'react-slick';
import { HomePage } from '../../../styles/common.style';
import { Link } from 'react-router-dom';
const Home1 = () => {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true
    };
    var settingsVideo = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            centerMode: true,
            centerPadding: '30px',
            slidesToShow: 1
          }
        }
      ]
    };
     var settingsAbout = {
       dots: true,
       infinite: true,
       speed: 500,
       arrows: false,
       autoplay: true,
       autoplaySpeed: 3000,
       slidesToShow: 1,
       slidesToScroll: 1,
     };
     var settingsTestimonial = {
       dots: true,
       infinite: true,
       speed: 500,
       arrows: false,
       autoplay: true,
       autoplaySpeed: 3000,
       slidesToShow: 4,
       slidesToScroll: 1,
       responsive: [
         {
           breakpoint: 1199,
           settings: {
             slidesToShow: 3,
             slidesToScroll: 1
           }
         },
         {
           breakpoint: 992,
           settings: {
             slidesToShow: 2,
             slidesToScroll: 1
           }
         },
         {
           breakpoint: 768,
           settings: {
             centerMode: true,
             centerPadding: '30px',
             slidesToShow: 1
           }
         }
       ]
     };
  return (
    <HomePage>
      <section className='home_001 banner-section-block'>
        {/* <div className="container h-100">
        <div className="row h-100">
            <div className="col-12 col-lg-4 col-md-5 my-auto">
                <div className="box_banner">
                    <h2>Is
                        <span>PAIN</span>
                        holding
                        you back?</h2>
                </div>
            </div>
        </div>
    </div> */}
        <div className='banner-section-block-inner'>
          <div className='common-container'>
            <div
              className='setting-block-inner'
              data-aos='fade-right'
              data-aos-offset='200'
              data-aos-easing='ease-in-sine'
              data-aos-duration='600'>
              <Slider {...settings}>
                <div>
                  <div className='setting-block-title'>
                    <h3>
                      Is <span>Pain</span>
                      <br></br> Holding<br></br> You Back?
                    </h3>
                  </div>
                </div>
                <div>
                  <div className='setting-block-title'>
                    <h3>
                      Is <span>Pain</span>
                      <br></br> Holding<br></br> You Back?
                    </h3>
                  </div>
                </div>
                <div>
                  <div className='setting-block-title'>
                    <h3>
                      Is <span>Pain</span>
                      <br></br> Holding<br></br> You Back?
                    </h3>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <section className='second-block'>
        <div className='common-container'>
          <div className='second-block-inner'>
            <div className='second-block-inner-block'>
              <h2 data-aos='fade-zoom-in' data-aos-offset='200' data-aos-easing='ease-in-sine' data-aos-duration='800'>
                We offer <span>PERSONALIZED</span>
                <br></br>
                corrective-exercise programs
                <br></br>
                based on your <span className='pr-2'>ASSESSMENT</span>results
              </h2>
            </div>
            <div className='second-block-inner-block-right'>
              <div
                className='second-block-inner-block-right-inner'
                data-aos='fade-zoom-in'
                data-aos-offset='200'
                data-aos-easing='ease-in-sine'
                data-aos-duration='600'>
                <h3>
                  Tired of <span>Hurting ?</span>
                  <br></br> Keep Getting <span>Injured ?</span>
                  <br></br>
                </h3>
                <h3>
                  Muscle Imbalances <br></br>may be to blame
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='one-size-block'>
        <div className='common-container'>
          <div
            className='common-title-block'
            data-aos='fade-zoom-in'
            data-aos-offset='200'
            data-aos-easing='ease-in-sine'
            data-aos-duration='600'>
            <h3>
              These are NOT one-size-fits-all <span>programs!</span>
            </h3>
            <p>Each program is tailored to your Body Type which will be determined from your Assessment Results.</p>
          </div>
        </div>
        <div className='img-chance-bloc'>
          <img src='/images/chanse-img.png' alt='img' />
        </div>
      </section>
      <section className='video-block'>
        <div className='common-container'>
          <div
            className='video-slider'
            data-aos='fade-up'
            data-aos-offset='200'
            data-aos-easing='ease-in-sine'
            data-aos-duration='600'>
            <Slider {...settingsVideo}>
              <div>
                <div className='video-slider'>
                  <video
                    className='video'
                    controls='controls'
                    width='100%'
                    height='100%'
                    poster='images/poster.png'
                    autoPlay={true}>
                    <source src='images/ankle-scraping.mp4' />
                  </video>
                  <button className='video-play-icon'>
                    <svg width='41' height='41' viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M20.8637 0.939392C9.87644 0.939392 0.977295 9.75378 0.977295 20.6364C0.977295 31.5189 9.87644 40.3333 20.8637 40.3333C31.8509 40.3333 40.75 31.5189 40.75 20.6364C40.75 9.75378 31.8509 0.939392 20.8637 0.939392ZM16.8864 29.5V11.7727L28.8182 20.6364L16.8864 29.5Z'
                        fill='#C00000'></path>
                    </svg>
                  </button>
                </div>
              </div>
              <div>
                <div className='video-slider'>
                  <video
                    className='video'
                    controls='controls'
                    width='100%'
                    height='100%'
                    poster='images/poster.png'
                    autoPlay={true}>
                    <source src='images/ankle-scraping.mp4' />
                  </video>
                  <button className='video-play-icon'>
                    <svg width='41' height='41' viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M20.8637 0.939392C9.87644 0.939392 0.977295 9.75378 0.977295 20.6364C0.977295 31.5189 9.87644 40.3333 20.8637 40.3333C31.8509 40.3333 40.75 31.5189 40.75 20.6364C40.75 9.75378 31.8509 0.939392 20.8637 0.939392ZM16.8864 29.5V11.7727L28.8182 20.6364L16.8864 29.5Z'
                        fill='#C00000'></path>
                    </svg>
                  </button>
                </div>
              </div>
              <div>
                <div className='video-slider'>
                  <video
                    className='video'
                    controls='controls'
                    width='100%'
                    height='100%'
                    poster='images/poster.png'
                    autoPlay={true}>
                    <source src='images/ankle-scraping.mp4' />
                  </video>
                  <button className='video-play-icon'>
                    <svg width='41' height='41' viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M20.8637 0.939392C9.87644 0.939392 0.977295 9.75378 0.977295 20.6364C0.977295 31.5189 9.87644 40.3333 20.8637 40.3333C31.8509 40.3333 40.75 31.5189 40.75 20.6364C40.75 9.75378 31.8509 0.939392 20.8637 0.939392ZM16.8864 29.5V11.7727L28.8182 20.6364L16.8864 29.5Z'
                        fill='#C00000'></path>
                    </svg>
                  </button>
                </div>
              </div>
              <div>
                <div className='video-slider'>
                  <video
                    className='video'
                    controls='controls'
                    width='100%'
                    height='100%'
                    poster='images/poster.png'
                    autoPlay={true}>
                    <source src='images/ankle-scraping.mp4' />
                  </video>
                  <button className='video-play-icon'>
                    <svg width='41' height='41' viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M20.8637 0.939392C9.87644 0.939392 0.977295 9.75378 0.977295 20.6364C0.977295 31.5189 9.87644 40.3333 20.8637 40.3333C31.8509 40.3333 40.75 31.5189 40.75 20.6364C40.75 9.75378 31.8509 0.939392 20.8637 0.939392ZM16.8864 29.5V11.7727L28.8182 20.6364L16.8864 29.5Z'
                        fill='#C00000'></path>
                    </svg>
                  </button>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
      <section className='about-section'>
        <div className='common-container'>
          <div className='about-section-inner'>
            <div className='about-section-left'>
              <div className='common-title-block'>
                <h3 data-aos='fade-up' data-aos-offset='200' data-aos-easing='ease-in-sine' data-aos-duration='600'>
                  <span>About Us</span>
                </h3>
                <p data-aos='fade-up' data-aos-offset='200' data-aos-easing='ease-in-sine' data-aos-duration='630'>
                  Helping people get out of pain is what we do!{' '}
                </p>
                <p data-aos='fade-up' data-aos-offset='200' data-aos-easing='ease-in-sine' data-aos-duration='660'>
                  We specialize in identifying and correcting muscle imbalances that cause pain.{' '}
                  <Link href=''>Learn More</Link>{' '}
                </p>
              </div>
              <div className='common-title-block'>
                <h3 data-aos='fade-up' data-aos-offset='200' data-aos-easing='ease-in-sine' data-aos-duration='690'>
                  <span>Assessment</span>
                </h3>
                <p data-aos='fade-up' data-aos-offset='200' data-aos-easing='ease-in-sine' data-aos-duration='720'>
                  A thorough assessment is essential to determine if muscle imbalances might be the cause of your pain
                  and dysfunction.
                </p>
                <p data-aos='fade-up' data-aos-offset='200' data-aos-easing='ease-in-sine' data-aos-duration='750'>
                  Our ASSESSMENT utilizes artificial intelligence to identify muscles in your body that may not be
                  working as they should.<Link href=''>Learn More</Link>{' '}
                </p>
              </div>
            </div>
            <div className='about-section-right'>
              <div
                className='slider-section-about'
                data-aos='fade-up'
                data-aos-offset='200'
                data-aos-easing='ease-in-sine'
                data-aos-duration='600'>
                <Slider {...settingsAbout}>
                  <div>
                    <div className='video-slider'>
                      <video
                        className='video'
                        controls='controls'
                        width='100%'
                        height='100%'
                        poster='images/poster.png'
                        autoPlay={true}>
                        <source src='images/ankle-scraping.mp4' />
                      </video>
                      <button className='video-play-icon'>
                        <svg width='41' height='41' viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M20.8637 0.939392C9.87644 0.939392 0.977295 9.75378 0.977295 20.6364C0.977295 31.5189 9.87644 40.3333 20.8637 40.3333C31.8509 40.3333 40.75 31.5189 40.75 20.6364C40.75 9.75378 31.8509 0.939392 20.8637 0.939392ZM16.8864 29.5V11.7727L28.8182 20.6364L16.8864 29.5Z'
                            fill='#C00000'></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className='video-slider'>
                      <video
                        className='video'
                        controls='controls'
                        width='100%'
                        height='100%'
                        poster='images/poster.png'
                        autoPlay={true}>
                        <source src='images/ankle-scraping.mp4' />
                      </video>
                      <button className='video-play-icon'>
                        <svg width='41' height='41' viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M20.8637 0.939392C9.87644 0.939392 0.977295 9.75378 0.977295 20.6364C0.977295 31.5189 9.87644 40.3333 20.8637 40.3333C31.8509 40.3333 40.75 31.5189 40.75 20.6364C40.75 9.75378 31.8509 0.939392 20.8637 0.939392ZM16.8864 29.5V11.7727L28.8182 20.6364L16.8864 29.5Z'
                            fill='#C00000'></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className='video-slider'>
                      <video
                        className='video'
                        controls='controls'
                        width='100%'
                        height='100%'
                        poster='images/poster.png'
                        autoPlay={true}>
                        <source src='images/ankle-scraping.mp4' />
                      </video>
                      <button className='video-play-icon'>
                        <svg width='41' height='41' viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M20.8637 0.939392C9.87644 0.939392 0.977295 9.75378 0.977295 20.6364C0.977295 31.5189 9.87644 40.3333 20.8637 40.3333C31.8509 40.3333 40.75 31.5189 40.75 20.6364C40.75 9.75378 31.8509 0.939392 20.8637 0.939392ZM16.8864 29.5V11.7727L28.8182 20.6364L16.8864 29.5Z'
                            fill='#C00000'></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className='video-slider'>
                      <video
                        className='video'
                        controls='controls'
                        width='100%'
                        height='100%'
                        poster='images/poster.png'
                        autoPlay={true}>
                        <source src='images/ankle-scraping.mp4' />
                      </video>
                      <button className='video-play-icon'>
                        <svg width='41' height='41' viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M20.8637 0.939392C9.87644 0.939392 0.977295 9.75378 0.977295 20.6364C0.977295 31.5189 9.87644 40.3333 20.8637 40.3333C31.8509 40.3333 40.75 31.5189 40.75 20.6364C40.75 9.75378 31.8509 0.939392 20.8637 0.939392ZM16.8864 29.5V11.7727L28.8182 20.6364L16.8864 29.5Z'
                            fill='#C00000'></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='determine-block'>
        <div className='determine-block-img'>
          <img src='/images/determine-img.png' alt='img' />
        </div>
        <div className='common-container'>
          <div
            className='determine-block-text'
            data-aos='fade-up'
            data-aos-offset='200'
            data-aos-easing='ease-in-sine'
            data-aos-duration='600'>
            <h3>
              Take our ASSESSMENT to determine<br></br> which program is best for you
            </h3>
            <button className='common-btn'>Take Our Assessment</button>
          </div>
        </div>
      </section>
      <section className='step-block'>
        <div className='common-container'>
          <div className='common-title-block'>
            <h3 data-aos='fade-zoom-in' data-aos-offset='200' data-aos-easing='ease-in-sine' data-aos-duration='600'>
              How It <span>Works</span> - 3 Steps
            </h3>
          </div>
        </div>
        <div className='common-container step-block-container'>
          <div className='step-block-inner'>
            <div
              className='step-block-inner-first common-step-block'
              data-aos='fade-up'
              data-aos-anchor-placement='top-bottom'>
              <div className='round-block'>
                <h4>RECORD</h4>
              </div>
              <ol>
                <li>Record yourself and answer questions as you are guided through a series of movements. </li>
              </ol>
            </div>
            <div
              className='step-block-inner-second common-step-block'
              data-aos='fade-up'
              data-aos-anchor-placement='top-bottom'>
              <div className='round-block'>
                <h4>ANALYZE</h4>
              </div>
              <ol>
                <li></li>
                <li>Your dominant and under-active muscles will be determined. </li>
              </ol>
            </div>
            <div
              className='step-block-inner-third common-step-block'
              data-aos='fade-up'
              data-aos-anchor-placement='top-bottom'>
              <div className='round-block'>
                <h4>RESULTS</h4>
              </div>
              <ol>
                <li></li>
                <li></li>
                <li>Receive a BODY TYPE and a 12-week corrective-exercise program. </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className='receive-block'>
        <div className='common-container'>
          <div className='receive-block-inner'>
            <div className='receive-block-inner-left'>
              <h3 data-aos='fade-zoom-in' data-aos-offset='200' data-aos-easing='ease-in-sine' data-aos-duration='600'>
                What You Will <span>Receive</span>
              </h3>
              <div className='programe-block'>
                <h4
                  data-aos='fade-zoom-in'
                  data-aos-offset='200'
                  data-aos-easing='ease-in-sine'
                  data-aos-duration='630'>
                  Each <span className='pr-2'>Programs</span>
                  Includes:
                </h4>
                <ul>
                  <li
                    data-aos='fade-zoom-in'
                    data-aos-offset='200'
                    data-aos-easing='ease-in-sine'
                    data-aos-duration='660'>
                    <img src='/images/check-arrow-img.svg' alt='img' />
                    Corrective exercises & stretches for your BODY TYPE
                  </li>
                  <li
                    data-aos='fade-zoom-in'
                    data-aos-offset='200'
                    data-aos-easing='ease-in-sine'
                    data-aos-duration='690'>
                    <img src='/images/check-arrow-img.svg' alt='img' />
                    Trigger Point & Muscle Library
                  </li>
                </ul>
              </div>
              <div className='programe-block'>
                <h4
                  data-aos='fade-zoom-in'
                  data-aos-offset='200'
                  data-aos-easing='ease-in-sine'
                  data-aos-duration='720'>
                  Educational <span>Videos</span>
                </h4>
                <ul>
                  <li
                    data-aos='fade-zoom-in'
                    data-aos-offset='200'
                    data-aos-easing='ease-in-sine'
                    data-aos-duration='750'>
                    <img src='/images/check-arrow-img.svg' alt='img' />
                    Learn about muscle imbalances and prevention
                  </li>
                  <li
                    data-aos='fade-zoom-in'
                    data-aos-offset='200'
                    data-aos-easing='ease-in-sine'
                    data-aos-duration='780'>
                    <img src='/images/check-arrow-img.svg' alt='img' />
                    Tips on how to perform each exercise correctly
                  </li>
                  <li
                    data-aos='fade-zoom-in'
                    data-aos-offset='200'
                    data-aos-easing='ease-in-sine'
                    data-aos-duration='810'>
                    <img src='/images/check-arrow-img.svg' alt='img' />
                    Common exercise mistakes
                  </li>
                  <li
                    data-aos='fade-zoom-in'
                    data-aos-offset='200'
                    data-aos-easing='ease-in-sine'
                    data-aos-duration='840'>
                    <img src='/images/check-arrow-img.svg' alt='img' />
                    Exercises and stretches to avoid, for now
                  </li>
                  <li
                    data-aos='fade-zoom-in'
                    data-aos-offset='200'
                    data-aos-easing='ease-in-sine'
                    data-aos-duration='870'>
                    <img src='/images/check-arrow-img.svg' alt='img' />
                    3D animated skeleton and muscle videos
                  </li>
                </ul>
              </div>
              <div
                className='learm-more-link'
                data-aos='fade-zoom-in'
                data-aos-offset='200'
                data-aos-easing='ease-in-sine'
                data-aos-duration='900'>
                <Link href=''> Learn more</Link>
              </div>
            </div>
            <div className='receive-block-inner-right'>
              <div
                className='receive-block-inner-right-top'
                data-aos='fade-zoom-in'
                data-aos-offset='200'
                data-aos-easing='ease-in-sine'
                data-aos-duration='650'>
                <img src='/images/computer-mobile-img.png' alt='img' />
              </div>
              <div className='receive-block-inner-right-bottom'>
                <div
                  className='receive-block-inner-right-bottom-inner'
                  data-aos='fade-zoom-in'
                  data-aos-offset='200'
                  data-aos-easing='ease-in-sine'
                  data-aos-duration='680'>
                  <div className='receive-block-video'>
                    <video
                      className='video'
                      controls='controls'
                      width='100%'
                      height='100%'
                      poster='images/poster.png'
                      autoPlay={true}>
                      <source src='images/ankle-scraping.mp4' />
                    </video>
                    <button className='video-play-icon'>
                      <svg width='41' height='41' viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M20.8637 0.939392C9.87644 0.939392 0.977295 9.75378 0.977295 20.6364C0.977295 31.5189 9.87644 40.3333 20.8637 40.3333C31.8509 40.3333 40.75 31.5189 40.75 20.6364C40.75 9.75378 31.8509 0.939392 20.8637 0.939392ZM16.8864 29.5V11.7727L28.8182 20.6364L16.8864 29.5Z'
                          fill='#C00000'></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div
                  className='receive-block-inner-right-bottom-inner'
                  data-aos='fade-zoom-in'
                  data-aos-offset='200'
                  data-aos-easing='ease-in-sine'
                  data-aos-duration='710'>
                  <div className='receive-block-video'>
                    <video
                      className='video'
                      controls='controls'
                      width='100%'
                      height='100%'
                      poster='images/poster.png'
                      autoPlay={true}>
                      <source src='images/ankle-scraping.mp4' />
                    </video>
                    <button className='video-play-icon'>
                      <svg width='41' height='41' viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M20.8637 0.939392C9.87644 0.939392 0.977295 9.75378 0.977295 20.6364C0.977295 31.5189 9.87644 40.3333 20.8637 40.3333C31.8509 40.3333 40.75 31.5189 40.75 20.6364C40.75 9.75378 31.8509 0.939392 20.8637 0.939392ZM16.8864 29.5V11.7727L28.8182 20.6364L16.8864 29.5Z'
                          fill='#C00000'></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div
                  className='receive-block-inner-right-bottom-inner'
                  data-aos='fade-zoom-in'
                  data-aos-offset='200'
                  data-aos-easing='ease-in-sine'
                  data-aos-duration='730'>
                  <div className='receive-block-video'>
                    <video
                      className='video'
                      controls='controls'
                      width='100%'
                      height='100%'
                      poster='images/poster.png'
                      autoPlay={true}>
                      <source src='images/ankle-scraping.mp4' />
                    </video>
                    <button className='video-play-icon'>
                      <svg width='41' height='41' viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M20.8637 0.939392C9.87644 0.939392 0.977295 9.75378 0.977295 20.6364C0.977295 31.5189 9.87644 40.3333 20.8637 40.3333C31.8509 40.3333 40.75 31.5189 40.75 20.6364C40.75 9.75378 31.8509 0.939392 20.8637 0.939392ZM16.8864 29.5V11.7727L28.8182 20.6364L16.8864 29.5Z'
                          fill='#C00000'></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='testimonial-block'>
        {/* <div className='testimonial-block-inner'>
          <div className='testimonial-block-inner-left'></div>
          <div className='testimonial-block-inner-right'>
            <img src='/images/img-about.png' alt='img' />
          </div>
        </div> */}
        <div className='testimonial-block-data'>
          {/* <div className='common-container'> */}
          <div className='testimonial-width'>
            {/* <h2>What Our Clients Are Saying</h2> */}
            <div
              className='testimonial-slider'
              data-aos='fade-zoom-in'
              data-aos-offset='200'
              data-aos-easing='ease-in-sine'
              data-aos-duration='730'>
              <Slider {...settingsAbout}>
                <div>
                  <div className='testimonial-block-inner'>
                    <div className='testimonial-block-inner-left'>
                      <div className='testimonial-content'>
                        <h2>What Our Clients Are Saying</h2>
                        <p>
                          I am finally able to train consistently again! I have tried so many one-size-fits all
                          approaches, that never gave me lasting results. I trust Body Solutions with my body.
                        </p>
                        <h5>Tim Alexander, Triathlete</h5>
                      </div>
                    </div>
                    <div className='testimonial-block-inner-right'>
                      <img src='/images/img-about.png' alt='img' />
                    </div>
                  </div>
                </div>
                <div>
                  <div className='testimonial-block-inner'>
                    <div className='testimonial-block-inner-left'>
                      <div className='testimonial-content'>
                        <h2>What Our Clients Are Saying</h2>
                        <p>
                          I am finally able to train consistently again! I have tried so many one-size-fits all
                          approaches, that never gave me lasting results. I trust Body Solutions with my body.
                        </p>
                        <h5>Tim Alexander, Triathlete</h5>
                      </div>
                    </div>
                    <div className='testimonial-block-inner-right'>
                      <img src='/images/img-about.png' alt='img' />
                    </div>
                  </div>
                </div>
                <div>
                  <div className='testimonial-block-inner'>
                    <div className='testimonial-block-inner-left'>
                      <div className='testimonial-content'>
                        <h2>What Our Clients Are Saying</h2>
                        <p>
                          I am finally able to train consistently again! I have tried so many one-size-fits all
                          approaches, that never gave me lasting results. I trust Body Solutions with my body.
                        </p>
                        <h5>Tim Alexander, Triathlete</h5>
                      </div>
                    </div>
                    <div className='testimonial-block-inner-right'>
                      <img src='/images/img-about.png' alt='img' />
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
          {/* </div> */}
        </div>
      </section>
      <section
        className='testimonial-block-last'
        data-aos='fade-up'
        data-aos-offset='200'
        data-aos-easing='ease-in-sine'
        data-aos-duration='600'>
        <div className='common-container'>
          <Slider {...settingsTestimonial}>
            <div>
              <div className='testimonial-block-last-inner'>
                <div className='testimonial-block-box'>
                  <div className='testimonial-block-box-inner'>
                    <div className='testimonial-img'>
                      <img src='/images/img41.png' alt='img' />
                    </div>
                    <p>“ I had frozen shoulder and Body Solution was able to get me my full range of motion back.”</p>
                    <h6>-Kelly</h6>
                    <ul>
                      <li>
                        <img src='/images/star-rating-icon.png' alt='img' />
                      </li>
                      <li>
                        <img src='/images/star-rating-icon.png' alt='img' />
                      </li>
                      <li>
                        <img src='/images/star-rating-icon.png' alt='img' />
                      </li>
                      <li>
                        <img src='/images/star-rating-icon.png' alt='img' />
                      </li>
                      <li>
                        <img src='/images/star-rating-icon.png' alt='img' />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className='testimonial-block-last-inner'>
                <div className='testimonial-block-box'>
                  <div className='testimonial-block-box-inner'>
                    <div className='testimonial-img'>
                      <img src='/images/img41.png' alt='img' />
                    </div>
                    <p>“ I had frozen shoulder and Body Solution was able to get me my full range of motion back.”</p>
                    <h6>-Kelly</h6>
                    <ul>
                      <li>
                        <img src='/images/star-rating-icon.png' alt='img' />
                      </li>
                      <li>
                        <img src='/images/star-rating-icon.png' alt='img' />
                      </li>
                      <li>
                        <img src='/images/star-rating-icon.png' alt='img' />
                      </li>
                      <li>
                        <img src='/images/star-rating-icon.png' alt='img' />
                      </li>
                      <li>
                        <img src='/images/star-rating-icon.png' alt='img' />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className='testimonial-block-last-inner'>
                <div className='testimonial-block-box'>
                  <div className='testimonial-block-box-inner'>
                    <div className='testimonial-img'>
                      <img src='/images/img41.png' alt='img' />
                    </div>
                    <p>“ I had frozen shoulder and Body Solution was able to get me my full range of motion back.”</p>
                    <h6>-Kelly</h6>
                    <ul>
                      <li>
                        <img src='/images/star-rating-icon.png' alt='img' />
                      </li>
                      <li>
                        <img src='/images/star-rating-icon.png' alt='img' />
                      </li>
                      <li>
                        <img src='/images/star-rating-icon.png' alt='img' />
                      </li>
                      <li>
                        <img src='/images/star-rating-icon.png' alt='img' />
                      </li>
                      <li>
                        <img src='/images/star-rating-icon.png' alt='img' />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className='testimonial-block-last-inner'>
                <div className='testimonial-block-box'>
                  <div className='testimonial-block-box-inner'>
                    <div className='testimonial-img'>
                      <img src='/images/img41.png' alt='img' />
                    </div>
                    <p>“ I had frozen shoulder and Body Solution was able to get me my full range of motion back.”</p>
                    <h6>-Kelly</h6>
                    <ul>
                      <li>
                        <img src='/images/star-rating-icon.png' alt='img' />
                      </li>
                      <li>
                        <img src='/images/star-rating-icon.png' alt='img' />
                      </li>
                      <li>
                        <img src='/images/star-rating-icon.png' alt='img' />
                      </li>
                      <li>
                        <img src='/images/star-rating-icon.png' alt='img' />
                      </li>
                      <li>
                        <img src='/images/star-rating-icon.png' alt='img' />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className='testimonial-block-last-inner'>
                <div className='testimonial-block-box'>
                  <div className='testimonial-block-box-inner'>
                    <div className='testimonial-img'>
                      <img src='/images/img41.png' alt='img' />
                    </div>
                    <p>“ I had frozen shoulder and Body Solution was able to get me my full range of motion back.”</p>
                    <h6>-Kelly</h6>
                    <ul>
                      <li>
                        <img src='/images/star-rating-icon.png' alt='img' />
                      </li>
                      <li>
                        <img src='/images/star-rating-icon.png' alt='img' />
                      </li>
                      <li>
                        <img src='/images/star-rating-icon.png' alt='img' />
                      </li>
                      <li>
                        <img src='/images/star-rating-icon.png' alt='img' />
                      </li>
                      <li>
                        <img src='/images/star-rating-icon.png' alt='img' />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
          {/* <div className='testimonial-block-last-inner'>
            <div className='testimonial-block-box'>
              <div className='testimonial-block-box-inner'>
                <div className='testimonial-img'>
                  <img src='/images/img41.png' alt='img' />
                </div>
                <p>“ I had frozen shoulder and Body Solution was able to get me my full range of motion back.”</p>
                <h6>-Kelly</h6>
                <ul>
                  <li>
                    <img src='/images/star-rating-icon.png' alt='img' />
                  </li>
                  <li>
                    <img src='/images/star-rating-icon.png' alt='img' />
                  </li>
                  <li>
                    <img src='/images/star-rating-icon.png' alt='img' />
                  </li>
                  <li>
                    <img src='/images/star-rating-icon.png' alt='img' />
                  </li>
                  <li>
                    <img src='/images/star-rating-icon.png' alt='img' />
                  </li>
                </ul>
              </div>
            </div>
            <div className='testimonial-block-box'>
              <div className='testimonial-block-box-inner'>
                <div className='testimonial-img'>
                  <img src='/images/img41.png' alt='img' />
                </div>
                <p>“ I had frozen shoulder and Body Solution was able to get me my full range of motion back.”</p>
                <h6>-Kelly</h6>
                <ul>
                  <li>
                    <img src='/images/star-rating-icon.png' alt='img' />
                  </li>
                  <li>
                    <img src='/images/star-rating-icon.png' alt='img' />
                  </li>
                  <li>
                    <img src='/images/star-rating-icon.png' alt='img' />
                  </li>
                  <li>
                    <img src='/images/star-rating-icon.png' alt='img' />
                  </li>
                  <li>
                    <img src='/images/star-rating-icon.png' alt='img' />
                  </li>
                </ul>
              </div>
            </div>
            <div className='testimonial-block-box'>
              <div className='testimonial-block-box-inner'>
                <div className='testimonial-img'>
                  <img src='/images/img41.png' alt='img' />
                </div>
                <p>“ I had frozen shoulder and Body Solution was able to get me my full range of motion back.”</p>
                <h6>-Kelly</h6>
                <ul>
                  <li>
                    <img src='/images/star-rating-icon.png' alt='img' />
                  </li>
                  <li>
                    <img src='/images/star-rating-icon.png' alt='img' />
                  </li>
                  <li>
                    <img src='/images/star-rating-icon.png' alt='img' />
                  </li>
                  <li>
                    <img src='/images/star-rating-icon.png' alt='img' />
                  </li>
                  <li>
                    <img src='/images/star-rating-icon.png' alt='img' />
                  </li>
                </ul>
              </div>
            </div>
            <div className='testimonial-block-box'>
              <div className='testimonial-block-box-inner'>
                <div className='testimonial-img'>
                  <img src='/images/img41.png' alt='img' />
                </div>
                <p>“ I had frozen shoulder and Body Solution was able to get me my full range of motion back.”</p>
                <h6>-Kelly</h6>
                <ul>
                  <li>
                    <img src='/images/star-rating-icon.png' alt='img' />
                  </li>
                  <li>
                    <img src='/images/star-rating-icon.png' alt='img' />
                  </li>
                  <li>
                    <img src='/images/star-rating-icon.png' alt='img' />
                  </li>
                  <li>
                    <img src='/images/star-rating-icon.png' alt='img' />
                  </li>
                  <li>
                    <img src='/images/star-rating-icon.png' alt='img' />
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <section className='goal-block'>
        <div className='common-container'>
          <div className='common-title-block'>
            <h3 data-aos='fade-up' data-aos-offset='200' data-aos-easing='ease-in-sine' data-aos-duration='630'>
              Our End
              <span className='pl-2'>Goal</span>
            </h3>
            <p data-aos='fade-up' data-aos-offset='200' data-aos-easing='ease-in-sine' data-aos-duration='660'>
              Help as many people as possible continue to do the things they love to do!
            </p>
          </div>
          <div className='goal-block-img'>
            <img
              data-aos='fade-up'
              data-aos-offset='200'
              data-aos-easing='ease-in-sine'
              data-aos-duration='690'
              src='/images/athelet-img-block.png'
              alt='img'
            />
            <div
              className='goal-block-img'
              data-aos='fade-up'
              data-aos-offset='200'
              data-aos-easing='ease-in-sine'
              data-aos-duration='710'>
              <video
                className='video'
                controls='controls'
                width='100%'
                height='100%'
                poster='images/poster.png'
                autoPlay={true}>
                <source src='images/ankle-scraping.mp4' />
              </video>
              <button className='video-play-icon'>
                <svg width='41' height='41' viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M20.8637 0.939392C9.87644 0.939392 0.977295 9.75378 0.977295 20.6364C0.977295 31.5189 9.87644 40.3333 20.8637 40.3333C31.8509 40.3333 40.75 31.5189 40.75 20.6364C40.75 9.75378 31.8509 0.939392 20.8637 0.939392ZM16.8864 29.5V11.7727L28.8182 20.6364L16.8864 29.5Z'
                    fill='#C00000'></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </section>
    </HomePage>
  );
}

export default Home1