import Carousel from "react-bootstrap/Carousel";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import {
  getExerciseDetail,
  getProgram,
  getWeekSchedule,
  updateProgramDayStatus,
  // getEducationalVideos
} from "../../../actions/user_program";
import { FadeLoader } from "react-spinners";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Exercises from "./Exercises";
import BreadcrumbMenu from "../BreadcrumbMenu";
import { UPDATE_PROGRAM_STATUS_PURGE } from "../../../actions/types";
import { RiMiniProgramLine } from "react-icons/ri";
import CustomVideoPlayer from '../AssessmentTimer/CustomVideoPlayer';
import { CommonPageData } from '../../../styles/common.style';
import { Link } from 'react-router-dom';
import { RiLayoutGridFill, RiListUnordered } from 'react-icons/ri';

const WeekSlider = ({
  weekScheduleRequest,
  weekScheduleSuccess,
  weekScheduleFailure,
  programId,
  getWeekSchedule,
  getProgram,
  weekSchedule,
  getExerciseDetail,
  exercises,
  updateProgramDayStatus,
  userProgramDayStatusUpdateSuccess,
  start,
  setStart,
  educational_video,
  program
  // getEducationalVideos
}) => {
  const navigate = useNavigate();
  let { id } = useParams();
  const dispatch = useDispatch();
  const [activeDayId, setActiveDayId] = useState(null);
  const [activeDayName, setActiveDayName] = useState(null);
  const [resume, setResume] = useState(false);
  const [view, setView] = useState(false);
  const [programEdVideos, setProgramEdVideos] = useState(null)
  const [itemsEdPerPage, setItemsEdPerPage] = useState(8);
  const [currentEdPage, setCurrentEdPage] = useState(educational_video ? parseInt(educational_video.meta.current_page) : 1);
  const [totalEdPages, setEdTotalPages] = useState(0)
  const location = useLocation();
  const [isChecked, setIsChecked] = useState(false);
  const [activeWeekIndex, setActiveWeekIndex] = useState(0);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    setView(event.target.checked);
  };
  // useEffect(() => {
  // getEducationalVideos(id, currentEdPage, itemsEdPerPage, 1);

  //   if (educational_video?.data) {
  //     const uniqueItems = filterUniqueItems(educational_video.data);
  //     console.log(uniqueItems, "uniqueItems")
  //   }
  // }, [currentEdPage, itemsEdPerPage]);

  // useEffect(() => {
  //   setEdTotalPages(educational_video ? parseInt(educational_video.meta.last_page) : 1)
  //   setProgramEdVideos(educational_video ? educational_video.data : [])
  // }, [educational_video]);

  // const filterUniqueItems = (arr) => {
  //   const uniqueIds = new Set();

  //   const filteredArray = arr.filter((item) => {
  //     if (!uniqueIds.has(item.video)) {
  //       uniqueIds.add(item.video);
  //       return true;
  //     }
  //     return false;
  //   });

  //   return filteredArray;
  // };

  const breadcrumbs = [
    { label: 'Home', link: '/' },
    { label: `${program?.name}`, link: '#', isActive: true }
  ];


  useEffect(() => {
    getProgram(id)
    getWeekSchedule(id);
  }, []);

  useEffect(() => {
    let activeEx = exercises?.filter((item, index) => item.is_started == true);

    setResume(activeEx?.length > 0 ? true : false);
  }, [exercises]);

  useEffect(() => {
    if (activeDayId != null && activeDayId != "" && activeDayId != undefined) {
      let days =
        weekSchedule && weekSchedule[0]["days"] ? weekSchedule[0]["days"] : [];
      localStorage.setItem("activeDayId", activeDayId);
      let nextActiveDayIndex = days.findIndex(
        (item, index) => item.id === activeDayId
      );
      let nextActiveDay = days[nextActiveDayIndex + 1];
      if (nextActiveDay !== undefined && nextActiveDay !== null) {
        localStorage.setItem("nextActiveDay", nextActiveDay.id);
      }
      getExerciseDetail(activeDayId);
    }
  }, [activeDayId]);

  useEffect(() => {
    if (
      weekSchedule != null &&
      weekSchedule != "" &&
      weekSchedule != undefined
    ) {
      let days =
        weekSchedule && weekSchedule[0]["days"] ? weekSchedule[0]["days"] : [];
      let activeDay = days.filter((item, index) => item.is_active == 1);
      setActiveDayName(
        activeDay && activeDay[0] != null && activeDay[0] != undefined
          ? "Day " + activeDay[0]["programDay"]["day"]
          : weekSchedule[0]["days"]
            ? "Day " + weekSchedule[0]["days"][0]["programDay"]["day"]
            : ""
      );
      setActiveDayId(
        activeDay && activeDay[0] != null && activeDay[0] != undefined
          ? activeDay[0].id
          : weekSchedule && weekSchedule[0]["days"]
            ? weekSchedule[0]["days"][0].id
            : ""
      );
      localStorage.setItem("nextActiveDayId", activeDayId);
    }
  }, [weekSchedule]);

  const handleItemsEdPerPageChange = (e) => {
    setItemsEdPerPage(parseInt(e.target.value));
  };

  const changeDayId = (id, program_id, day) => {
    setActiveDayId(id);

    let dt = {
      is_active: 1,
      program_id: program_id,
    };
    updateProgramDayStatus(dt, id);
    localStorage.setItem("activeDayName", `Day ${day}`);
  };

  if (weekScheduleFailure) {
    navigate("/welcome", { replace: true });
  } else if (!weekScheduleSuccess) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 center-loader">
            <FadeLoader color={"#FB1417"} height={10} />
          </div>
        </div>
      </div>
    );
  }
  if (userProgramDayStatusUpdateSuccess) {
    dispatch({ type: UPDATE_PROGRAM_STATUS_PURGE });
    getWeekSchedule(id);
  }

  const formatDate = (isoDate, weekNumber) => {
    const date = new Date(isoDate);
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
    });
    return formattedDate;
  };

  const weeks = Array.from({ length: 12 }, (_, index) => ({
    week: index + 1,
    days: [],
    start_at: null,
    end_at: null,
  }));

  const weekOne = weekSchedule?.find((apiWeek) => apiWeek.week === 1);
  const weekOneDays = weekOne?.days || [];
  const startAtDate = weekOneDays[0]?.start_at ? new Date(weekOneDays[0].start_at) : null;
  const endAtDate = weekOneDays[0]?.end_at ? new Date(weekOneDays[0].end_at) : null;

  if (startAtDate) {
    weeks.forEach((week, index) => {
      const startDate = new Date(startAtDate);
      startDate.setDate(startDate.getDate() + index * 7);

      const endDate = new Date(endAtDate);
      endDate.setDate(endDate.getDate());

      week.days = [...weekOneDays];
      // week.start_at = formatDate(startDate.toISOString());
      // week.end_at = formatDate(endDate.toISOString());
      week.start_at = startDate.toISOString();
      week.end_at = endDate.toISOString();
    });
  }

  const handleSelect = (selectedIndex) => {
    setActiveWeekIndex(selectedIndex);
  };
  

  // const weekOneData = weekSchedule?.find((apiWeek) => apiWeek.week === 1)?.days || [];

  // weeks.forEach((week) => {
  //   console.log("weekOneData", weekOneData[0]);

  //   const startAt = formatDate(weekOneData[0]?.start_at) || null;
  //   week.days = [...weekOneData];

  //   if (week.week === 1) {
  //     week.start_at = startAt;
  //   }
  // });

  return (
    <>
      <CommonPageData>
        <section className='assestmentgrid_001'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 col-lg-12 col-md-12 p-0'>
                <Carousel className="box_week" activeIndex={activeWeekIndex} onSelect={handleSelect} indicators={false} nextLabel="" prevLabel="" interval={null}>
                  {weeks.map((week) => (
                    <Carousel.Item style={{ textAlign: "center" }} key={week.week}>
                      <h6>{formatDate(week.start_at)} - Week {week.week}</h6>
                      <div className="box_date">
                        <ul className="date_week">
                          {week.days.length > 0 ? (
                            week.days.map((day) => (
                              <li key={day?.id}>
                                <p
                                  className={day?.is_active === 1 || activeDayId === day?.id ? "active" : ""}
                                  data-toggle="modal"
                                  data-target="#statusCheckout1"
                                  style={{ cursor: "pointer" }}
                                >
                                  <span onClick={() => changeDayId(day?.id, day?.program_id, day?.programDay?.day)}>
                                    Day {day?.programDay?.day}
                                  </span>
                                </p>
                              </li>
                            ))
                          ) : (
                            <li>
                              <p>No days available</p>
                            </li>
                          )}
                        </ul>
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>

              </div>
            </div>
          </div>
        </section>
        <section className='welcome_002'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-12 col-lg-8 col-md-8'>
                <BreadcrumbMenu
                  breadcrumbs={breadcrumbs}
                  classNames={""}
                />
              </div>
              <div className='col-12 col-lg-4 col-md-4'>
                <div className='pro text-right'>
                  <Link state={{ week: weeks[activeWeekIndex] }} to={`/weekly-progress/${activeDayId}`} className='theme-btn-link d-inline-block mt-20'>
                    Progress Report
                  </Link>

                  {resume && (
                    <>
                      <span>-</span>
                      <Link to={`/assessment-timer`} className='theme-btn-link d-inline-block mt-20'>
                        Resume
                      </Link>
                    </>
                  )}
                </div>
                <div className='nav_week justify-content-end'>
                  <Link to='#' className='daily'>
                    <RiLayoutGridFill /> Grid View
                  </Link>
                  <div className='button-switch'>
                    <input
                      type='checkbox'
                      id='addsetting'
                      className='switch sw_btn'
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor='addsetting' className='lbl-off'></label>
                  </div>
                  <Link to='#' className='weekly'>
                    List View <RiListUnordered />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Exercises
          activeDayId={activeDayId ? activeDayId : localStorage.getItem('activeDayId')}
          programId={id ? id : null}
          view={view}
          exercises={exercises}
          setStart={setStart}
          start={start}
        />


      </CommonPageData>
      {/* <div className="container">
        {programEdVideos && programEdVideos.length > 0 ?
          <div className="row mt-4 mb-5">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-5">
              <div className="videos_headings">
                <h2 className="detail_span" style={{ color: "#fff" }}>EDUCATIONAL VIDEOS</h2>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
              <div className="row">
                {programEdVideos && programEdVideos.map((data, key) => {
                  if (data) {
                    return (
                      <div className="col-12 col-sm-12 col-md-6  col-lg-4 col-xl-3 " key={key}>
                        <div className="test_videobox-education pt-4">
                          <div className="check_test pb-2">
                            <CustomVideoPlayer videoUrl={data?.video ? data?.video : ''} className={'video'} />
                          </div>
                        </div>
                      </div>
                    )
                  }
                })
                }
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
              <div className="pagination">
                <span className="itemsPerPagelabel">Items Per Page:</span>
                <div className={'perpagedropdown'}>
                  <select id="itemsPerPage" value={itemsEdPerPage} onChange={handleItemsEdPerPageChange}>
                    <option value={4}>4</option>
                    <option value={8}>8</option>
                    <option value={12}>12</option>
                    <option value={16}>16</option>
                    <option value={20}>20</option>
                  </select>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                </div>
                <span className="totalpages">{currentEdPage}-{totalEdPages} of {totalEdPages}</span>
                <div className="paginationbuttons">
                  {currentEdPage > 1 ? <button
                    className={'button-previous'}
                    onClick={() => setCurrentEdPage(currentEdPage - 1)}
                    disabled={currentEdPage === 1}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                  </button> : ''}
                  {currentEdPage < totalEdPages ? <button
                    className={'button-next'}
                    onClick={() => setCurrentEdPage(currentEdPage + 1)}
                    disabled={currentEdPage === totalEdPages}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                  </button> : ''}
                </div>
              </div>
            </div>
          </div>
          : ''}
      </div> */}
    </>
  );
};

WeekSlider.propTypes = {
  weekSchedule: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  weekScheduleRequest: state.programReducer.weekScheduleRequest || null,
  weekScheduleSuccess: state.programReducer.weekScheduleSuccess || null,
  weekScheduleFailure: state.programReducer.weekScheduleFailure || null,
  userProgramDayStatusUpdateSuccess:
    state.programReducer.userProgramDayStatusUpdateSuccess || null,
  programId: state.programReducer.programId,
  weekSchedule: state.programReducer.weekSchedule,
  exercises: state.programReducer.exercises,
  educational_video: state.programReducer.educational_video,
});

export default connect(mapStateToProps, {
  // getEducationalVideos,
  getWeekSchedule,
  getProgram,
  getExerciseDetail,
  updateProgramDayStatus,
})(WeekSlider);

// export default WeekSlider
