import React, { useEffect } from 'react'
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import { CommonPageData } from '../../../styles/common.style';
const NewChangeLevel = () => {
  
  
  return (
    <div>
      <Header />
      <CommonPageData>
        <div className='common-banner-block'>
          <div className='main-banner'>
            <img src='/images/banner-common-block.png' alt='img' />
          </div>
          <div className='container'>
            <div className='common-banner-block-inner'>
              <h1>Change Movement</h1>
            </div>
          </div>
        </div>
        <section className='change-block'>
          <div className='container'>
            <div className='top-block'>
              <h2 className='title-inner-block'>
                <span>Choose</span> a movement to perform instead
              </h2>
              <button type='button' class='common-btn-all-front min-auto-btn  btn btn-primary'>
                Back
              </button>
            </div>
            <div className='middle-text-block'>
              <p>
                If you select an exercise, it will replace the original exercise in your program.<br></br> You can
                change the exercise levels at any time.
              </p>
            </div>
            <div className='change-level-block'>
              <div className='change-level-block-inner'>
                <div className='box-changes'>
                  <h3>Level 1</h3>
                  <div className='img-change-block'>
                    <img src='/images/change-level-img.png' alt='img' />
                  </div>
                  <p>
                    TFL Release Foam Roller<br></br> (Straight Arms)
                  </p>
                </div>
              </div>
              <div className='change-level-block-inner'>
                <div className='box-changes'>
                  <h3>Level 2</h3>
                  <div className='img-change-block'>
                    <img src='/images/change-level-img-2.png' alt='img' />
                  </div>
                  <p>
                    TFL Release Foam Roller<br></br> (Forearms)
                  </p>
                </div>
              </div>
              <div className='change-level-block-inner'>
                <div className='box-changes'>
                  <h3>Level 3</h3>
                  <div className='img-change-block'>
                    <img src='/images/change-level-img-3.png' alt='img' />
                  </div>
                  <p>
                    TFL Release Foam Roller<br></br> (Straight Arms)
                  </p>
                </div>
              </div>
            </div>
            <div className='equipment-block'>
              <h2 className='title-inner-block'>
                <span>EQUIPMENT</span> OPTIONS
              </h2>
              <div className='equipment-block-inner'>
                <div className='equipment-block-flex'>
                  <div className='img-equipment'>
                    <img src='/images/equipment-img-block.png' alt='img' />
                  </div>
                  <p>TFL Release TheStick Bench</p>
                </div>
                <div className='equipment-block-flex'>
                  <div className='img-equipment'>
                    <img src='/images/equipment-img-block-2.png' alt='img' />
                  </div>
                  <p>TFL Release TheStick Bench</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        
      </CommonPageData>
      <Footer />
    </div>
  );
}

export default NewChangeLevel