import React from 'react'

import img7 from '../../images/img7.png'
const Home12 = () => {
  return (
    <section className='home_0012' style={{ display: 'none' }}>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-lg-5 col-md-6'>
            <h2>Common Pain Areas</h2>
            <p>We offer 12-week corrective exercise programs</p>
            <p className='each'>Each 12-week program includes:</p>
            <ul className='list_pain'>
              <li>
                <svg width='25' height='22' viewBox='0 0 25 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <g clipPath='url(#clip0_305_85)'>
                    <path
                      d='M0.500065 0.82666C0.590191 0.865616 0.685699 0.845807 0.777844 0.845807H19.5202C19.5485 0.934283 19.4691 0.954753 19.4301 0.989748C18.6849 1.6586 17.9342 2.32218 17.1924 2.995C17.0848 3.09272 16.9731 3.09206 16.8487 3.09206C12.2527 3.09206 7.65664 3.09074 3.06061 3.0881C2.85883 3.0881 2.78687 3.12111 2.78687 3.34296C2.79494 8.42311 2.79494 13.5033 2.78687 18.5834C2.78687 18.7993 2.84875 18.8435 3.0559 18.8429C8.22273 18.8376 13.3898 18.8376 18.5571 18.8429C18.7716 18.8429 18.8261 18.7914 18.8261 18.5788C18.8187 15.4273 18.8221 12.2765 18.818 9.12498C18.8145 9.05795 18.8271 8.99105 18.8548 8.92968C18.8825 8.86832 18.9245 8.81421 18.9774 8.77173C19.6796 8.14777 20.3744 7.51721 21.0732 6.88467C21.1533 6.93947 21.1142 7.01672 21.1142 7.07351C21.1142 11.6695 21.116 16.2654 21.1196 20.8613C21.1196 21.0244 21.0934 21.0819 20.9078 21.0819C14.1509 21.0766 7.39433 21.075 0.637947 21.0772C0.591538 21.0772 0.544456 21.0706 0.498047 21.0667L0.500065 0.82666Z'
                      fill='#FB1417'
                    />
                    <path
                      d='M6.59221 7.30946C6.6487 7.3603 6.70116 7.40454 6.75026 7.45274C7.94142 8.61878 9.13526 9.78285 10.3197 10.9562C10.4542 11.0922 10.5215 11.0882 10.6614 10.9621C14.6826 7.36757 18.7053 3.77458 22.7296 0.183135C22.8836 0.0457978 22.9556 0.054381 23.0935 0.193038C23.5199 0.622216 23.9584 1.03951 24.4024 1.44756C24.5288 1.56443 24.5369 1.61923 24.4024 1.7394C22.7778 3.19508 21.1576 4.65473 19.5416 6.11833C16.6185 8.75546 13.6977 11.3928 10.7791 14.0304C10.619 14.175 10.5423 14.1565 10.3997 14.0152C8.68418 12.3205 6.96347 10.6306 5.23761 8.94562C5.11116 8.82215 5.09906 8.75678 5.23761 8.63001C5.65663 8.23385 6.06019 7.8291 6.46979 7.42765C6.50477 7.39002 6.54781 7.35502 6.59221 7.30946Z'
                      fill='#FB1417'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_305_85'>
                      <rect width='24' height='21' fill='white' transform='translate(0.5 0.0859375)' />
                    </clipPath>
                  </defs>
                </svg>
                <p>Corrective exercises and stretches for your body type.</p>
              </li>
              <li>
                <svg width='25' height='22' viewBox='0 0 25 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <g clipPath='url(#clip0_305_85)'>
                    <path
                      d='M0.500065 0.82666C0.590191 0.865616 0.685699 0.845807 0.777844 0.845807H19.5202C19.5485 0.934283 19.4691 0.954753 19.4301 0.989748C18.6849 1.6586 17.9342 2.32218 17.1924 2.995C17.0848 3.09272 16.9731 3.09206 16.8487 3.09206C12.2527 3.09206 7.65664 3.09074 3.06061 3.0881C2.85883 3.0881 2.78687 3.12111 2.78687 3.34296C2.79494 8.42311 2.79494 13.5033 2.78687 18.5834C2.78687 18.7993 2.84875 18.8435 3.0559 18.8429C8.22273 18.8376 13.3898 18.8376 18.5571 18.8429C18.7716 18.8429 18.8261 18.7914 18.8261 18.5788C18.8187 15.4273 18.8221 12.2765 18.818 9.12498C18.8145 9.05795 18.8271 8.99105 18.8548 8.92968C18.8825 8.86832 18.9245 8.81421 18.9774 8.77173C19.6796 8.14777 20.3744 7.51721 21.0732 6.88467C21.1533 6.93947 21.1142 7.01672 21.1142 7.07351C21.1142 11.6695 21.116 16.2654 21.1196 20.8613C21.1196 21.0244 21.0934 21.0819 20.9078 21.0819C14.1509 21.0766 7.39433 21.075 0.637947 21.0772C0.591538 21.0772 0.544456 21.0706 0.498047 21.0667L0.500065 0.82666Z'
                      fill='#FB1417'
                    />
                    <path
                      d='M6.59221 7.30946C6.6487 7.3603 6.70116 7.40454 6.75026 7.45274C7.94142 8.61878 9.13526 9.78285 10.3197 10.9562C10.4542 11.0922 10.5215 11.0882 10.6614 10.9621C14.6826 7.36757 18.7053 3.77458 22.7296 0.183135C22.8836 0.0457978 22.9556 0.054381 23.0935 0.193038C23.5199 0.622216 23.9584 1.03951 24.4024 1.44756C24.5288 1.56443 24.5369 1.61923 24.4024 1.7394C22.7778 3.19508 21.1576 4.65473 19.5416 6.11833C16.6185 8.75546 13.6977 11.3928 10.7791 14.0304C10.619 14.175 10.5423 14.1565 10.3997 14.0152C8.68418 12.3205 6.96347 10.6306 5.23761 8.94562C5.11116 8.82215 5.09906 8.75678 5.23761 8.63001C5.65663 8.23385 6.06019 7.8291 6.46979 7.42765C6.50477 7.39002 6.54781 7.35502 6.59221 7.30946Z'
                      fill='#FB1417'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_305_85'>
                      <rect width='24' height='21' fill='white' transform='translate(0.5 0.0859375)' />
                    </clipPath>
                  </defs>
                </svg>
                <p>Common exercise mistakes.</p>
              </li>
              <li>
                <svg width='25' height='22' viewBox='0 0 25 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <g clipPath='url(#clip0_305_85)'>
                    <path
                      d='M0.500065 0.82666C0.590191 0.865616 0.685699 0.845807 0.777844 0.845807H19.5202C19.5485 0.934283 19.4691 0.954753 19.4301 0.989748C18.6849 1.6586 17.9342 2.32218 17.1924 2.995C17.0848 3.09272 16.9731 3.09206 16.8487 3.09206C12.2527 3.09206 7.65664 3.09074 3.06061 3.0881C2.85883 3.0881 2.78687 3.12111 2.78687 3.34296C2.79494 8.42311 2.79494 13.5033 2.78687 18.5834C2.78687 18.7993 2.84875 18.8435 3.0559 18.8429C8.22273 18.8376 13.3898 18.8376 18.5571 18.8429C18.7716 18.8429 18.8261 18.7914 18.8261 18.5788C18.8187 15.4273 18.8221 12.2765 18.818 9.12498C18.8145 9.05795 18.8271 8.99105 18.8548 8.92968C18.8825 8.86832 18.9245 8.81421 18.9774 8.77173C19.6796 8.14777 20.3744 7.51721 21.0732 6.88467C21.1533 6.93947 21.1142 7.01672 21.1142 7.07351C21.1142 11.6695 21.116 16.2654 21.1196 20.8613C21.1196 21.0244 21.0934 21.0819 20.9078 21.0819C14.1509 21.0766 7.39433 21.075 0.637947 21.0772C0.591538 21.0772 0.544456 21.0706 0.498047 21.0667L0.500065 0.82666Z'
                      fill='#FB1417'
                    />
                    <path
                      d='M6.59221 7.30946C6.6487 7.3603 6.70116 7.40454 6.75026 7.45274C7.94142 8.61878 9.13526 9.78285 10.3197 10.9562C10.4542 11.0922 10.5215 11.0882 10.6614 10.9621C14.6826 7.36757 18.7053 3.77458 22.7296 0.183135C22.8836 0.0457978 22.9556 0.054381 23.0935 0.193038C23.5199 0.622216 23.9584 1.03951 24.4024 1.44756C24.5288 1.56443 24.5369 1.61923 24.4024 1.7394C22.7778 3.19508 21.1576 4.65473 19.5416 6.11833C16.6185 8.75546 13.6977 11.3928 10.7791 14.0304C10.619 14.175 10.5423 14.1565 10.3997 14.0152C8.68418 12.3205 6.96347 10.6306 5.23761 8.94562C5.11116 8.82215 5.09906 8.75678 5.23761 8.63001C5.65663 8.23385 6.06019 7.8291 6.46979 7.42765C6.50477 7.39002 6.54781 7.35502 6.59221 7.30946Z'
                      fill='#FB1417'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_305_85'>
                      <rect width='24' height='21' fill='white' transform='translate(0.5 0.0859375)' />
                    </clipPath>
                  </defs>
                </svg>
                <p> Exercises and stretches to avoid, for now. </p>
              </li>
              <li>
                <svg width='25' height='22' viewBox='0 0 25 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <g clipPath='url(#clip0_305_85)'>
                    <path
                      d='M0.500065 0.82666C0.590191 0.865616 0.685699 0.845807 0.777844 0.845807H19.5202C19.5485 0.934283 19.4691 0.954753 19.4301 0.989748C18.6849 1.6586 17.9342 2.32218 17.1924 2.995C17.0848 3.09272 16.9731 3.09206 16.8487 3.09206C12.2527 3.09206 7.65664 3.09074 3.06061 3.0881C2.85883 3.0881 2.78687 3.12111 2.78687 3.34296C2.79494 8.42311 2.79494 13.5033 2.78687 18.5834C2.78687 18.7993 2.84875 18.8435 3.0559 18.8429C8.22273 18.8376 13.3898 18.8376 18.5571 18.8429C18.7716 18.8429 18.8261 18.7914 18.8261 18.5788C18.8187 15.4273 18.8221 12.2765 18.818 9.12498C18.8145 9.05795 18.8271 8.99105 18.8548 8.92968C18.8825 8.86832 18.9245 8.81421 18.9774 8.77173C19.6796 8.14777 20.3744 7.51721 21.0732 6.88467C21.1533 6.93947 21.1142 7.01672 21.1142 7.07351C21.1142 11.6695 21.116 16.2654 21.1196 20.8613C21.1196 21.0244 21.0934 21.0819 20.9078 21.0819C14.1509 21.0766 7.39433 21.075 0.637947 21.0772C0.591538 21.0772 0.544456 21.0706 0.498047 21.0667L0.500065 0.82666Z'
                      fill='#FB1417'
                    />
                    <path
                      d='M6.59221 7.30946C6.6487 7.3603 6.70116 7.40454 6.75026 7.45274C7.94142 8.61878 9.13526 9.78285 10.3197 10.9562C10.4542 11.0922 10.5215 11.0882 10.6614 10.9621C14.6826 7.36757 18.7053 3.77458 22.7296 0.183135C22.8836 0.0457978 22.9556 0.054381 23.0935 0.193038C23.5199 0.622216 23.9584 1.03951 24.4024 1.44756C24.5288 1.56443 24.5369 1.61923 24.4024 1.7394C22.7778 3.19508 21.1576 4.65473 19.5416 6.11833C16.6185 8.75546 13.6977 11.3928 10.7791 14.0304C10.619 14.175 10.5423 14.1565 10.3997 14.0152C8.68418 12.3205 6.96347 10.6306 5.23761 8.94562C5.11116 8.82215 5.09906 8.75678 5.23761 8.63001C5.65663 8.23385 6.06019 7.8291 6.46979 7.42765C6.50477 7.39002 6.54781 7.35502 6.59221 7.30946Z'
                      fill='#FB1417'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_305_85'>
                      <rect width='24' height='21' fill='white' transform='translate(0.5 0.0859375)' />
                    </clipPath>
                  </defs>
                </svg>
                <p>How your muscle imbalances developed and how to prevent them from happening in the future.</p>
              </li>
              <li>
                <svg width='25' height='22' viewBox='0 0 25 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <g clipPath='url(#clip0_305_85)'>
                    <path
                      d='M0.500065 0.82666C0.590191 0.865616 0.685699 0.845807 0.777844 0.845807H19.5202C19.5485 0.934283 19.4691 0.954753 19.4301 0.989748C18.6849 1.6586 17.9342 2.32218 17.1924 2.995C17.0848 3.09272 16.9731 3.09206 16.8487 3.09206C12.2527 3.09206 7.65664 3.09074 3.06061 3.0881C2.85883 3.0881 2.78687 3.12111 2.78687 3.34296C2.79494 8.42311 2.79494 13.5033 2.78687 18.5834C2.78687 18.7993 2.84875 18.8435 3.0559 18.8429C8.22273 18.8376 13.3898 18.8376 18.5571 18.8429C18.7716 18.8429 18.8261 18.7914 18.8261 18.5788C18.8187 15.4273 18.8221 12.2765 18.818 9.12498C18.8145 9.05795 18.8271 8.99105 18.8548 8.92968C18.8825 8.86832 18.9245 8.81421 18.9774 8.77173C19.6796 8.14777 20.3744 7.51721 21.0732 6.88467C21.1533 6.93947 21.1142 7.01672 21.1142 7.07351C21.1142 11.6695 21.116 16.2654 21.1196 20.8613C21.1196 21.0244 21.0934 21.0819 20.9078 21.0819C14.1509 21.0766 7.39433 21.075 0.637947 21.0772C0.591538 21.0772 0.544456 21.0706 0.498047 21.0667L0.500065 0.82666Z'
                      fill='#FB1417'
                    />
                    <path
                      d='M6.59221 7.30946C6.6487 7.3603 6.70116 7.40454 6.75026 7.45274C7.94142 8.61878 9.13526 9.78285 10.3197 10.9562C10.4542 11.0922 10.5215 11.0882 10.6614 10.9621C14.6826 7.36757 18.7053 3.77458 22.7296 0.183135C22.8836 0.0457978 22.9556 0.054381 23.0935 0.193038C23.5199 0.622216 23.9584 1.03951 24.4024 1.44756C24.5288 1.56443 24.5369 1.61923 24.4024 1.7394C22.7778 3.19508 21.1576 4.65473 19.5416 6.11833C16.6185 8.75546 13.6977 11.3928 10.7791 14.0304C10.619 14.175 10.5423 14.1565 10.3997 14.0152C8.68418 12.3205 6.96347 10.6306 5.23761 8.94562C5.11116 8.82215 5.09906 8.75678 5.23761 8.63001C5.65663 8.23385 6.06019 7.8291 6.46979 7.42765C6.50477 7.39002 6.54781 7.35502 6.59221 7.30946Z'
                      fill='#FB1417'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_305_85'>
                      <rect width='24' height='21' fill='white' transform='translate(0.5 0.0859375)' />
                    </clipPath>
                  </defs>
                </svg>
                <p>Trigger-point library</p>
              </li>
            </ul>
          </div>
          <div className='col-12 col-lg-5 col-md-6 m-auto'>
            <img src={img7} alt='img7' className='img-fluid' />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home12