import React from 'react'
import img2 from '../../images/img2.png'
import video4 from "../../images/Golfer-1350.mp4";
const Home5 = () => {
  return (
    <section className='home_005' style={{ display: 'none' }}>
      <div className='container h-100'>
        <div className='row align-items-center h-100'>
          <div className='col-12 col-lg-6 col-md-6 p-0'>
            <video
              className='img-fluid'
              autoPlay={true}
              loop={true}
              muted={true}
              playsInline={true}
              controls={false}
              src={video4}
              type='video/mp4'
            />
            {/*<img src={img2} alt="img2" className="img-fluid show_mob" />*/}
          </div>
          <div className='col-12 col-lg-6 col-md-6 my-auto cover-img'>
            <div className=''>
              <h2>
                Muscle imbalances are caused by repetitive <span>movements</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home5