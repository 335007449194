import React from 'react'
import img5 from '../../images/img5.png'

const Home7 = () => {
  return (
    <section className='home_007' style={{ display: 'none' }}>
      <div className='container h-100'>
        <div className='row align-items-center h-100'>
          <div className='col-12 col-lg-6 col-md-6  '>
            <img src={img5} alt='img5' className='img-fluid show_mob' />
          </div>
          <div className='col-12 col-lg-4 col-md-5 m-auto'>
            <div className=''>
              <h2>
                Every day we are “teaching” our muscles how to <span>behave</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home7