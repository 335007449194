import React from 'react'


import img4 from '../../images/img4.png'
import video4 from '../../images/cyclingvid1.mp4'
const Home6 = () => {
  return (
    <section className='home_006' style={{ display: 'none' }}>
      <div className='container h-100'>
        <div className='row align-items-center h-100'>
          <div className='col-12 col-lg-6 col-md-6 my-auto or2 '>
            <div className=''>
              <h2>
                The muscles you use most often get stronger and <span>dominant</span>
              </h2>
            </div>
          </div>
          <div className='col-12 col-lg-6 col-md-6 or1 p-0'>
            <div className='video-container'>
              <video
                className='img-fluid'
                autoPlay={true}
                loop={true}
                muted={true}
                playsInline={true}
                controls={false}
                src={video4}
                type='video/mp4'
              />
            </div>
            {/*<img src={img4} alt="img4" className="img-fluid show_mob" />*/}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home6