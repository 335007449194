import {combineReducers} from 'redux';
import alert from './alert';
import auth from './auth';
import programReducer from "./user_program";
import testReducer from "./user_test";
import instructionsReducer from "./instructions";
import assessmentReducer from "./user_assessment"

export default combineReducers({
    alert,
    auth,
    programReducer,
    testReducer,
    instructionsReducer,
    assessmentReducer
});