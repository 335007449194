/** @format */
import React, { Navigate, useState, useEffect, useRef } from 'react';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import { CommonPageData } from '../../../styles/common.style';
import { useNavigate } from 'react-router-dom';
import { getAssessmentSummary } from '../../../actions/user_assessment';
import { connect } from "react-redux";

const AssessmentSummary = ({ allAssessment, getAssessmentSummary, assessmentSummarySuccess }) => {
  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

  useEffect(() => {
    if (!allAssessment || allAssessment.length === 0) {
      getAssessmentSummary(user.id);
    }
    console.log("allAssessment", allAssessment);
    
  }, [getAssessmentSummary]);

  return (
    <>
      <Header />
      <CommonPageData>
        <div className='common-banner-block'>
          <div className='main-banner'>
            <img src='/images/banner-common-block.png' alt='img' />
          </div>
          <div className='container'>
            <div className='common-banner-block-inner'>
              <h1>Assessment Summary Page</h1>
            </div>
          </div>
        </div>
        <section className='assessment-block'>
          <div className='container'>
            <div className='col-12 col-lg-12 col-md-12'>
              <div className='assessment-block-summary'>
                {allAssessment && allAssessment.length > 0 ? (
                  allAssessment.map((assessment, index) => {
                    const fileUrl = assessment.url || ''; // Get the file URL
                    const fileExtension = fileUrl.split('.').pop().toLowerCase(); // Extract file extension

                    const isVideo = ["mp4", "mov", "avi", "mkv", "webm"].includes(fileExtension); // Check if it's a video

                    return (
                      <div key={index} className='assessment-block-summary-inner'>
                        <div className='assessment-block-summary-inner-flex'>
                          <div className='info-pages-flex'>
                            {isVideo ? (
                              <video height="100" controls>
                                <source src={fileUrl} type={`video/${fileExtension}`} />
                                Your browser does not support the video tag.
                              </video>
                            ) : (
                              <img src={fileUrl} className='img-fluid' alt='Assessment' />
                            )}
                          </div>
                          
                          <div className='profile-img'>
                            <img src={isVideo ? '/images/video-icon.png' : '/images/img-profile-img.png'} alt='login-banner' />
                          </div>
                          <div className='title-box'>
                            <h6>{assessment.assessment.title || 'Unknown Title'}</h6>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>No assessments available.</p>
                )}

                {/* <div className='assessment-block-summary-inner'>
                  <div className='assessment-block-summary-inner-flex'>
                    <div className='info-pages-flex'>
                      <img
                        src='https://d1rs42xew6aaeb.cloudfront.net/Exercise-Covers/_1734046232082.png'
                        class='img-fluid'></img>
                    </div>
                    <div className='profile-img'>
                      <img src='/images/img-profile-img.png' alt='login-banner' />
                    </div>
                    <div className='title-box'>
                      <h6>Front View</h6>
                    </div>
                  </div>
                </div>
                <div className='assessment-block-summary-inner'>
                  <div className='assessment-block-summary-inner-flex'>
                    <div className='info-pages-flex'>
                      <img
                        src='https://d1rs42xew6aaeb.cloudfront.net/Exercise-Covers/_1734046232082.png'
                        class='img-fluid'></img>
                    </div>
                    <div className='profile-img'>
                      <img src='/images/video-icon.png' alt='login-banner' />
                    </div>
                    <div className='title-box'>
                      <h6>Front View</h6>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </CommonPageData>
      <Footer />
    </>
  );
};

const mapStateToProps = state => ({
  allAssessment: state.assessmentReducer.allAssessment,
  assessmentSummarySuccess: state.assessmentReducer.assessmentSummarySuccess || null
});

export default connect(mapStateToProps, { getAssessmentSummary })(AssessmentSummary);
