import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { createAssessmentStep, getAssessment, getAssessmentPresignedUrl, getLastAssessment } from '../../../actions/user_assessment';
import { CommonPageData } from '../../../styles/common.style';
import { Form } from 'react-bootstrap';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import { useLocation } from "react-router-dom";
import { genders, s3Credential, weight_unit } from "../../../utils/constants";
import { sassNull } from 'sass';
import { FadeLoader } from "react-spinners";

const Assessments = ({ getAssessment, assessments, getAssessmentPresignedUrl, stepFileUrl, userAssessmentPreSuccess, createAssessmentStep, createAssessmentSuccess, getLastAssessment, lastAssessment, createAssessmentStepRequest }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const videoRef = useRef(null);
  const [preview, setPreview] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [note, setNote] = useState(null);

  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
  const hasFetchedLastStep = useRef(false);

  useEffect(() => {
    if (!assessments || assessments.length === 0) {
      getAssessment();
    }
  }, [getAssessment]);


  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }

  }, [currentIndex]);

  useEffect(() => {
    const fetchLastStep = async () => {
      if (!hasFetchedLastStep.current && user?.id && assessments.length > 0) {
        hasFetchedLastStep.current = true; // Prevent further executions

        getLastAssessment(user.id);

        if (lastAssessment) {
          // Find index of last completed step
          const lastIndex = assessments.findIndex(a => a.id === lastAssessment.assessment_id);

          if (lastIndex !== -1) {
            setCurrentIndex(lastIndex + 1);
          }
        }
      }
    };

    fetchLastStep();
  }, [user?.id, assessments?.length]);


  const handleNext = async () => {
    if (currentIndex < assessments.length - 1) {
      if (file && stepFileUrl && stepFileUrl.fields.key) {
        setIsUploading(true); // Start loader

        let url = await handleImageUpload(stepFileUrl, file);
        let id = assessments[currentIndex].id;
        let userId = user?.id;

        let payload = {
          userId: userId,
          assessmentId: id,
          url: url
        };

        if (selectedOptions.length > 0) {
          payload.assessmentFeedbackOptionIds = selectedOptions;
        }

        if (note) {
          payload.note = note;
        }

        await createAssessmentStep(payload);
        setPreview(null);

        setIsUploading(false); // Stop loader
        setSelectedOptions([]);
        setNote('')
      }

      setCurrentIndex(prevIndex => prevIndex + 1);
    }
  };

  const handleCheckboxChange = (optionId) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.includes(optionId)
        ? prevSelected.filter((id) => id !== optionId) // Remove if already checked
        : [...prevSelected, optionId] // Add if not checked
    );
  };

  const handleNoteChange = (e) => {
    const value = e.target.value.trim(); // Trim to remove extra spaces
    setNote(value.length > 0 ? value : null); // Set note or null
  };

  const handleBack = () => {
    if (currentIndex > 0) {
      setCurrentIndex(prevIndex => prevIndex - 1);
    }
  }

  const handleFileChange = (event) => {
    console.log("hello");

    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setFile(selectedFile);
      const fileURL = URL.createObjectURL(selectedFile);
      setPreview(fileURL);
      getAssessmentPresignedUrl(selectedFile.type, selectedFile.name, "assessments");
    }


  };

  const handleImageUpload = async (presignedPostUrl, fileNew) => {
    const formData = new FormData();
    Object.entries(presignedPostUrl.fields).forEach(([k, v]) => {
      formData.append(k, v);
    });
    formData.append('file', fileNew);
    formData.append('folder_name', 'assessments');
    const response = await fetch(presignedPostUrl.url, {
      method: "POST",
      body: formData
    });


    if (response.status == 200 || response.status == 204) {
      return s3Credential.s3EndPoint + stepFileUrl.fields.key
    }
    return false;
  }

  const isVideo = (url) => {
    return /\.(mp4|webm|ogg)$/i.test(url);
  };

  const isImage = (url) => {
    return /\.(jpeg|jpg|gif|png|webp)$/i.test(url);
  };

  return (
    <>
      <Header />
      <CommonPageData>
        {!assessments || assessments.length === 0 ? <p>Loading assessments...</p> :
          <>

            <div className='common-banner-block'>
              <div className='main-banner'>
                <img src='/images/banner-common-block.png' alt='img' />
              </div>
              <div className='container'>
                <div className='common-banner-block-inner'>
                  <h1>{assessments?.length > 0 ? assessments[currentIndex]?.title : "No Assessments"}</h1>
                  <p>({currentIndex + 1}/{assessments?.length})</p>
                </div>
              </div>
            </div>


            {assessments?.[currentIndex]?.assessmentFeedbackOption &&
              Array.isArray(assessments[currentIndex]?.assessmentFeedbackOption) &&
              assessments[currentIndex]?.assessmentFeedbackOption.length > 0 ? (

              <section className='test1_001 assessment-block'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-12 col-lg-6 col-md-6  '>
                      <div className=' ssa box_video'>
                        {
                          assessments?.length > 0 && isVideo(assessments[currentIndex]?.video) ? (
                            <>
                              <video ref={videoRef} className='video' controls controlsList='nodownload' preload='auto'>
                                <source src={assessments[currentIndex]?.video} type='video/mp4' />
                              </video>

                            </>
                          ) : assessments?.length > 0 && isImage(assessments[currentIndex]?.video) ? (
                            <>
                              <img src={assessments[currentIndex]?.video} alt='banner' />
                            </>

                          ) : (

                            <p>Unsupported file</p>

                          )
                        }
                      </div>
                      <div className='upload-input-type-file full-width text-center'>
                        <input onChange={handleFileChange} type='file' name='file-image' id='file-input' />
                        <label for='file-input'>
                          <img src='/images/plus-icon-img.png' alt='plus' />
                          <h4>Upload Video</h4>
                        </label>
                        {preview && (
                          <div className="preview mt-3">
                            {file?.type?.startsWith("image/") ? (
                              <img src={preview} alt="Uploaded preview" width="200" />
                            ) : (
                              <video width="200" controls>
                                <source src={preview} type={file?.type} />
                                Your browser does not support the video tag.
                              </video>
                            )}
                          </div>
                        )}
                      </div>


                      <h3>
                        {assessments?.[currentIndex]?.note || ""}
                      </h3>

                    </div>

                    <div className='col-12 col-lg-6 col-md-6 test_one'>
                      <div className='box_test'>
                        <h3 className='test-title-block'>Feedback</h3>

                        {assessments[currentIndex]?.assessmentFeedbackOption.map((option, index) => (
                          <Form.Group key={option?.id} className="form-group checkbox_custom">
                            <Form.Control
                              type="checkbox"
                              id={`checkbox-${option?.id}`}
                              name={`checkbox-${option?.id}`}
                              checked={selectedOptions.includes(option?.id)}
                              onChange={() => handleCheckboxChange(option?.id)}
                            />
                            <Form.Label htmlFor={`checkbox-${option?.id}`}>{option?.option}</Form.Label>
                          </Form.Group>
                        ))}

                        <Form.Group className='form-group text-area'>
                          <Form.Label>
                            <span> Notes</span>
                          </Form.Label>
                          <textarea
                            className='form-control form-2 text-left'
                            onChange={handleNoteChange}
                            rows='5'
                            maxLength='500'
                            name='note'></textarea>
                        </Form.Group>
                        <div className='btn-assessment'>
                          {
                            currentIndex > 0 ?
                              <button
                                className='theme-common-btn-big'
                                onClick={handleBack}
                              >
                                Back
                              </button> : ""
                          }
                          <button
                            className='theme-common-btn-big ml-3'
                            onClick={handleNext}
                            type='button'
                            disabled={currentIndex >= assessments?.length - 1}
                          >
                            {currentIndex < assessments?.length - 1 ? "Next" : "Finish"}
                          </button>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>
              </section>

            ) : <section className='assessment-block'>
              <div className='container'>
                <div className='col-12 col-lg-12 col-md-12'>
                  <div className='assessment-block-inner'>
                    {
                      assessments?.length > 0 && isVideo(assessments[currentIndex]?.video) ? (
                        <>
                          <div className='assessment-block-inner-video'>
                            <video ref={videoRef} className='video' controls controlsList='nodownload' preload='auto'>
                              <source src={assessments[currentIndex]?.video} type='video/mp4' />
                            </video>
                          </div>

                        </>
                      ) : assessments?.length > 0 && isImage(assessments[currentIndex]?.video) ? (
                        <>
                          <div className='upload-img-input'>
                            <img src={assessments[currentIndex]?.video} alt='banner' />
                          </div>
                        </>

                      ) : (

                        <p>Unsupported file</p>

                      )
                    }

                    {assessments?.length > 0 && assessments[currentIndex] ? (
                      assessments[currentIndex]?.file_type === "video" ? (
                        <>
                          <div className='upload-input-type-file full-width'>
                            <input onChange={handleFileChange} type='file' name='file-input2' id='file-input2' />
                            <label htmlFor='file-input2'>
                              <img src='/images/plus-icon-img.png' alt='plus' />
                              <h4>Upload Video</h4>
                            </label>

                            {preview && (
                              <div className="preview mt-3">
                                {file?.type?.startsWith("image/") ? (
                                  <img src={preview} alt="Uploaded preview" width="200" />
                                ) : (
                                  <video width="200" controls>
                                    <source src={preview} type={file?.type} />
                                    Your browser does not support the video tag.
                                  </video>
                                )}
                              </div>
                            )}
                          </div>
                        </>
                      ) : assessments[currentIndex]?.file_type === "image" ? (
                        <>
                          <div className='upload-input-type-file full-width'>
                            <input onChange={handleFileChange} type='file' name='file-image' id='file-input' />
                            <label htmlFor='file-input'>
                              <img src='/images/plus-icon-img.png' alt='plus' />
                              <h4>Upload Image</h4>
                            </label>

                            {preview && (
                              <div className="preview mt-3">
                                {file?.type?.startsWith("image/") ? (
                                  <img src={preview} alt="Uploaded preview" width="200" />
                                ) : (
                                  <video width="200" controls>
                                    <source src={preview} type={file?.type} />
                                    Your browser does not support the video tag.
                                  </video>
                                )}
                              </div>
                            )}
                          </div>
                        </>
                      ) : null
                    ) : null}

                    <h3>
                      {assessments?.[currentIndex]?.note || ""}
                    </h3>


                    <div className='btn-assessment'>
                      {
                        currentIndex > 0 ?
                          <button
                            className='theme-common-btn-big'
                            onClick={handleBack}
                          >
                            Back
                          </button> : ""
                      }
                      <button
                        className='theme-common-btn-big ml-3'
                        onClick={handleNext}
                        disabled={currentIndex >= assessments?.length - 1}
                      >
                        {currentIndex < assessments?.length - 1 ? "Next" : "Finish"}
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </section>

            }
          </>
        }



        {/* <section className={`assessment-block ${assessments[currentIndex]?.assessmentFeedbackOption?.length > 0 ? 'test1_001' : ''}`} >
          <div className='container'>
            {assessments?.length > 0 ? (
              <>
                {(createAssessmentStepRequest || isUploading) && (
                  <div className="loader-overlay">
                    <FadeLoader color={'#FB1417'} loading={true} />
                  </div>
                )}

                <div className='row'>

                  <div className={`col-12 ${assessments[currentIndex]?.assessmentFeedbackOption?.length > 0 ? 'col-lg-6 col-md-6' : 'col-lg-12 col-lg-12'}`} >
                    <div className={`${assessments[currentIndex]?.assessmentFeedbackOption?.length > 0 ? ' ssa box_video' : 'assessment-block-inner'}`}>
                      {
                        assessments?.length > 0 && isVideo(assessments[currentIndex]?.video) ? (
                          <>
                            <div className='assessment-block-inner-video'>
                              <video ref={videoRef} className='video' controls controlsList='nodownload' preload='auto'>
                                <source src={assessments[currentIndex]?.video} type='video/mp4' />
                              </video>
                            </div>

                          </>
                        ) : assessments?.length > 0 && isImage(assessments[currentIndex]?.video) ? (
                          <>
                            <div className='upload-img-input'>
                              <img src={assessments[currentIndex]?.video} alt='banner' />
                            </div>
                          </>

                        ) : (

                          <p>Unsupported file</p>

                        )}




                      {
                        assessments[currentIndex]?.assessmentFeedbackOption?.length > 0 ? (
                          ""
                        ) : <div className='btn-assessment'>
                          {
                            currentIndex > 0 ?
                              <button
                                className='theme-common-btn-big'
                                onClick={handleBack}
                              >
                                Back
                              </button> : ""
                          }

                          <button
                            className='theme-common-btn-big ml-3'
                            onClick={handleNext}
                            disabled={currentIndex >= assessments?.length - 1}
                          >
                            {currentIndex < assessments?.length - 1 ? "Next" : "Finish"}
                          </button>
                        </div>
                      }

                    </div>

                    {assessments?.length > 0 && assessments[currentIndex] ? (
                      assessments[currentIndex].file_type === "video" ? (
                        <>
                          <div className='upload-input-type-file full-width'>
                            <input onChange={handleFileChange} type='file' name='file-input2' id='file-input2' />
                            <label htmlFor='file-input2'>
                              <img src='/images/plus-icon-img.png' alt='plus' />
                              <h4>Upload Video</h4>
                            </label>

                            {preview && (
                              <div className="preview mt-3">
                                {file?.type?.startsWith("image/") ? (
                                  <img src={preview} alt="Uploaded preview" width="200" />
                                ) : (
                                  <video width="200" controls>
                                    <source src={preview} type={file?.type} />
                                    Your browser does not support the video tag.
                                  </video>
                                )}
                              </div>
                            )}
                          </div>
                        </>
                      ) : assessments[currentIndex].file_type === "image" ? (
                        <>
                          <div className='upload-input-type-file full-width'>
                            <input onChange={handleFileChange} type='file' name='file-image' id='file-input' />
                            <label htmlFor='file-input'>
                              <img src='/images/plus-icon-img.png' alt='plus' />
                              <h4>Upload Image</h4>
                            </label>

                            {preview && (
                              <div className="preview mt-3">
                                {file?.type?.startsWith("image/") ? (
                                  <img src={preview} alt="Uploaded preview" width="200" />
                                ) : (
                                  <video width="200" controls>
                                    <source src={preview} type={file?.type} />
                                    Your browser does not support the video tag.
                                  </video>
                                )}
                              </div>
                            )}
                          </div>
                        </>
                      ) : null
                    ) : null}
                  </div>

                  <h3>
                    {assessments[currentIndex]?.note}
                  </h3>

                  {assessments[currentIndex]?.assessmentFeedbackOption?.length > 0 ? (

                    <div className='col-12 col-lg-6 col-md-6 test_one'>
                      <div className='box_test'>
                        <h3 className='test-title-block'>Feedback</h3>

                        {assessments[currentIndex].assessmentFeedbackOption.map((option, index) => (
                          <Form.Group key={option.id} className="form-group checkbox_custom">
                            <Form.Control
                              type="checkbox"
                              id={`checkbox-${option.id}`}
                              name={`checkbox-${option.id}`}
                              checked={selectedOptions.includes(option.id)}
                              onChange={() => handleCheckboxChange(option.id)}
                            />
                            <Form.Label htmlFor={`checkbox-${option.id}`}>{option.option}</Form.Label>
                          </Form.Group>
                        ))}

                        <Form.Group className='form-group text-area'>
                          <Form.Label>
                            <span> Notes</span>
                          </Form.Label>
                          <textarea
                            className='form-control form-2 text-left'
                            rows='5'
                            maxLength='500'
                            name='note'></textarea>
                        </Form.Group>
                        <div className='btn-assessment'>
                          {
                            currentIndex > 0 ?
                              <button
                                className='theme-common-btn-big'
                                onClick={handleBack}
                              >
                                Back
                              </button> : ""
                          }
                          <button
                            className='theme-common-btn-big ml-3'
                            onClick={handleNext}
                            type='button'
                            disabled={currentIndex >= assessments.length - 1}
                          >
                            {currentIndex < assessments.length - 1 ? "Next" : "Finish"}
                          </button>
                        </div>

                      </div>
                    </div>
                  ) : ""
                  }

                </div >
              </>
            ) : (
              <p>No assessments available.</p>
            )}
          </div >
        </section > */}

      </CommonPageData >
      <Footer />
    </>
  );
};

const mapStateToProps = state => ({
  assessments: state.assessmentReducer.assessments,
  stepFileUrl: state.assessmentReducer.stepFileUrl,
  lastAssessment: state.assessmentReducer.lastAssessment || null,
  userAssessmentPreSuccess: state.assessmentReducer.userAssessmentPreSuccess || null,
  createAssessmentSuccess: state.assessmentReducer.createAssessmentSuccess || null,
  createAssessmentStepRequest: state.assessmentReducer.createAssessmentStepRequest || null
});

export default connect(mapStateToProps, { getAssessment, getAssessmentPresignedUrl, createAssessmentStep, getLastAssessment })(Assessments);