import React, {useEffect} from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({
                            redirectPath = '/login',
                            children,
                        }) => {
                            
    let userData = useSelector((state) => state.auth);
    userData = userData?.user?.user
    let user = JSON.parse(localStorage.getItem('user')) || userData;
    if (!user) {
        return <Navigate to={redirectPath} replace/>;
    }

    return children ? children : <Outlet/>;
};

export default ProtectedRoute