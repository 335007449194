import React, { useEffect, useState } from "react";
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Home/Header";
import Header2 from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import { Container, Row, Col, Form } from "react-bootstrap";
import imgNotFound from "../../images/broken-hd.png";
import imgRedirect from "../../images/redirect.png";
import { getCategories } from "../../../actions/user_program";
import { connect } from "react-redux";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaTiktok, FaComment, FaFacebookF } from "react-icons/fa";
import profile from "../../images/Group 29358.png";
import { cross_origin, SOCIAL_MEDIA_LINKS } from "../../../utils/constants";
import FadeLoader from "react-spinners/FadeLoader";
import { Interweave } from "interweave";
import Modal from "react-bootstrap/Modal";
import { CommonPageData } from '../../../styles/common.style';
const ProductsWithCategory = ({ getCategories, categories }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(false);
  const [keyword, setKeyword] = useState(null);
  const [loader, setLoader] = useState(true);

  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const maxLength = 40;

  const [productDetails, setProductDetails] = useState([]);

  const [isSearch, setIsSearch] = useState(false);
  const [productsData, setProductsData] = useState([]);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (categories) {
      setLoader(false);
    }
  }, [categories]);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setUser(true);
    } else {
      setUser(false);
    }
  }, []);

  const handleSearchClick = (value) => {
    //getCategories(keyword);
    setLoader(true);
    if (value.length > 0) {
      setIsSearch(true);
      if (categories && categories.length > 0) {
        const filteredData = categories
          .map((group) => group.equipments || [])
          .reduce((acc, muscles) => acc.concat(muscles), [])
          .filter((muscle) =>
            muscle.title.toLowerCase().includes(value.toLowerCase())
          );

        setLoader(false);
        setProductsData(filteredData);
      } else {
        setLoader(false);
        setProductsData([]);
      }
    } else {
      setLoader(false);
      setIsSearch(false);
    }
    // setCurrentPage(1)
  };

  const handleSearch = (value) => {
    // setVal(true);
    setKeyword(value);
    if (keyword && value.length == 0) {
      getCategories();
    }
  };

  const handleReadMoreClick = (data) => {
    setProductDetails(data);
    setModalShow(true);
  };

  const handleProducts = (data) => {
    navigate("/recommended-products", { state: { products: data.id } });
    // navigate(`/recommended-products/${data.id}`, { replace: true });

    // history.push({
    //   pathname: "/summary-excercise/" + id,
    //   state: {
    //     products: data,
    //   },
    // });
  };

  const ReadMoreP = ({ text, maxLength, className }) => {
    const [expanded, setExpanded] = useState(false);
    const truncatedText = text?.slice(0, maxLength);
    return (
      <div>
        <div
          className={`titles-label ${className}`}
          style={{ wordWrap: "break-word" }}
        >
          <Interweave content={expanded ? text : truncatedText} />
          <button
            className={"btn-expand float-right"}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? "Read less" : "Read more"}
          </button>
        </div>
      </div>
    );
  };

  const ProductCard = (data) => {
    const productData = data?.data;
    return (
      <div
        className={'product-box common-box-bg-all'}
        onClick={() => handleProducts(productData)}
        style={{ cursor: 'pointer' }}>
        <div className={'product-box-img'}>
          <img
            className={'img-fluid product-img'}
            crossOrigin={cross_origin ? 'anonymous' : null}
            src={productData?.image ? productData?.image : imgNotFound}
            alt={productData?.title}
            title={data?.title}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = imgNotFound;
            }}
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>
        <div className={''}>
          <div className={'product-info d-flex flex-column text-center'}>
            <h3>
              {productData?.name}
            </h3>
            {/* <p>
              <span>{productData?.description}</span>
            </p> */}
          </div>

          {/* <div className="clearfix mt-3"></div> */}
        </div>
      </div>
    );
  };

  const ProductCardDetails = (data) => {
    const productData = data?.data;
    const referral_link2 = productData?.referral_link2
      ? JSON.parse(productData?.referral_link2)
      : "";
    return (
      <div className={'product-box full-height-block common-box-bg-all'}>
        <div className={'product-box-img'}>
          <img
            className={'img-fluid product-img'}
            crossOrigin={cross_origin ? 'anonymous' : null}
            src={productData?.image ? productData?.image : imgNotFound}
            alt={productData?.title}
            title={data?.title}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = imgNotFound;
            }}
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>
        <div className={''}>
          <div className={'product-info d-flex flex-column'}>
            <h4 className={'text-uppercase'}>{productData?.title}</h4>
            {productData?.description != '' ? (
              <>
                <div className={'product-desc'}>{productData?.description}</div>
                <div className={'float-right'}>
                  <button className={'btn-expand-read float-right'} onClick={() => handleReadMoreClick(productData)}>
                    Read more
                  </button>
                </div>
              </>
            ) : (
              <div className={'comment-not-found'}>No description found.</div>
            )}
            {/* <p>
              <span>{productData?.description}</span>
            </p> */}
          </div>
          {/* <button
            className={"btn-expand-read float-right"}
            onClick={() => handleReadMoreClick(productData)}
          >
            Read more
          </button> */}
          <div className='clearfix mb-2'>
            {productData?.note != null &&
            productData?.note != undefined &&
            productData?.note != '' &&
            productData?.note != ' ' ? (
              <div className='mt-4'>
                <div className={'product-comments'} onClick={() => handleReadMoreClick(productData)}>
                  {productData?.note && <FaComment />} <span>{productData?.note}</span>
                </div>
                <button className={'btn-expand-read float-right'} onClick={() => handleReadMoreClick(productData)}>
                  Read more
                </button>
              </div>
            ) : (
              <>
                <div className={'comment-not-found'}>No comment found.</div>
                {/* <button
                className={"btn-expand-read float-right"}
                onClick={() => handleReadMoreClick(productData)}
              >
                Read more
              </button> */}
              </>
            )}
          </div>
          {/* <p>
            <span>{productData?.note}</span>
          </p> */}

          <div className={'mx-auto text-center last-block-flex-buy'}>
            <span>Buy it On</span>
            <div className='logo-grid'>
              {referral_link2 &&
                referral_link2.map((link, key) => (
                  <>
                    {link?.id === 1 && link?.inputValue && (
                      <a href={link?.inputValue} target='_blank' className='logo-text' key={key} rel='noreferrer'>
                        <img className='logo-imgage' src={link?.logo} />
                      </a>
                    )}
                    {link?.id === 2 && link?.inputValue && (
                      <a href={link?.inputValue} target='_blank' className='logo-text' key={key} rel='noreferrer'>
                        <img className='logo-imgage' src={link?.logo} />
                      </a>
                    )}

                    {link?.id === 3 && link?.inputValue && (
                      <a href={link?.inputValue} target='_blank' className='logo-text' key={key} rel='noreferrer'>
                        <img className='logo-imgage' src={link?.logo} />
                      </a>
                    )}

                    {link?.id === 4 && link?.inputValue && (
                      <a href={link?.inputValue} target='_blank' className='logo-text' key={key} rel='noreferrer'>
                        <img className='logo-imgage' src={link?.logo} />
                      </a>
                    )}
                    {link?.id === 5 && link?.inputValue && (
                      <a href={link?.inputValue} target='_blank' className='logo-text' key={key} rel='noreferrer'>
                        <img className='logo-imgage' src={link?.logo} />
                      </a>
                    )}
                    {link?.id === 6 && link?.inputValue && (
                      <a href={link?.inputValue} target='_blank' className='logo-text' key={key} rel='noreferrer'>
                        <img className='logo-imgage' src={link?.logo} />
                      </a>
                    )}
                    {link?.id === 7 && link?.inputValue && (
                      <a href={link?.inputValue} target='_blank' className='logo-text' key={key} rel='noreferrer'>
                        <img className='logo-imgage' src={link?.logo} />
                      </a>
                    )}
                    {link?.id === 8 && link?.inputValue && (
                      <a href={link?.inputValue} target='_blank' className='logo-text' key={key} rel='noreferrer'>
                        <img className='logo-imgage' src={link?.logo} />
                      </a>
                    )}
                    {link?.id === 9 && link?.inputValue && (
                      <a href={link?.inputValue} target='_blank' className='logo-text' key={key} rel='noreferrer'>
                        <img className='logo-imgage' src={link?.logo} />
                      </a>
                    )}
                    {link?.id === 10 && link?.inputValue && (
                      <a href={link?.inputValue} target='_blank' className='logo-text' key={key} rel='noreferrer'>
                        <img className='logo-imgage' src={link?.logo} />
                      </a>
                    )}
                  </>
                ))}
            </div>
            {/* <a
              href={productData?.referral_link}
              target="_blank"
              className={"btn-full"}
            >
              {productData?.referral_link_image ? (
                <img
                  className={"img-fluid"}
                  crossOrigin={cross_origin ? "anonymous" : null}
                  src={productData?.referral_link_image}
                  alt={"Recommended Vendor"}
                  title={"Recommended Vendor"}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = imgRedirect;
                  }}
                />
              ) : (
                "Click Here"
              )}
            </a> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <ScrollTopOnMount />
      {/* {user ? <Header2 /> : <Header />} */}
      <Header2 />
      <div className={'clearfix'}></div>
      <CommonPageData>
        <section className={'recomended-products'}>
          {/* <Row>
              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 '>
                <div className='videos_headings'>
                  <h2 className='detail_span' style={{ color: '#fff' }}>
                    Product Recommendations 1
                  </h2>
                  <p style={{ color: '#fff' }}>Our favorite products used in the Body Solutions programs</p>
                </div>
              </div>
            </Row> */}

          <div className='common-banner-block'>
            <div className='main-banner'>
              <img src='/images/banner-common-block.png' alt='img' />
            </div>
            <div className='container'>
              <div className='common-banner-block-inner'>
                <h1>Product Recommendations</h1>
                <p>Our favorite products used in the Body Solutions programs</p>
              </div>
            </div>
          </div>
          <Container>
            <div className='search-block-custom'>
              <Form className='' onSubmit={(e) => e.preventDefault()}>
                <Form.Control
                  value={keyword}
                  type='search'
                  placeholder='Search'
                  className='rounded-pill'
                  aria-label='Search'
                  onChange={(e) => {
                    handleSearch(e.target.value);
                    handleSearchClick(e.target.value);
                  }}
                />
                <button>
                  <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M15.2713 14.9121L12.357 12.0064C13.2973 10.8085 13.8075 9.32921 13.8056 7.80636C13.8056 6.45015 13.4034 5.12439 12.65 3.99674C11.8965 2.86909 10.8255 1.99019 9.57257 1.47119C8.31959 0.95219 6.94085 0.816396 5.61069 1.08098C4.28054 1.34556 3.05871 1.99864 2.09972 2.95763C1.14073 3.91662 0.487654 5.13845 0.22307 6.4686C-0.0415146 7.79876 0.0942797 9.1775 0.61328 10.4305C1.13228 11.6835 2.01118 12.7544 3.13883 13.5079C4.26648 14.2613 5.59224 14.6635 6.94845 14.6635C8.4713 14.6654 9.95056 14.1552 11.1485 13.2149L14.0542 16.1292C14.1339 16.2096 14.2287 16.2733 14.3331 16.3168C14.4376 16.3604 14.5496 16.3828 14.6627 16.3828C14.7759 16.3828 14.8879 16.3604 14.9924 16.3168C15.0968 16.2733 15.1916 16.2096 15.2713 16.1292C15.3516 16.0495 15.4154 15.9547 15.4589 15.8503C15.5024 15.7458 15.5249 15.6338 15.5249 15.5206C15.5249 15.4075 15.5024 15.2955 15.4589 15.191C15.4154 15.0866 15.3516 14.9918 15.2713 14.9121ZM1.8056 7.80636C1.8056 6.7892 2.10722 5.79488 2.67232 4.94915C3.23743 4.10341 4.04063 3.44423 4.98037 3.05498C5.9201 2.66573 6.95416 2.56389 7.95178 2.76233C8.94939 2.96076 9.86576 3.45057 10.585 4.16982C11.3042 4.88906 11.7941 5.80543 11.9925 6.80304C12.1909 7.80066 12.0891 8.83472 11.6998 9.77445C11.3106 10.7142 10.6514 11.5174 9.80567 12.0825C8.95993 12.6476 7.96561 12.9492 6.94845 12.9492C5.58448 12.9492 4.27638 12.4074 3.31191 11.4429C2.34743 10.4784 1.8056 9.17033 1.8056 7.80636Z'
                      fill='black'
                    />
                  </svg>
                </button>
              </Form>
            </div>
            <div className='product-block-reco'>
              <Row>
                {loader ? (
                  <div className='col-12 col-sm-12 col-md-12  col-lg-12 col-xl-12 '>
                    <div className={'empty-page'}>
                      <div className='spin-loader'>
                        <FadeLoader color={'#EC1246'} height={10} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {!isSearch ? (
                      <>
                        {categories &&
                          categories.map((category, key) => {
                            if (category) {
                              return (
                                <Col xs={6} sm={6} md={3} lg={3} xl={3} key={key}>
                                  <ProductCard data={category} />
                                </Col>
                              );
                            }
                          })}
                      </>
                    ) : productsData.length > 0 ? (
                      productsData &&
                      productsData.map((products, key) => {
                        if (products) {
                          return (
                            <Col xs={12} sm={12} md={6} lg={3} xl={3} key={key} className='mb-col-buy'>
                              <ProductCardDetails data={products} />
                            </Col>
                          );
                        }
                      })
                    ) : (
                      <div className='text-center'>
                        <h3>No products found.</h3>
                      </div>
                    )}
                  </>
                )}
              </Row>
            </div>
            {/* <Row className={"mt-5"}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"mx-auto"}>
              <div className="social-links">
                <div className="kit">
                  <h3>Share this kit:</h3>
                  <ul className="links-icons">
                    <li>
                      <a href={SOCIAL_MEDIA_LINKS.TWITTER} target="_blank">
                        <AiOutlineTwitter />
                      </a>
                    </li>
                    <li>
                      <a href={SOCIAL_MEDIA_LINKS.FACEBOOK} target="_blank">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href={SOCIAL_MEDIA_LINKS.TIKTOK} target="_blank">
                        <FaTiktok />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="social-profile">
                  <div className="wrapper">
                    <img src={profile} alt="profile" className="img-fluid" />
                  </div>
                  <div className="content">
                    <h3>@bodysolutions</h3>
                    <a
                      href={SOCIAL_MEDIA_LINKS.WEB}
                      target="_blank"
                      className="btn-follow"
                    >
                      Follow
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row> */}
            <Row className='share-kit-block'>
              <Col md={12} lg={6}>
                <div className='kit common-box-bg-all'>
                  <h3 className='title-inner-block'>Share this kit:</h3>
                  <ul className='links-icons'>
                    <li>
                      <a href={SOCIAL_MEDIA_LINKS.TWITTER} target='_blank' rel='noreferrer'>
                        {/* <AiOutlineTwitter /> */}

                        <svg width='28' height='26' viewBox='0 0 28 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M21.7641 0.5H25.9043L16.8592 11.3015L27.5 26H19.1683L12.6427 17.0855L5.17581 26H1.03317L10.7077 14.4465L0.5 0.5H9.04315L14.9418 8.64823L21.7641 0.5ZM20.311 23.4108H22.6051L7.79659 2.95323H5.33476L20.311 23.4108Z'
                            fill='white'
                          />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href={SOCIAL_MEDIA_LINKS.FACEBOOK} target='_blank' rel='noreferrer'>
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href={SOCIAL_MEDIA_LINKS.TIKTOK} target='_blank' rel='noreferrer'>
                        <FaTiktok />
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={12} lg={6}>
                <div className='social-profile'>
                  <div className='content common-box-bg-all'>
                    <figure>
                      {/* <img src={profile} alt='profile' className='img-fluid' /> */}
                      <img src='/images/logo-body-solution.png' alt='img' />
                    </figure>
                    <div className='wrapper'>
                      <h3>@bodysolutions</h3>
                    </div>
                    <a
                      href={SOCIAL_MEDIA_LINKS.WEB}
                      target='_blank'
                      className='theme-common-btn-small'
                      rel='noreferrer'>
                      Follow
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </CommonPageData>
      <Footer />

      {modalShow && (
        <>
          <Modal
            show={modalShow}
            onHide={handleClose}
            id='IntroModal'
            className='modal fade custom-modal-style small-modal-common'
            aria-hidden='true'
            aria-labelledby='exampleModalLabel'
            size='xl'
            centered>
            <Modal.Header>
              <h4>{productDetails?.title}</h4>
              <button type='button' className='close' data-dismiss='modal' aria-label='Close' onClick={handleClose}>
                <span aria-hidden='true'>&times;</span>
              </button>
            </Modal.Header>
            <Modal.Body className='modal-body-custom'>
              <div className={'justify-text'}>
                {productDetails?.description ? (
                  <>
                    <h6 className='modal-custom-title'>Description:</h6>
                    <div className='modal-custom-title-desc'>{productDetails?.description}</div>
                  </>
                ) : (
                  <p>No description found.</p>
                )}
                {productDetails?.note ? (
                  <>
                    <div className='mt-3 '>
                      <h6 className='mb-2 modal-custom-title'>Comments:</h6>
                      <div className='modal-custom-title-desc'>{productDetails?.note}</div>
                    </div>
                  </>
                ) : (
                  <p>No comments found.</p>
                )}
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    categories: state.programReducer.categories,
  };
};
export default connect(mapStateToProps, { getCategories })(
  ProductsWithCategory
);
