import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from "react";
import logo from '../../images/logo.png'
import { connect } from 'react-redux';
import { getUserProfile, logout } from '../../../actions/auth';
import avatar from "../../images/avatar.png";
import { GetPages, getProgram } from "../../../actions/user_program";
import moment from "moment";
import { setAlert } from "../../../actions/alert";
import { HeaderMain } from '../../../styles/common.style';

const Header = ({ logout, profilePicture, getUserProfile, userProfile, logoutSuccess, logoutRequest, logoutFailure }) => {
    const [mobileMenu, setMobileMenu] = useState(false);
    const navigate = useNavigate();
    const [headerList, setHeaderList] = useState([]);

    useEffect(() => {
        getPagesListData();
    }, []);

    const getPagesListData = async () => {
        await GetPages()
            .then((result) => {
                if (result.status) {                    
                    if (result.data) {
                        const rowData = [];
                        result?.data?.map((dt, index) => {
                                rowData.push({
                                    id: dt.id,
                                    index: ++index,
                                    slug: dt.slug,
                                    title: dt.name,
                                    created_at: moment(dt.created_at).fromNow(),
                                });
                        });
                        setHeaderList(rowData);
                    }
                } else {
                    return setAlert(result.message, "danger");
                }
            })
            .catch((error) => {
                setAlert(error.response.data.message.replace(/_/g, " "), "danger");
            });
    };

    const onMenuClick = () => {
        setMobileMenu(!mobileMenu);
    };

    const onCloseClick = () => {
        setMobileMenu(false);
    };

    const logoutAccount = (e) => {
        e.preventDefault();
        logout();
    };

    useEffect(() => {
    }, [logoutSuccess, navigate]);

    const user = localStorage.getItem('user');
    const parsedUser = user ? JSON.parse(user) : null;

    return (
      <HeaderMain >
        <header className='header'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-12 col-lg-2 col-md-2 '>
                <div className='logo'>
                  <Link to={'/'}>
                    {' '}
                    <img src={logo} alt='logo' className='img-fluid' />
                  </Link>
                </div>
                <span
                  className='icon_mobile'
                  onClick={() => {
                    setMobileMenu(!mobileMenu);
                    onMenuClick();
                  }}>
                  <i className='fas fa-bars'></i>
                </span>
              </div>
              <div
                className={`col-12 col-lg-10 col-md-10 text-right hide_mobile ${mobileMenu ? 'show_mobile_menu' : ''}`}>
                <span className='show_mobile close1' onClick={() => onCloseClick()}>
                  <i className='far fa-times-circle'></i>
                </span>
                <div className='main_head'>
                  <ul className='menu_top right_left'>
                    <ul className='right_menu'>
                      <li className='dropdown_user hide_mobile'>
                        <div className='dropdown'>
                          <a
                            className='h_login'
                            href='javascript:void(0)'
                            id='dropdownMenuButton'
                            data-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'>
                            <span>About</span>

                            <svg
                              width='10'
                              height='6'
                              viewBox='0 0 10 6'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'>
                              <path
                                d='M0.412893 2.23408L4.01989 5.61786C4.56303 6.12738 5.44041 6.12738 5.98354 5.61786L9.59054 2.23408C10.4679 1.411 9.84122 0 8.60175 0H1.38776C0.148287 0 -0.464485 1.411 0.412893 2.23408Z'
                                fill='white'
                                fill-opacity='0.8'
                              />
                            </svg>
                          </a>
                          <div className='dropdown-menu menu_top1' aria-labelledby='dropdownMenuButton'>
                            {headerList.length > 0 &&
                              headerList.map((item, index) => {
                                return (
                                  <Link className='dropdown-item profile_h' to={`/pages/${item.slug}`}>
                                    {item.title}
                                  </Link>
                                );
                              })}
                          </div>
                        </div>
                      </li>
                    </ul>
                    {/* <li><Link to="/page/about-us" className="h_login"> About</Link></li> */}
                    <li>
                      <Link to='/education' className='h_login'>
                        {' '}
                        Education
                      </Link>
                    </li>
                    {user ? (
                      <li>
                        <Link to='/welcome' className='h_login'>
                          {' '}
                          my programs
                        </Link>
                      </li>
                    ) : (
                      <li>
                        <Link to='/page/programs-offered' className='h_login'>
                          programs
                        </Link>
                      </li>
                    )}
                    <li>
                      <Link to='/recommended-categories' className='h_login'>
                        Recommended Products
                      </Link>
                    </li>
                    {/* <li><Link to="#" className="h_login"> Pain Trigger points</Link></li> */}
                    {/* <li><Link to="/testimonials" className="h_login"> Testimonials</Link></li> */}
                  </ul>
                  {user ? (
                    <ul className='menu_top right_menu'>
                      <li className='dropdown_user hide_mobile'>
                        <div className='dropdown'>
                          <Link
                            className='h_login'
                            to='#'
                            id='dropdownMenuButton'
                            data-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'>
                            <span className='img_user'>
                              <img
                                src={user && user?.userDetail?.image ? user.userDetail?.image : avatar}
                                alt='user1'
                                className='img-fluid'
                              />
                            </span>
                            <span>{user ? user.name : '-'}</span>
                            <svg
                              width='14'
                              height='9'
                              viewBox='0 0 14 9'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'>
                              <path
                                d='M7 7L6.29289 7.70711L7 8.41421L7.70711 7.70711L7 7ZM0.292893 1.70711L6.29289 7.70711L7.70711 6.29289L1.70711 0.292893L0.292893 1.70711ZM7.70711 7.70711L13.7071 1.70711L12.2929 0.292894L6.29289 6.29289L7.70711 7.70711Z'
                                fill='#CCD2E3'
                              />
                            </svg>
                          </Link>
                          <div className='dropdown-menu menu_top1' aria-labelledby='dropdownMenuButton'>
                            <Link className='dropdown-item profile_h' to='/my-profile'>
                              My Profile
                            </Link>
                            <Link className='dropdown-item profile_h' to='#' onClick={logoutAccount}>
                              Logout
                            </Link>
                          </div>
                        </div>
                      </li>
                    </ul>
                  ) : (
                    <ul className='menu_top right_menu'>
                      <li>
                        <Link to='/login' className='h_login'>
                          {' '}
                          Sign In
                        </Link>
                      </li>
                      <li>
                        <Link to='/signup' className='btn-1'>
                          Sign Up
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </header>
      </HeaderMain>
    );
}

const mapStateToProps = state => ({
    profilePicture: state.auth.profilePicture,
    // logoutFailure: state.auth.logoutFailure || null,
    logoutSuccess: state.auth.logoutSuccess || null,
    // logoutRequest: state.auth.logoutRequest || null,
    userProfile: state.auth.userProfile,
})

export default connect(mapStateToProps, { logout, getUserProfile })(Header)