import React from "react";
import history from '../history'
import setAuthToken from "../utils/setAuthToken";
import api from '../utils/api';
import { setAlert } from './alert';
import {
    AUTH_ERROR,
    LOGIN_FAIL,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_UNVERIFIED,
    LOGOUT,
    LOGOUT_FAILURE,
    LOGOUT_REQUEST,
    OCCUPATION_FAILURE,
    OCCUPATION_REQUEST,
    OCCUPATION_SUCCESS,
    OTP_FAILURE, OTP_REQUEST,
    OTP_SUCCESS,
    PAGE_DATA_FAILURE,
    PAGE_DATA_REQUEST,
    PAGE_DATA_SUCCESS,
    PASSWORD_RESET_FAILURE, PASSWORD_RESET_REQUEST,
    PASSWORD_RESET_SUCCESS,
    REGISTER_FAIL,
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    RESEND_OTP_FAILURE,
    RESEND_OTP_REQUEST,
    RESEND_OTP_SUCCESS,
    SPORT_FAILURE,
    SPORT_REQUEST,
    SPORT_SUCCESS,
    USER_LOADED,
    USER_PROFILE_FAILURE,
    USER_PROFILE_REQUEST,
    USER_PROFILE_SUCCESS,
    USER_PROFILE_UPDATE_FAILURE,
    USER_PROFILE_UPDATE_REQUEST,
    USER_PROFILE_UPDATE_SUCCESS,
    USER_PROFILE_UPLOAD_FAILURE,
    USER_PROFILE_UPLOAD_REQUEST,
    USER_PROFILE_UPLOAD_SUCCESS,
    VERIFY_OTP_FAILURE,
    VERIFY_OTP_REQUEST,
    VERIFY_OTP_SUCCESS,
    LOGIN_GOOGLE_REQUEST,
    LOGIN_GOOGLE_FAIL,
    LOGIN_GOOGLE_SUCCESS,
    LOGIN_GOOGLE_UNVERIFIED,
    EXPIRE_OTP_REQUEST,
    EXPIRE_OTP_SUCCESS,
    EXPIRE_OTP_FAILURE
} from './types'


/*
  NOTE: we don't need a config object for axios as the
 default headers in axios are already Content-Type: application/json
 also axios stringifies and parses JSON for you, so no need for 
 JSON.stringify or JSON.parse
*/


//Load User
// export  const loadUser = () => async dispatch => {
//
//     try{
//         let token = localStorage.getItem('token')
//         const res = await api.post('/me',
//             {},{
//                 headers: {
//                     Authorization: 'Bearer ' + token
//                 }
//             }
//             )
//         dispatch({
//             type: USER_LOADED,
//             payload: res.data.data
//         })
//         }
//
//     catch (err) {
//          dispatch({
//              type: AUTH_ERROR
//          })
//     }
// }

//Reset password
export const reset = (payload) => async (dispatch) => {
    try {
        dispatch({
            type: PASSWORD_RESET_REQUEST,
            payload: null
        });

        const res = await api.post('/reset-password', payload);
        dispatch({
            type: PASSWORD_RESET_SUCCESS,
            payload: res.data.data
        });
        let msg = res.data.message
        dispatch(setAlert(msg, 'success'))

    } catch (err) {
        const msg = err.response.data.message;
        dispatch(setAlert(msg, 'danger'))

        dispatch({
            type: PASSWORD_RESET_FAILURE
        });
    }

}

//Send OTP from forgot password screen
export const sendOTP = (email) => async (dispatch) => {
    try {
        dispatch({
            type: OTP_REQUEST,
            payload: null
        });
        const res = await api.post('/forgot-password', { email });

        dispatch({
            type: OTP_SUCCESS,
            payload: res.data
        })
        let msg = res.data.message
        dispatch(setAlert(msg, 'success'))
            ;
    } catch (err) {
        const msg = err.response.data.message;
        dispatch(setAlert(msg, 'danger'))

        dispatch({
            type: OTP_FAILURE
        });
    }

}

//Register User
export const registerUser = (payload) => async (dispatch) => {
    payload.device_type = 'web'
    payload.device_token = 'ABC'
    payload.role_id = '2'
    let json = JSON.stringify(payload)
    try {
        dispatch({
            type: REGISTER_REQUEST,
            payload: null
        });
        const res = await api.post('/register', json);
        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data.data
        });
        let msg = res.data.message
        dispatch(setAlert(msg, 'success'))

    } catch (err) {
        dispatch({
            type: REGISTER_FAIL
        });
        const msg = err.response.data.message;
        dispatch(setAlert(msg, 'danger'))
    }
};

// Login User
export const login = (email, password) => async (dispatch) => {
    const body = { email, password }
    body.device_type = 'web'
    body.device_token = 'ABC'
    body.role_id = '2'
    try {
        dispatch({
            type: LOGIN_REQUEST,
            payload: null
        });
        const res = await api.post('/login', body);
        let verified = res.data.data.user.is_verified
        if (verified === 0) {
            dispatch({
                type: LOGIN_UNVERIFIED,
                payload: res.data.data
            });
        } else {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data.data
            });
            let msg = res.data.message
            dispatch(setAlert(msg, 'success'))
        }
    } catch (err) {
        const msg = err.response.data.message;
        dispatch(setAlert(msg, 'danger'))
        dispatch({
            type: LOGIN_FAIL
        });
    }
};

export const loginWithGoogle = (token, plateform) => async (dispatch) => {
    try {
        dispatch({
            type: LOGIN_GOOGLE_REQUEST,
            payload: null
        });

        const res = await api.get(`/get-socialuser?token=${token}&plateform=${plateform}`);

        let verified = res.data.data.user.is_verified
        if (verified === 0) {
            dispatch({
                type: LOGIN_GOOGLE_UNVERIFIED,
                payload: res.data.data
            });
        } else {
            dispatch({
                type: LOGIN_GOOGLE_SUCCESS,
                payload: res.data.data
            });
            let msg = res.data.message
            dispatch(setAlert(msg, 'success'))
        }

        // dispatch({
        //     type: LOGIN_GOOGLE_SUCCESS,
        //     payload: res.data.data
        // });

        const msg = res.data.message;
        console.log("Setting alert with message:", msg);
        dispatch(setAlert(msg, 'success'));
    } catch (err) {
        const msg = err?.response?.data?.message || "An error occurred";
        console.error("Error occurred:", err);

        dispatch(setAlert(msg, 'danger'));
        dispatch({
            type: LOGIN_GOOGLE_FAIL
        });
    }
};


// Logout
export const logout = () => async (dispatch) => {
    try {
        dispatch({ type: LOGOUT_REQUEST })
        let token = localStorage.getItem('token') || ''
        if (token != '') {
            const res = await api.post('/logout',
                {}, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
            let msg = res.data.message
            dispatch(setAlert(msg, 'success'))
        }
        localStorage.removeItem("user");
        localStorage.removeItem('token')
        dispatch({ type: LOGOUT })
        // window.location='/login'
    } catch (err) {
        dispatch({ type: LOGOUT_FAILURE })
        const msg = err.response.data.message;

        dispatch(setAlert(msg, 'danger'))
    }
}

//verifyOTP
export const verifyOTP = (payload) => async (dispatch) => {
    try {
        dispatch({ type: VERIFY_OTP_REQUEST })
        const res = await api.post('/verify-otp',
            payload);
        console.log("res", res);

        dispatch({
            type: VERIFY_OTP_SUCCESS,
            payload: res.data
        })

        let msg = res.data.message
        dispatch(setAlert(msg, 'success'))
    } catch (err) {
        const msg = err.response.data.message;
        dispatch({ type: VERIFY_OTP_FAILURE })
        dispatch(setAlert(msg, 'danger'))
    }
}

//resendOTP
export const resendOTP = (payload) => async (dispatch) => {
    try {
        dispatch({ type: RESEND_OTP_REQUEST })
        const res = await api.post('/resend-otp',
            payload);
        dispatch({
            type: RESEND_OTP_SUCCESS,
            payload: res.data

        })
        let msg = res.data.message
        dispatch(setAlert(msg, 'success'))

    } catch (err) {
        const msg = err.response.data.message;
        dispatch({ type: RESEND_OTP_FAILURE })
        dispatch(setAlert(msg, 'danger'))
    }
}

export const expireOTP = (payload) => async (dispatch) => {
    try {
        console.log("expire otp payload", payload);

        dispatch({ type: EXPIRE_OTP_REQUEST })
        const res = await api.post('/expire-otp',
            payload);
        dispatch({
            type: EXPIRE_OTP_SUCCESS,
            payload: res.data

        })
        let msg = res.data.message
        dispatch(setAlert(msg, 'success'))

    } catch (err) {
        const msg = err.response.data.message;
        dispatch({ type: EXPIRE_OTP_FAILURE })
        dispatch(setAlert(msg, 'danger'))
    }
}

export const getUserProfile = () => async (dispatch) => {
    try {
        dispatch({
            type: USER_PROFILE_REQUEST
        })
        let token = localStorage.getItem('token')
        const res = await api.post('/me',
            {}, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
        dispatch({
            type: USER_PROFILE_SUCCESS,
            payload: res.data.data
        });
    } catch (err) {
        const msg = err && err.response ? err.response.data.message : null;
        if (msg) {
            console.log("auth.js,getUserProfile", msg)
            dispatch(setAlert(msg, 'danger'))
        }
        dispatch({
            type: USER_PROFILE_FAILURE
        })
    }
}

export const getAuthProfile = () => async (dispatch) => {
    try {
        dispatch({
            type: USER_PROFILE_REQUEST
        })
        let token = localStorage.getItem('token')
        const res = await api.post('/me',
            {}, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
        dispatch({
            type: USER_PROFILE_SUCCESS,
            payload: res.data.data
        });
    } catch (err) {
        dispatch({
            type: USER_PROFILE_FAILURE
        })
    }
}

export const getPresignedPostUrl = (fileType, fileName) => async (dispatch) => {
    try {
        dispatch({
            type: USER_PROFILE_UPLOAD_REQUEST
        })
        let token = localStorage.getItem('token')
        const res = await api.get('get-s3-token?image_type=' + fileType + "&file_name=" + fileName, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
        dispatch({
            type: USER_PROFILE_UPLOAD_SUCCESS,
            payload: res.data.data
        });
    } catch (err) {
        const msg = err && err.response ? err.response.data.message : null;
        if (msg) {
            dispatch(setAlert(msg, 'danger'))
        }
        dispatch({
            type: USER_PROFILE_UPLOAD_FAILURE
        })
    }
}

export const updateUserProfile = (payload, id) => async (dispatch) => {
    let token = localStorage.getItem('token')
    try {
        dispatch({ type: USER_PROFILE_UPDATE_REQUEST })
        const res = await api.put(`/users/${id}`,
            payload, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
        dispatch({
            type: USER_PROFILE_UPDATE_SUCCESS,
        })
        let msg = res.data.message
        dispatch(setAlert(msg, 'success'))
    } catch (err) {
        const msg = err.response.data.message;
        dispatch({ type: USER_PROFILE_UPDATE_FAILURE })
        dispatch(setAlert(msg, 'danger'))
    }
}


export const getPageBySlug = (slug) => async (dispatch) => {
    try {
        dispatch({
            type: PAGE_DATA_REQUEST
        });
        const res = await api.get('/page-by-slug/' + slug);
        dispatch({
            type: PAGE_DATA_SUCCESS,
            payload: res.data.data
        })
    } catch (err) {
        const msg = err.response.data.message;
        dispatch(setAlert(msg, 'danger'))
        dispatch({
            type: PAGE_DATA_FAILURE
        });
    }

}

export const getOccupations = (page, perPage, is_paginate) => async (dispatch) => {
    try {
        dispatch({
            type: OCCUPATION_REQUEST
        });
        const params = {};
        if (page) {
            params['page'] = page;
        }
        if (perPage) {
            params['per-page'] = perPage;
        }
        params['is_paginate'] = is_paginate;
        params['order'] = "asc"
        params['order-column'] = "name"
        const res = await api.get("/occupations", {
            params: params
        });
        dispatch({
            type: OCCUPATION_SUCCESS,
            payload: res.data.data
        })
    } catch (err) {
        dispatch({
            type: OCCUPATION_FAILURE
        });
    }

}

export const getSports = (page, perPage, is_paginate) => async (dispatch) => {
    try {
        dispatch({
            type: SPORT_REQUEST
        });
        const params = {};
        if (page) {
            params['page'] = page;
        }
        if (perPage) {
            params['per-page'] = perPage;
        }
        params['is_paginate'] = is_paginate;
        const res = await api.get("/sports-list", {
            params: params
        });
        dispatch({
            type: SPORT_SUCCESS,
            payload: res.data.data
        })
    } catch (err) {
        dispatch({
            type: SPORT_FAILURE
        });
    }

}