import Modal from "react-bootstrap/Modal";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { checkValidation } from "../../../utils/helpers";
import { SKIP_TEST_REASONS } from "../../../utils/constants";
import FadeLoader from "react-spinners/FadeLoader";
import { skipTest } from "../../../actions/user_test";
import usePrevious from "../../Layout/usePrevious";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";


const SkipTestModal = ({ show, handleClose, skipTestRequest, skipTest, program, skipTestReasons, testId }) => {
    let { id } = useParams();
    const [selectedOptions, setSelectedOptions] = useState('');
    const [validationErrors, setValidationErrors] = useState({});
    const { register, watch, handleSubmit, setError, clearErrors, formState: { errors } } = useForm({
        mode: "onBlur",
    });

    // const onSubmitHandler = async (e) => {
    //     e.preventDefault();
    //     let formData = new FormData(e.target);
    //     let validate = await checkValidation(Object.fromEntries(formData), ['reason'])
    //     setValidationErrors(validate)
    //     if (Object.keys(validate).length > 0) {
    //         return false;
    //     } else {
    //         formData.append('program_id', program.id)
    //         formData.append('program_test_id', id)
    //         skipTest(formData)
    //     }
    // };

    const onSubmit = async data => {

        let submitData = {
            program_id: program.id,
            skip_question_reason_id: skipTestReasons[0]?.id || '',
            reason_text:selectedOptions,
            note: data.note,
            test_id: id
        }
        skipTest(submitData)
        handleClose(false)
    }


    const handleCheckboxChange = (event) => {
        const value = event.target.value;
        if (event.target.checked) {
            // If checkbox is checked, add the value to the string
            setSelectedOptions((prevSelected) =>
                prevSelected ? prevSelected + ',' + value : value
            );
        } else {
            // If checkbox is unchecked, remove the value from the string
            setSelectedOptions((prevSelected) =>
                prevSelected
                    ? prevSelected
                        .split(',')
                        .filter((item) => item !== value)
                        .join(',')
                    : ''
            );
        }
    };

    return (
      <Modal show={show} onHide={handleClose} className='modal_test custom-modal-style' id='skip' centered>
        <Modal.Header>
          <h4>Why do you want to skip the exercise?</h4>
          <button type='button' className='close' data-dismiss='modal' aria-label='Close' onClick={handleClose}>
            <span aria-hidden='true'>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className='modal-body-custom pad-block-modal'>
          <div className='box_state'>
            <Form
              onSubmit={handleSubmit(onSubmit)}
              className='formSkipTest'
              id='formSkipTest'
              autoComplete='off'
              role='presentation'>
              {/* <div className="form-group checkbox_custom">
                            <input type="radio" id="pain" name="reason" value={SKIP_TEST_REASONS.PAINFUL} />
                            <label htmlFor="pain">Painful</label>
                        </div>
                        <div className="form-group checkbox_custom">
                            <input type="radio" id="understand" name="reason"
                                value={SKIP_TEST_REASONS.DONT_UNDERSTAND} />
                            <label htmlFor="understand">I don't Understand</label>
                        </div>
                        <div className="form-group checkbox_custom">
                            <input type="radio" id="shortTime" name="reason" value={SKIP_TEST_REASONS.SHORT_TIME} />
                            <label htmlFor="shortTime">Short on time</label>
                        </div>
                        <div className="form-group checkbox_custom">
                            <input type="radio" id="skipWeek" name="reason" value={SKIP_TEST_REASONS.SKIP_ONE_WEEK} />
                            <label htmlFor="skipWeek">Skip the test for one week</label>
                        </div>
                        <div className="skip-test-error">
                            <span
                                className="show-error">{validationErrors['reason']}</span>
                        </div>

                        <div className="form-group">
                            <textarea className="form-control form-1" placeholder="Other" name="note"></textarea>
                        </div> */}
              {skipTestReasons &&
                skipTestReasons.map((item, index) => {
                  return (
                    <>
                      <Form.Group key={index} className='form-group checkbox_custom'>
                        <Form.Control
                          type='checkbox'
                          id={'skip-check-' + index}
                          name='reason'
                          value={item.option}
                          // checked={selectedValues == item?.id.toString() ? true : false}
                          {...register('reason', {
                            required: {
                              value: 'required',
                              message: 'Please select at least one option'
                            },
                            onChange: handleCheckboxChange
                          })}
                        />
                        <Form.Label htmlFor={'skip-check-' + index}>{item.option}</Form.Label>
                      </Form.Group>
                      {errors.reason && (
                        <small className='validationText hasError form-text'>{errors.reason.message}</small>
                      )}
                    </>
                  );
                })}
              <Form.Group className='mt-4 form-group'>
                <textarea
                  className='form-control form-2 text-left'
                  placeholder='Other'
                  rows='5'
                  maxLength='500'
                  name='note'
                  id='note'
                  {...register('note', {
                    maxLength: {
                      value: 500,
                      message: 'Max length is 500'
                    }
                  })}></textarea>
                {errors.note && <small className=' validationText hasError form-text'>{errors.note.message}</small>}
              </Form.Group>
              {skipTestRequest ? (
                <div className='loader_custom'>
                  <FadeLoader color={'#FB1417'} height={10} />
                </div>
              ) : (
                <Form.Control type='submit' value='Submit' className='theme-common-btn-big' />
              )}
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    );
};

const mapStateToProps = state => ({
    program: state.programReducer.program,
    skipTestRequest: state.testReducer.skipTestRequest,
    skipTestSuccess: state.testReducer.skipTestSuccess,

});

export default connect(mapStateToProps, { skipTest })(SkipTestModal);
