import React, { useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom"
import { connect } from 'react-redux';
import Form from "react-bootstrap/Form";
import PropTypes from 'prop-types';
import { reset } from '../../actions/auth';
import FadeLoader from "react-spinners/FadeLoader";
import { LoginMain } from '../../styles/common.style';
const ResetPassword = ({ reset, resetPasswordFailure, resetPasswordSuccess, resetPasswordRequest }) => {
    const [formData, setFormData] = useState(
        {
            email: "",
            password: "",
            password_confirmation: "",
            otp_code: null
        }
    );
    const location = useLocation();
    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
    const { email, password, password_confirmation, otp_code } = formData
    let navigate = useNavigate();
    const toHome = () => navigate("/", { replace: true })

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const onSubmit = (e) => {
        e.preventDefault();
        formData.email = location.state.email
        reset(formData)

    };
    if (resetPasswordSuccess) {
        navigate("/login", { replace: true })
    }
    return (
      <div>
        <LoginMain>
          <div className='login-main-block'>
            <div className='login-main-block-left'>
              <div className='login-main-block-left-link'>
                <Link to='/login'>
                  <img src='/images/auth-logo.svg' alt='img' />
                </Link>
              </div>
              <div className='login-main-block-left-banner'>
                <img src='/images/login-banner.png' alt='login-banner' />
                {/* <img src='/images/img42.png' alt='login-banner' /> */}
              </div>
              {/* <div className='title-block'>
                <h1>Welcome</h1>
              </div> */}
            </div>
            <div className='login-main-block-right'>
              {/* <LoginForm /> */}
              <div className='login-form-block'>
                <div className='login-form-block-title'>
                  <h2>Reset Your Password</h2>
                </div>
                <Form className='form-main' onSubmit={onSubmit}>
                  <Form.Group className='form_signup'>
                    <Form.Control
                      type='tel'
                      className=''
                      placeholder='Enter otp code'
                      pattern="[0-9]*"
                      name='otp_code'
                      value={otp_code}
                      onChange={onChange}
                      maxLength={6}
                      minLength={6}
                      required
                    />
                    <div className='form-sign-icon otp-mobile-icon'>
                      <img src='/images/otp-mobile-icon.svg' alt='icon-block' />
                    </div>
                  </Form.Group>
                  <Form.Group className='form_signup'>
                    <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      className=''
                      placeholder='Confirm Password'
                      id='password-field'
                      name='password'
                      value={password}
                      onChange={onChange}
                      required
                    />
                    <span
                      toggle='#password-field'
                      className={
                        showPassword
                          ? 'fa fa-fw fa-eye field-icon toggle-password'
                          : 'fa fa-fw fa-eye-slash field-icon toggle-password '
                      }
                      onClick={() => setShowPassword(!showPassword)}></span>
                    <div className='form-sign-icon'>
                      <img src='/images/password-icon.svg' alt='icon-block' />
                    </div>
                  </Form.Group>
                  <Form.Group className='form_signup'>
                    <Form.Control
                      type={showPasswordConfirm ? 'text' : 'password'}
                      className=''
                      placeholder='Confirm New Password '
                      id='password-field1'
                      name='password_confirmation'
                      onChange={onChange}
                      value={password_confirmation}
                      required
                    />
                    <span
                      toggle='#password-field1'
                      className={
                        showPasswordConfirm
                          ? 'fa fa-fw fa-eye field-icon toggle-password'
                          : 'fa fa-fw fa-eye-slash field-icon toggle-password '
                      }
                      onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}></span>
                    <div className='form-sign-icon'>
                      <img src='/images/password-icon.svg' alt='icon-block' />
                    </div>
                  </Form.Group>
                  <div className='bottom-auth-block'>
                    <div className='common-btn-main'>
                      <button className='btn btn-common-auth'>Save</button>
                    </div>
                    <p className='sign-text-block'>
                      Go to <Link to='/login'>Sign in</Link>
                    </p>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </LoginMain>
        {/* <section className='register image_reg' style={{ height: '100vh' }}>
          <div className='container  h-100'>
            <div className='row  h-100'>
              <div className='col-12 col-lg-5 col-md-7   my-auto'>
                <div className='box_register'>
                  <div className='mid_box'>
                    <div className='logo_signup' onClick={toHome}>
                      <svg width='223' height='61' viewBox='0 0 223 61' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M0.46582 54.4596L4.13868 51.8222C5.46013 54.1436 7.92425 55.474 10.7693 55.474C14.0962 55.474 16.6109 53.8705 16.6109 51.5999C16.6109 48.8533 12.7165 47.1912 9.26125 45.7009C5.79049 44.152 1.93108 42.3457 1.93108 37.6796C1.93108 32.8106 6.1364 29.4203 11.465 29.4203C15.6897 29.4203 18.8495 31.4685 20.0622 34.7301L16.607 37.1256C15.6897 35.1163 13.6492 33.9927 11.4999 33.9927C8.78319 33.9927 6.61446 35.6898 6.61446 37.7927C6.61446 40.1141 9.35064 40.9451 12.9535 42.4939C17.6369 44.5578 21.2748 46.8284 21.2748 51.5101C21.2748 56.7848 16.7197 60.1752 10.711 60.1752C5.84879 60.1752 2.1565 57.889 0.46582 54.4596Z'
                          fill='white'
                        />
                        <path
                          d='M23.8477 44.8504C23.8477 36.3687 30.7192 29.4359 39.1493 29.4359C47.5638 29.4359 54.4704 36.3687 54.4704 44.8504C54.4704 53.2579 47.5638 60.1557 39.1337 60.1557C30.7192 60.1557 23.8477 53.2579 23.8477 44.8504ZM49.7675 44.8153C49.7675 38.9319 44.9715 34.141 39.1299 34.141C33.2883 34.141 28.531 38.9358 28.531 44.8153C28.531 50.6596 33.2883 55.4349 39.1299 55.4349C44.9715 55.4349 49.7675 50.6596 49.7675 44.8153Z'
                          fill='white'
                        />
                        <path d='M76.5851 55.0292V59.6758H58.73V29.9509H63.3784V55.0292H76.5851Z' fill='white' />
                        <path
                          d='M80.021 48.4085V29.9509H84.6849V48.389C84.6849 52.5011 87.5688 55.5052 91.1873 55.5052C94.8057 55.5052 97.7285 52.5011 97.7285 48.389V29.9509H102.412V48.4085C102.412 55.2321 97.5069 60.2298 91.2067 60.2298C84.9065 60.2298 80.021 55.2321 80.021 48.4085Z'
                          fill='white'
                        />
                        <path
                          d='M126.361 34.5819H118.425V59.6758H113.761V34.5819H105.824V29.9509H126.361V34.5819Z'
                          fill='white'
                        />
                        <path d='M130.054 29.9509H134.702V59.6758H130.054V29.9509Z' fill='white' />
                        <path
                          d='M138.981 44.8504C138.981 36.3687 145.853 29.4359 154.283 29.4359C162.697 29.4359 169.604 36.3687 169.604 44.8504C169.604 53.2579 162.697 60.1557 154.267 60.1557C145.852 60.1557 138.981 53.2579 138.981 44.8504ZM164.901 44.8153C164.901 38.9319 160.105 34.141 154.263 34.141C148.422 34.141 143.664 38.9358 143.664 44.8153C143.664 50.6596 148.422 55.4349 154.263 55.4349C160.105 55.4349 164.901 50.6596 164.901 44.8153Z'
                          fill='white'
                        />
                        <path
                          d='M197.669 29.8767V60.1908H197.448L178.508 40.3325V59.7109H173.86V29.4359H174.097L192.998 49.259V29.8767H197.669Z'
                          fill='white'
                        />
                        <path
                          d='M201.653 54.4596L205.326 51.8222C206.648 54.1436 209.112 55.474 211.957 55.474C215.284 55.474 217.798 53.8705 217.798 51.5999C217.798 48.8533 213.904 47.1912 210.449 45.7009C206.978 44.152 203.119 42.3457 203.119 37.6796C203.119 32.8106 207.324 29.4203 212.652 29.4203C216.877 29.4203 220.037 31.4685 221.25 34.7301L217.794 37.1256C216.877 35.1163 214.837 33.9927 212.687 33.9927C209.967 33.9927 207.802 35.6898 207.802 37.7927C207.802 40.1141 210.538 40.9451 214.141 42.4939C218.824 44.5578 222.462 46.8284 222.462 51.5101C222.462 56.7848 217.907 60.1752 211.898 60.1752C207.036 60.1752 203.344 57.889 201.653 54.4596Z'
                          fill='white'
                        />
                        <path
                          d='M77.7629 11.9341C77.7629 14.4505 75.8663 16.9357 72.6171 16.9357H66.4995V0.514587H70.9652C73.7869 0.514587 75.6953 2.55113 75.6953 4.73203C75.6953 6.50327 74.4671 7.68541 73.8569 7.68541C75.6914 7.68541 77.7629 9.37862 77.7629 11.9341ZM69.053 3.0193V7.1158L71.0818 7.10409C72.3489 7.1158 73.2856 6.23798 73.2856 5.06755C73.2856 3.89712 72.2711 3.0193 71.1129 3.0193H69.053ZM75.2561 11.7624C75.2561 10.5217 74.1989 9.37862 72.345 9.37862L69.0569 9.39033V14.4232H72.3839C73.853 14.4193 75.2561 13.3503 75.2561 11.7624Z'
                          fill='white'
                        />
                        <path
                          d='M89.8389 8.74661C89.8389 4.061 93.6361 0.229797 98.2923 0.229797C102.941 0.229797 106.753 4.061 106.753 8.74661C106.753 13.3932 102.937 17.201 98.2806 17.201C93.6361 17.201 89.8389 13.3893 89.8389 8.74661ZM104.157 8.7232C104.157 5.47331 101.51 2.82424 98.2806 2.82424C95.0547 2.82424 92.4235 5.47331 92.4235 8.7232C92.4235 11.9536 95.0508 14.5909 98.2806 14.5909C101.51 14.5909 104.157 11.9536 104.157 8.7232Z'
                          fill='white'
                        />
                        <path
                          d='M133.023 8.74659C133.023 13.7872 129.785 16.9357 124.589 16.9357H119.929V0.514589H124.589C129.785 0.502885 133.023 3.66304 133.023 8.74659ZM130.423 8.73489C130.423 5.25092 128.168 3.07002 124.577 3.07002H122.486V14.3685H124.608C128.18 14.3685 130.423 12.1994 130.423 8.73489Z'
                          fill='white'
                        />
                        <path
                          d='M148.457 9.91702L143.027 0.514587H145.919L149.724 7.05337L153.541 0.514587H156.432L151.003 9.91702V16.9357H148.457V9.91702Z'
                          fill='white'
                        />
                        <path d='M56.7208 7.62689H1.91162V9.80389H56.7208V7.62689Z' fill='white' />
                        <path d='M222.466 7.62689H166.211V9.80389H222.466V7.62689Z' fill='white' />
                      </svg>
                    </div>
                    <Form className='form_signup' onSubmit={onSubmit}>
                      <h4>Reset Password</h4>
                      <p>Enter the 6-digit code sent to your email</p>
                      <Form.Group className='form-group'>
                        <div className='position-relative phone_ic'>
                          <Form.Control
                            type='tel'
                            className='form-control form-1 phone_img'
                            placeholder='Enter otp code'
                            name='otp_code'
                            value={otp_code}
                            onChange={onChange}
                            maxLength={4}
                            minLength={4}
                            required
                          />
                        </div>
                      </Form.Group>
                      <Form.Group className='form-group'>
                        <div className='position-relative password_ic'>
                          <Form.Control
                            type={showPassword ? 'text' : 'password'}
                            className='form-control form-1 pass_img'
                            placeholder='Type Password'
                            id='password-field'
                            name='password'
                            value={password}
                            onChange={onChange}
                            required
                          />
                          <span
                            toggle='#password-field'
                            className={
                              showPassword
                                ? 'fa fa-fw fa-eye field-icon toggle-password'
                                : 'fa fa-fw fa-eye-slash field-icon toggle-password '
                            }
                            onClick={() => setShowPassword(!showPassword)}></span>
                        </div>
                      </Form.Group>
                      <Form.Group className='form-group'>
                        <div className='position-relative password_ic'>
                          <Form.Control
                            type={showPasswordConfirm ? 'text' : 'password'}
                            className='form-control form-1 pass_img'
                            placeholder='Retype Password '
                            id='password-field1'
                            name='password_confirmation'
                            onChange={onChange}
                            value={password_confirmation}
                            required
                          />
                          <span
                            toggle='#password-field1'
                            className={
                              showPasswordConfirm
                                ? 'fa fa-fw fa-eye field-icon toggle-password'
                                : 'fa fa-fw fa-eye-slash field-icon toggle-password '
                            }
                            onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}></span>
                        </div>
                      </Form.Group>
                      <Form.Group className='form-group loader_custom'>
                        {resetPasswordRequest ? (
                          <FadeLoader color={'#FB1417'} height={10} />
                        ) : (
                          <Form.Control type='submit' value='Reset' name='submit' className='submit-btn w-100' />
                        )}
                      </Form.Group>
                      <p className='already'>
                        <div className='forgot'>
                          <Link to='/'>Return to Home</Link>
                        </div>
                      </p>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
    );

}
ResetPassword.propTypes = {
    reset: PropTypes.func.isRequired,
    resetPasswordRequest: PropTypes.bool,
    resetPasswordSuccess: PropTypes.bool.isRequired,
    resetPasswordFailure: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    resetPasswordRequest: state.auth.resetPasswordRequest || null,
    resetPasswordSuccess: state.auth.resetPasswordSuccess || null,
    resetPasswordFailure: state.auth.resetPasswordFailure || null,
});

export default connect(mapStateToProps, { reset })(ResetPassword)


