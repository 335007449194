import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import { getPainPoint } from "../../../actions/user_program";
import { Link, useLocation, useParams } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
import { cross_origin } from "../../../utils/constants";
import { Form } from "react-bootstrap";
import { CommonPageData } from '../../../styles/common.style';
function TriggerBodyPart({ getPainPoint, pain_point }) {
  const [painPointData, setPainPointData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const { id } = useParams();
  let location = useLocation();

  useEffect(() => {
    if (location?.state?.pain_point_data?.painPointLibraries) {
      setPainPointData(location.state.pain_point_data.painPointLibraries);
      setOriginalData(location.state.pain_point_data.painPointLibraries);
    }
  }, [location]);

  useEffect(() => {
    setTimeout(() => {
      const topElement = document.querySelector(".trigger-banner-recommended");
      if (topElement) {
        topElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 2000);
  }, []);

  useEffect(() => {
    if (loader) {
      setTimeout(() => setLoader(false), 500);
    }
  }, [currentPage, itemsPerPage]);

  const handleSearch = (value) => {
    setKeyword(value);
    if (!value) {
      setPainPointData(originalData);
    } else {
      const lowerCasedValue = value.toLowerCase();
      setPainPointData(originalData.filter(item => item.title.toLowerCase().includes(lowerCasedValue)));
    }
    setCurrentPage(1);
  };

  const totalPages = Math.ceil((painPointData?.length || 0) / itemsPerPage);
  const paginatedData = painPointData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <>
      <ScrollTopOnMount />
      <Header />
      <CommonPageData>
        <div className='common-banner-block'>
          <div className='main-banner'>
            <img src='/images/banner-common-block.png' alt='img' />
          </div>
          <div className='container'>
            <div className='common-banner-block-inner'>
              <h1>Trigger Point Library</h1>
            </div>
          </div>
        </div>
        <section className='videos'>
          <div className='container'>
            <div className='row row mb-custom-block'>
              {/* <div className='col-12'>
                <div className='videos_headings'>
                  <h2 className='detail_span' style={{ color: '#fff' }}>
                    TRIGGER POINT LIBRARY 1
                  </h2>
                </div>
              </div> */}
              <div className='search-block-custom'>
                <Form className='' onSubmit={(e) => e.preventDefault()}>
                  <Form.Control
                    value={keyword}
                    type='search'
                    placeholder='Search'
                    className='me-2 rounded-pill'
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                  <button>
                    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M15.2713 14.9121L12.357 12.0064C13.2973 10.8085 13.8075 9.32921 13.8056 7.80636C13.8056 6.45015 13.4034 5.12439 12.65 3.99674C11.8965 2.86909 10.8255 1.99019 9.57257 1.47119C8.31959 0.95219 6.94085 0.816396 5.61069 1.08098C4.28054 1.34556 3.05871 1.99864 2.09972 2.95763C1.14073 3.91662 0.487654 5.13845 0.22307 6.4686C-0.0415146 7.79876 0.0942797 9.1775 0.61328 10.4305C1.13228 11.6835 2.01118 12.7544 3.13883 13.5079C4.26648 14.2613 5.59224 14.6635 6.94845 14.6635C8.4713 14.6654 9.95056 14.1552 11.1485 13.2149L14.0542 16.1292C14.1339 16.2096 14.2287 16.2733 14.3331 16.3168C14.4376 16.3604 14.5496 16.3828 14.6627 16.3828C14.7759 16.3828 14.8879 16.3604 14.9924 16.3168C15.0968 16.2733 15.1916 16.2096 15.2713 16.1292C15.3516 16.0495 15.4154 15.9547 15.4589 15.8503C15.5024 15.7458 15.5249 15.6338 15.5249 15.5206C15.5249 15.4075 15.5024 15.2955 15.4589 15.191C15.4154 15.0866 15.3516 14.9918 15.2713 14.9121ZM1.8056 7.80636C1.8056 6.7892 2.10722 5.79488 2.67232 4.94915C3.23743 4.10341 4.04063 3.44423 4.98037 3.05498C5.9201 2.66573 6.95416 2.56389 7.95178 2.76233C8.94939 2.96076 9.86576 3.45057 10.585 4.16982C11.3042 4.88906 11.7941 5.80543 11.9925 6.80304C12.1909 7.80066 12.0891 8.83472 11.6998 9.77445C11.3106 10.7142 10.6514 11.5174 9.80567 12.0825C8.95993 12.6476 7.96561 12.9492 6.94845 12.9492C5.58448 12.9492 4.27638 12.4074 3.31191 11.4429C2.34743 10.4784 1.8056 9.17033 1.8056 7.80636Z'
                        fill='black'
                      />
                    </svg>
                  </button>
                </Form>
              </div>

              <div className='col-12 mt-4 mb-4'>
                <div className='row'>
                  {loader ? (
                    <div className='col-12 empty-page'>
                      <div className='spin-loader'>
                        <FadeLoader color={'#EC1246'} height={10} />
                      </div>
                    </div>
                  ) : paginatedData.length > 0 ? (
                    paginatedData.map((data, key) => (
                      <div className='col-3 mb-custom-30' key={key}>
                        <div className='library-img-container-main-inner'>
                          <div className='library-img-container-main common-box-height'>
                            <Link
                              className={'nav-link common-box-bg-all library-nav'}
                              to={`/trigger-pain-point/detail/${data.id}`}>
                              <div className='library-img-container'>
                                <div className='library-img-box'>
                                  <img src={data.image || ''} alt={data.title || ''} crossOrigin='anonymous' />
                                </div>
                                <div className='library-img-container-inner'>
                                  <div className='title-one'>{data.title}</div>
                                  <div className='title-two'>{data.title2}</div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className='col-12 library-empty-page'>
                      <span className='itemsPerPagelabel'>Not Found</span>
                    </div>
                  )}
                </div>
              </div>

              {painPointData.length > 0 && (
                <div className='col-12 mt-3 pagination'>
                  <span className='itemsPerPagelabel'>Items Per Page:</span>
                  <div className={'perpagedropdown'}>
                    <select
                      id='itemsPerPage'
                      value={itemsPerPage}
                      onChange={(e) => {
                        setItemsPerPage(Number(e.target.value));
                        setCurrentPage(1);
                      }}>
                      <option value={4}>4</option>
                      <option value={8}>8</option>
                      <option value={12}>12</option>
                      <option value={16}>16</option>
                      <option value={20}>20</option>
                    </select>
                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                      <path d='M7 10l5 5 5-5z'></path>
                      <path d='M0 0h24v24H0z' fill='none'></path>
                    </svg>
                  </div>
                  {/* <select
                  value={itemsPerPage}
                  onChange={(e) => {
                    setItemsPerPage(Number(e.target.value));
                    setCurrentPage(1);
                  }}
                >
                  {[4, 8, 12, 16, 20].map(size => <option key={size} value={size}>{size}</option>)}
                </select> */}
                  <span className='totalpages'>
                    Page {currentPage} of {totalPages}
                  </span>
                  <div className='paginationbuttons'>
                    {currentPage > 1 ? (
                      <button
                        className={'button-previous'}
                        onClick={() => {
                          setCurrentPage(currentPage - 1);
                          setLoader(true);
                        }}
                        disabled={currentPage === 1}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          aria-hidden='true'
                          role='presentation'>
                          <path d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z'></path>
                          <path d='M0 0h24v24H0z' fill='none'></path>
                        </svg>
                      </button>
                    ) : (
                      ''
                    )}
                    {currentPage < totalPages ? (
                      <button
                        className={'button-next'}
                        onClick={() => {
                          setCurrentPage(currentPage + 1);
                          setLoader(true);
                        }}
                        disabled={currentPage === totalPages}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          aria-hidden='true'
                          role='presentation'>
                          <path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'></path>
                          <path d='M0 0h24v24H0z' fill='none'></path>
                        </svg>
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </CommonPageData>
      <Footer />
    </>
  );
}

export default TriggerBodyPart;
