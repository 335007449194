import React from 'react'
import { CommonPageData } from '../../../styles/common.style';
const Assessment1 = () => {
  return (
    <CommonPageData>
      <div>
        <section className='assestmentd1_001'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 col-lg-12 col-md-12 text-center'>
                <h3 className='title-inner-block'>
                  Daily <span>Summary </span>
                </h3>
              </div>
            </div>
          </div>
        </section>
      </div>
    </CommonPageData>
  );
}

export default Assessment1