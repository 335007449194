import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { getProgramTests } from "../../../actions/user_program";
import { pluck } from "../../../utils/helpers";
import { setAlert } from "../../../actions/alert";
import CustomVideoPlayer from "../AssessmentTimer/CustomVideoPlayer";
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import DetailsCustomVideoPlayer from "../AssessmentTimer/DetailsCustomVideoPlayer";
import { CommonPageData } from '../../../styles/common.style';
import { cross_origin } from "../../../utils/constants";

const TestVideos = ({
  ownProps,
  getTestsRequest,
  program_tests,
  getProgramTests,
  setAlert,
}) => {
  // const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [programTests, setProgramTests] = useState([]);
  let [selectedTests, setSelectedTests] = useState([]);
  let [selectAllChecked, setSelectAllChecked] = useState(false);
  const { program } = location.state;

  useEffect(() => {
    if (program) {
      setProgramTests(program.programTests ? program.programTests : []);
    }
  }, [program]);

  const handleSelectAll = async () => {
    setSelectAllChecked(!selectAllChecked);
    if (!selectAllChecked) {
      setSelectedTests(await pluck(programTests, "id"));
    } else {
      setSelectedTests([]);
    }
  };

  const handleCheck = (e, id) => {

    if (e.target.checked && !selectedTests.includes(id)) {
      setSelectedTests([...selectedTests, id]);
    } else {
      setSelectedTests((selectedTests) =>
        selectedTests.filter((item) => {
          return item !== id;
        })
      );
    }
  };

  const startTests = (e) => {
    e.preventDefault();
    if (selectedTests.length == 0) {
      setAlert("Please select at least one test", "danger");
    } else {
      navigate("/assessment-test/" + selectedTests[0], {
        state: { selectedTests },
      });
    }
  };

  const handlePlayerClick = (e) => {
    e.preventDefault(); // Prevent event propagation to parent elements
    e.nativeEvent.stopImmediatePropagation();
  };

  return (
    <>
      <ScrollTopOnMount />
      <Header />
      {/*<section className="welcome_001">*/}
      {/*    <div className="container h-100">*/}
      {/*        <div className="row h-100">*/}
      {/*            <div className="col-12 col-lg-6 col-md-6 m-auto">*/}
      {/*                <div className="box_banner_inner text-center m-auto">*/}
      {/*                    <div className="mx-auto">*/}
      {/*                        <h1>Test Videos</h1>*/}
      {/*                    </div>*/}
      {/*                </div>*/}
      {/*            </div>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</section>*/}
      <CommonPageData>
        <div className='common-banner-block'>
          <div className='main-banner'>
            <img src='/images/banner-common-block.png' alt='img' />
          </div>
          <div className='container'>
            <div className='common-banner-block-inner'>
              <h1>Choose the tests that you will perform daily</h1>
              <p>Start with the test that you struggled with the most during the assessment</p>
            </div>
          </div>
        </div>
        <section className='videos test-block-videos'>
          <div className='container'>
            <div className='col-12 col-lg-12 col-md-12'>
              <div className="message-info">
                Ideally, you should perform these tests before each workout to determine if you need to spend extra time on a specific area.
              </div>
              <p className='text-center mt-4 mb-4'>
                This tests are based on your assessment results.
              </p>
              <div className='ssa top-video-box common-box-bg box_video' style={{ textAlign: 'center' }} data-click='0'>
                <video
                  className='video'
                  controls
                  controlsList='nodownload'
                  preLoad='auto'
                  crossOrigin={cross_origin ? 'anonymous' : null}>
                  <source
                    src='https://d1rs42xew6aaeb.cloudfront.net/1. Program Management/1. General Videos (not body type specific)/Welcome Video TEMP COM.mp4#t=0.001'
                    type='video/mp4'
                  />
                </video>
              </div>
            </div>
            <div className='row'>
              {/* <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-5'>
                <div className='videos_headings text-center'>
                  <h2 className='detail_span text-center' style={{ color: '#fff' }}>
                    Choose the tests that you will perform daily
                  </h2>
                  <h4>Start with the test that you struggled with the most during the assessment</h4>
                </div>
              </div> */}
              {programTests.length === 0 ? (
                <div className={'empty-page'}>
                  <span className='itemsPerPagelabel'>No Tests Available.</span>
                </div>
              ) : (
                programTests &&
                programTests.map((item, index) => (
                  <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4' key={index}>
                    <div className='test_videobox common-box-bg-all'>
                      <div className='check_test'>
                        <input
                          type='checkbox'
                          id={`check_test_${item.id}`}
                          className='exercise'
                          onChange={(e) => {
                            handleCheck(e, item.id);
                          }}
                          checked={selectedTests.includes(item.id) ? true : false}
                        />
                        <label className='' htmlFor={`check_test_${item.id}`} onClick={handlePlayerClick}>
                          <div
                            className='check-block-videso-block'
                            onClick={() =>
                              handleCheck({ target: { checked: !selectedTests.includes(item.id) } }, item.id)
                            }>
                            <img src='/images/check-border.png' alt='img' />
                          </div>
                          <div className='vids'>
                            <div className='video_new'>
                              <div className='ssa video_testbox' style={{ textAlign: 'center' }} data-click='0'>
                                <DetailsCustomVideoPlayer
                                  videoUrl={item.video}
                                  className={'video'}
                                  posterUrl={item.images}
                                  showTime={true}
                                />
                                {/*<video className="video" style={{ width: '100%' }}*/}
                                {/*    controls="controls" crossOrigin='anonymous'  preload="meta">*/}
                                {/*    <source src={item.video + '#t=0.001'} type="video/mp4" />*/}
                                {/*</video>*/}
                              </div>
                            </div>
                            <div className='video_testcontent'>
                              <h6>{item.title}</h6>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
            <div className='block-btn-test justify-center-block'>
              {programTests.length > 0 ? (
                <div class='test_btn text-center'>
                  <Link to='#' class='theme-common-btn-big mt-30' data-target='#statuscheckout' onClick={startTests}>
                    Start Selected test
                  </Link>
                  <button class='theme-common-btn-big mt-30 checkAll' onClick={handleSelectAll}>
                    {selectedTests.length == programTests.length ? 'Unselect All' : 'Select All'}
                  </button>
                  <Link to={`/test-instructions/${program?.id}`} class='theme-common-btn-big mr-10' >
                    Back
                  </Link>
                </div>
              ) : (
                <div class='test_btn text-center'>
                  <Link to={`/assessment-timer`} className='theme_btn1 mt-30'>
                    Start Exercises
                  </Link>
                </div>
              )}
            </div>
          </div>
        </section>
      </CommonPageData>
      <Footer />
    </>
  );
};
const mapStateToProps = (state, ownProps) => ({
  getTestsRequest: state.programReducer.getTestsRequest,
  getTestsSuccess: state.programReducer.getTestsSuccess,
  getTestsFailure: state.programReducer.getTestsFailure,
  program_tests: state.programReducer.program_tests,
  ownProps: ownProps,
});

export default connect(mapStateToProps, { getProgramTests, setAlert })(
  TestVideos
);
