import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from 'react-redux';
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Welcome1 from './Welcome1'
import Welcome3 from './Welcome3'
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { getProgram } from "../../../actions/user_program";
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import HowToUseModal from "../../Layout/HowToUseModal";


const Welcome = ({ getProgram,hasRegisterPopup }) => {
    const [show, setShow] = useState(false);
    let userData = useSelector((state) => state.auth);
    userData = userData?.user?.user
    let user = JSON.parse(localStorage.getItem('user')) || userData || null;
    useEffect(() => {
        getProgram()
        const hasModalBeenShown = localStorage.getItem('hasModalBeenShown');
        if (user?.is_first_login === 1) {
            handleShow();
        }
    }, [])
    const handleShow = () => {
        setShow(true)
    };
    const handleClose = () => setShow(false);
    return (
        <div id="welcome">
            <ScrollTopOnMount />
            <HowToUseModal handleClose={handleClose} show={show} />
            <Header />
            <Welcome1 />
            <Welcome3 />
            <Footer />
        </div>
    );
};

Welcome.propTypes = {
    getProgram: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    hasRegisterPopup: state.auth.hasRegisterPopup,
});

export default connect(
    mapStateToProps,
    { getProgram }
)(Welcome);
