import React, { useEffect } from 'react'


const AboutNew = () => {
  
  
  return (
    <div>
        <h1>About New Page</h1>
    </div>
  )
}

export default AboutNew