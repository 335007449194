import React, { useEffect, useState } from 'react'
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import { CommonPageData } from '../../../styles/common.style';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import { Interweave } from "interweave";
import ChangeLevelModalNew from '../../Layout/ChangeLevelModalNew'
import { updateExerciseLevel } from '../../../actions/user_program'
import { connect, useDispatch } from 'react-redux'

const NewChangeLevelSecond = ({ updateExerciseLevel, levelUpdateSuccess, levelUpdateRequest, levelUpdate }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  const [exerciseLevel, setExerciseLevel] = useState([]);
  const [activeId, setActiveId] = useState(null)
  const [programId, setProgramId] = useState(null)
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const handleClose = () => setIsModalOpen(false);

  const handleConfirmClose = () => setIsModalConfirmOpen(false);

  const handleShowExercise = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {

    if (location.state?.levelData && location.state.activeId) {

      setExerciseLevel(location.state.levelData);
      setActiveId(location.state.activeId)
      setProgramId(location.state.programId)
    }
  }, [location.state]);

  const handleConfirmShow = () => {
    setIsModalOpen(false)
    setIsModalConfirmOpen(true);
  }

  const handleSubmit = () => {
    console.log("Submit button clicked");
    if (exerciseLevel?.exercise_id && exerciseLevel?.exercise_level_id) {
      console.log("Executing updateExerciseLevel");
      updateExerciseLevel(activeId, exerciseLevel.exercise_id, exerciseLevel.exercise_level_id);
    } else {
      console.warn("Missing exercise_id or exercise_level_id", exerciseLevel);
    }
  };

  if (levelUpdateSuccess && levelUpdate) {
    dispatch({ type: 'LEVEL_CHANGE_PURGE' });
    //navigate(`/program/${programId}`, { replace: true, state: { myData: activeId } });
    navigate(`/program/${programId}`, { replace: true });

  }

  return (
    <div>
      <Header />
      <CommonPageData>
        <div className='common-banner-block'>
          <div className='main-banner'>
            <img src='/images/banner-common-block.png' alt='img' />
          </div>
          <div className='container'>
            <div className='common-banner-block-inner'>
              <h1>Change Movement</h1>
            </div>
          </div>
        </div>
        <section className='change-block'>
          <div className='container'>
            <div className='top-block diff-text-center'>
              <h2 className='title-inner-block'>REPLACE MOVEMENT?</h2>
            </div>
            <div className='video-block-play'>
              <div className='video-block'>
                <img src={exerciseLevel?.exerciseLevel?.video_cover} alt='img' />
                <button>
                  <svg width='41' height='41' viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M20.8637 0.939392C9.87644 0.939392 0.977295 9.75378 0.977295 20.6364C0.977295 31.5189 9.87644 40.3333 20.8637 40.3333C31.8509 40.3333 40.75 31.5189 40.75 20.6364C40.75 9.75378 31.8509 0.939392 20.8637 0.939392ZM16.8864 29.5V11.7727L28.8182 20.6364L16.8864 29.5Z'
                      fill='#C00000'></path>
                  </svg>
                </button>
              </div>
              <div className='release-block mb-3'>
                <p>
                  {exerciseLevel?.exerciseLevel?.file_name}
                </p>
                <button
                  onClick={() => {
                    handleShowExercise();
                  }}>
                  Movement Instructions
                </button>
              </div>
              <div className='col-12 col-xl-12 col-lg-12 col-md-12 test_one mt-3'>
                <div className='center-block-btn'>
                  <button
                    className='common-btn-all-front'
                    onClick={() => {
                      handleConfirmShow();
                    }}>
                    Choose this movement
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </CommonPageData>
      {isModalOpen && (
        <Modal
          show={isModalOpen}
          onHide={handleClose}
          id=''
          className='modal fade custom-modal-style muscles-modal'
          // aria-hidden='true'
          // aria-labelledby='exampleModalLabel'
          size='xl'>
          <Modal.Header>
            <h4>Change Level</h4>
            <button type='button' className='close' data-dismiss='modal' aria-label='Close' onClick={handleClose}>
              <span aria-hidden='true'>&times;</span>
            </button>
          </Modal.Header>
          <div className='modal-body-custom'>
            <div className='changes-level-modal'>
              <div className='changes-level-modal-img'>
                <img src={exerciseLevel?.exerciseLevel?.video_cover} alt='img' />
              </div>
              <p>
                {exerciseLevel?.exerciseLevel?.file_name}
              </p>
            </div>
            <div className='position-block-inner'>
              <div className='position-block-inner-body'>
                <h3>starting position</h3>
                <p>
                  <Interweave content={exerciseLevel?.exerciseLevel?.starting_position} />
                </p>
              </div>
              <div className='position-block-inner-body'>
                <h3>Movement</h3>
                <p>
                  <Interweave content={exerciseLevel?.exerciseLevel?.movements} />
                </p>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {isModalConfirmOpen && (
        <Modal
          show={isModalConfirmOpen}
          onHide={handleConfirmClose}
          id=''
          className='modal fade custom-modal-style muscles-modal'
          // aria-hidden='true'
          // aria-labelledby='exampleModalLabel'
          size='xl'>
          <Modal.Header>
            <h4>SELECT THIS MOVEMENT</h4>
            <button
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
              onClick={handleConfirmClose}>
              <span aria-hidden='true'>&times;</span>
            </button>
          </Modal.Header>
          <div className='modal-body-custom'>
            <div className='col-12 col-xl-12 col-lg-12 col-md-12 test_one'>
              <div className='center-block-btn'>
                <button type="button" onClick={handleSubmit} className='common-btn-all-front'>Confirm</button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      <Footer />
    </div>
  );
};
const mapStateToProps = (state, ownProps) => ({
  levelUpdateRequest: state.programReducer.levelUpdateRequest || null,
  levelUpdateSuccess: state.programReducer.levelUpdateSuccess || null,
  levelUpdateFailure: state.programReducer.levelUpdateFailure || null,
  levelUpdate: state.programReducer.levelUpdate,
});

export default connect(mapStateToProps, { updateExerciseLevel })(NewChangeLevelSecond);