import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
import HowToUseModal from "../HowToUseModal";
import { FooterMain } from '../../../styles/common.style';
const FooterTop = () => {
  const user = localStorage.getItem("user");
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => setShow(false);

  return (
    <>
      <FooterMain>
        <footer className='footer'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 col-xl-4 col-lg-12 col-md-12'>
                <div className='bx_footer'>
                  <div className='footer_logo'>
                    <Link to={user ? '/home' : '/'}>
                      {' '}
                      <img src={logo} alt='logo' className='img-fluid' />
                    </Link>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua.
                  </p>
                  <ul className='cards'>
                    <li>
                      <div>
                        <Link to='#'>
                          <svg
                            width='24'
                            height='23'
                            viewBox='0 0 24 23'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M15.238 12.0374L15.6145 9.3754H13.2211V7.63929C13.2211 6.88697 13.5438 6.19253 14.6195 6.19253H15.7221V3.90665C15.7221 3.90665 14.7271 3.7041 13.7859 3.7041C11.8228 3.7041 10.532 5.00618 10.532 7.321V9.3754H8.32684V12.0374H10.532V18.5189H13.2211V12.0374H15.238Z'
                              fill='white'
                            />
                          </svg>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div>
                        <Link to='#'>
                          <svg
                            width='23'
                            height='23'
                            viewBox='0 0 23 23'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M17.7192 8.077C18.3705 7.575 18.9566 6.97259 19.4124 6.26978C18.8263 6.53751 18.1425 6.73832 17.4587 6.80525C18.1751 6.37018 18.6961 5.70083 18.9566 4.86415C18.3053 5.26576 17.5564 5.56696 16.8075 5.7343C16.1562 5.03149 15.2771 4.62988 14.3002 4.62988C12.4116 4.62988 10.8812 6.20284 10.8812 8.14394C10.8812 8.41168 10.9137 8.67941 10.9789 8.94715C8.14595 8.77982 5.6061 7.37419 3.91287 5.26576C3.61981 5.76777 3.457 6.37018 3.457 7.03952C3.457 8.24434 4.04312 9.31529 4.98742 9.95117C4.43386 9.9177 3.88031 9.78383 3.42444 9.51609V9.54956C3.42444 11.2564 4.59667 12.662 6.15966 12.9967C5.89916 13.0636 5.57354 13.1306 5.28048 13.1306C5.05254 13.1306 4.85717 13.0971 4.62924 13.0636C5.05254 14.4692 6.32247 15.4733 7.82032 15.5067C6.64809 16.4438 5.18279 17.0127 3.58725 17.0127C3.29419 17.0127 3.03369 16.9793 2.77319 16.9458C4.27105 17.9498 6.06197 18.5188 8.0157 18.5188C14.3002 18.5188 17.7192 13.1975 17.7192 8.54555C17.7192 8.37821 17.7192 8.24434 17.7192 8.077Z'
                              fill='white'
                            />
                          </svg>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div>
                        <Link to='#'>
                          <svg
                            width='24'
                            height='23'
                            viewBox='0 0 24 23'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M11.9727 6.8138C9.59568 6.8138 7.70148 8.74833 7.70148 11.0921C7.70148 13.473 9.59568 15.3704 11.9727 15.3704C14.3126 15.3704 16.244 13.473 16.244 11.0921C16.244 8.74833 14.3126 6.8138 11.9727 6.8138ZM11.9727 13.8823C10.4499 13.8823 9.18713 12.6546 9.18713 11.0921C9.18713 9.56678 10.4128 8.3391 11.9727 8.3391C13.4955 8.3391 14.7212 9.56678 14.7212 11.0921C14.7212 12.6546 13.4955 13.8823 11.9727 13.8823ZM17.3953 6.66499C17.3953 6.10696 16.9496 5.66053 16.3925 5.66053C15.8354 5.66053 15.3897 6.10696 15.3897 6.66499C15.3897 7.22303 15.8354 7.66946 16.3925 7.66946C16.9496 7.66946 17.3953 7.22303 17.3953 6.66499ZM20.2181 7.66946C20.1438 6.33017 19.8466 5.1397 18.881 4.17243C17.9153 3.20517 16.7268 2.90755 15.3897 2.83315C14.0155 2.75874 9.89281 2.75874 8.51859 2.83315C7.18151 2.90755 6.03013 3.20517 5.02732 4.17243C4.06165 5.1397 3.76452 6.33017 3.69023 7.66946C3.61595 9.04595 3.61595 13.1754 3.69023 14.5519C3.76452 15.8912 4.06165 17.0445 5.02732 18.0489C6.03013 19.0162 7.18151 19.3138 8.51859 19.3882C9.89281 19.4626 14.0155 19.4626 15.3897 19.3882C16.7268 19.3138 17.9153 19.0162 18.881 18.0489C19.8466 17.0445 20.1438 15.8912 20.2181 14.5519C20.2923 13.1754 20.2923 9.04595 20.2181 7.66946ZM18.4353 16.0028C18.1753 16.7468 17.581 17.3049 16.8754 17.6025C15.7611 18.0489 13.1612 17.9373 11.9727 17.9373C10.7471 17.9373 8.14718 18.0489 7.07008 17.6025C6.32726 17.3049 5.77014 16.7468 5.47301 16.0028C5.02732 14.9239 5.13874 12.3198 5.13874 11.0921C5.13874 9.9016 5.02732 7.29743 5.47301 6.18136C5.77014 5.47452 6.32726 4.91648 7.07008 4.61886C8.14718 4.17243 10.7471 4.28404 11.9727 4.28404C13.1612 4.28404 15.7611 4.17243 16.8754 4.61886C17.581 4.87928 18.1382 5.47452 18.4353 6.18136C18.881 7.29743 18.7696 9.9016 18.7696 11.0921C18.7696 12.3198 18.881 14.9239 18.4353 16.0028Z'
                              fill='white'
                            />
                          </svg>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-12 col-xl-3 col-lg-4 col-md-12'>
                <h6 className='title-footer'>About </h6>
                <ul className='menu_footer'>
                  <li>
                    <Link to='/'>Home</Link>
                  </li>
                  {/* <li>
                    <Link to={user ? '/pages/about-us' : '/page/about-us'}>About</Link>
                  </li> */}
                  <li>
                    <Link to='#' onClick={handleShow}>
                      How to use the app
                    </Link>
                  </li>
                  {/* <li>
                    <Link to='/faqs'>FAQs</Link>
                  </li> */}
                  {/* <li>
                  <Link to="/welcome">Programs </Link>
                </li> */}
                  <li>
                    <Link to={user ? '/educational-videos' : '/education'}>Education </Link>
                  </li>
                  {user && (
                    <>
                      <li>
                        <Link to='/muscles-anatomy/library'>Muscles Library</Link>
                      </li>
                      <li>
                        {/* <Link to="/trigger-pain-point/library"> */}
                        <Link to='/trigger-categories'>Trigger Point Library</Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>
              <div className='col-12 col-xl-3 col-lg-4 col-md-12'>
                <h6 className='title-footer'>Company </h6>
                <ul className='menu_footer'>
                  <li>
                    <Link to={user ? '/pages/about-us' : '/page/about-us'}>About Us </Link>
                  </li>
                  {/*<li>*/}
                  {/*  <Link to="#">Who We Are</Link>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                  {/*  <Link to="#">What We Do </Link>*/}
                  {/*</li>*/}
                  <li>
                    <Link to='#'>Contact Us </Link>
                  </li>
                  <li>
                    <Link to='/faqs'>FAQs</Link>
                  </li>
                  <li>
                    <Link to='/pages/terms-and-conditions'>Terms and conditions </Link>
                  </li>
                  <li>
                    <Link to='/pages/privacy-policy'>Privacy Policy </Link>
                  </li>
                </ul>
              </div>
              <div className='col-12 col-xl-2 col-lg-4 col-md-12'>
                <h6 className='title-footer'>Contact Detail </h6>
                <div className='icon-contact-details'>
                  <ul>
                    <li>
                      <a className='link-f-last' href='mailto:info@bodysolutions.com'>
                        <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M10.085 10.9133C13.5736 14.4028 14.3651 10.3659 16.5863 12.5867C18.7277 14.7287 19.9585 15.1578 17.2453 17.8716C16.9055 18.1449 14.7462 21.4325 7.15778 13.8422C-0.43159 6.251 2.85238 4.08839 3.12557 3.74846C5.84532 1.02712 6.26685 2.26571 8.40828 4.40766C10.6295 6.62943 6.59637 7.42386 10.085 10.9133Z'
                            fill='white'
                          />
                        </svg>
                        <span>info@bodysolutions.com</span>
                      </a>
                    </li>
                    <li>
                      <a className='link-f-last' href='tel:(758) 937 - 2694'>
                        <svg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M0 0V2.25L8.9852 6.75L17.9704 2.25V0H0ZM0 4.5V13.5H17.9704V4.5L8.9852 9L0 4.5Z'
                            fill='white'
                          />
                        </svg>
                        <span>(758) 937 - 2694</span>
                      </a>
                    </li>
                    <li>
                      <div className='link-f-last'>
                        <svg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M8.99531 0L0 6H2.24883V14H6.74649V10H11.2441V14H15.7418V5.94L17.9906 6L8.99531 0Z'
                            fill='white'
                          />
                        </svg>
                        <span>28 Cambridge Avenue San Francisco 3426 </span>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* <div className='help'>
                  <p>
                    <a href='mailto:info@bodysolutions.com'>info@bodysolutions.com</a>
                  </p>

                  <p>28 Cambridge Avenue San Francisco 3426</p>
                  <p>
                    <a href='tel:(758) 937 - 2694'>(758) 937 - 2694</a>
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </footer>
        <HowToUseModal handleClose={handleClose} show={show} />
      </FooterMain>
    </>
  );
};

export default FooterTop;
