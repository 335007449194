import React, { useEffect, useState } from 'react';
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import Footer from "../../Layout/Footer";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { getAptVideos, getProgram, getExerciseType } from "../../../actions/user_program";
import Header from "../../Layout/Header";
import { cross_origin } from "../../../utils/constants";
import imgNotFound from "../../images/broken-hd.png";
import { useNavigate } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
import { CommonPageData } from '../../../styles/common.style';
import BreadcrumbMenu from "../BreadcrumbMenu";

function Education({ ExerciseTypeSuccess, aptVideosSuccess, aptVideos, getAptVideos, user, getProgram, program, getExerciseType, exercise_types }) {
  const [itemsEdPerPage, setItemsEdPerPage] = useState(8);
  const [totalEdPages, setEdTotalPages] = useState(0)
  const [bodyTypeVideos, setBodyTypeVideos] = useState(null)

  const [currentPage, setCurrentPage] = useState(aptVideos ? parseInt(aptVideos.meta.current_page) : 1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [totalAptPages, setAptTotalPages] = useState(0)
  const [programEdVideos, setProgramEdVideos] = useState(null)

  const [categoryId, setCategoryId] = useState(null)
  const [loader, setLoader] = useState(true);

  const breadcrumbs = [
    { label: 'Home', link: '/' },
    { label: `${program?.name}`, link: `/program/${program?.id}` },
    { label: 'Body Type Videos', link: '#', isActive: true }
  ];

  const navigate = useNavigate();

  useEffect(() => {
    getProgram()
    getExerciseType()
    console.log('exercise_types', exercise_types)
  }, []);

  useEffect(() => {
    getAptVideos(currentPage, itemsPerPage, 1)
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    setAptTotalPages(aptVideos ? parseInt(aptVideos.meta.last_page) : 1)
    setBodyTypeVideos(aptVideos ? aptVideos.data : [])
  }, [aptVideos]);

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
  };

  const handleItemsEdPerPageChange = (e) => {
    setItemsEdPerPage(parseInt(e.target.value));
  };

  const filterUniqueItems = (arr) => {
    // Create a Set to store unique IDs
    const uniqueIds = new Set();

    // Filter the array based on the unique IDs
    const filteredArray = arr.filter((item) => {
      if (!uniqueIds.has(item.video)) {
        // If the 'id' is not in the Set, add it and include the item in the filtered array
        uniqueIds.add(item.video);
        return true;
      }
      return false;
    });

    return filteredArray;
  };

  const categoryByVideos = (categoryId, name) => {
    navigate("/educational-videos", { state: { category: categoryId, categoryName: name } })
  }

  const ProductCard = (data) => {
    const productData = data?.data;
    return (
      <div
        className={"product-box"}
        style={{ cursor: "pointer" }}
        onClick={() => categoryByVideos(productData?.id, productData?.name)}
      >
        <div className={"mx-auto text-center"}>
          <img
            className={"img-fluid product-img"}
            crossOrigin={cross_origin ? "anonymous" : null}
            src={productData?.image ? productData?.image : imgNotFound}
            alt={productData?.title}
            title={data?.title}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = imgNotFound;
            }}
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>

      </div>
    );
  };


  return (
    <>
      <ScrollTopOnMount />
      <Header />
      {!ExerciseTypeSuccess && !aptVideosSuccess ? (
        <div className='col-12 col-sm-12 col-md-12  col-lg-12 col-xl-12 '>
          <div className={'empty-page-loader'}>
            <div className='spin-loader'>
              <FadeLoader color={'#EC1246'} height={10} />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={'clearfix'}></div>
          <CommonPageData>
            <div className='common-banner-block'>
              <div className='main-banner'>
                <img src='/images/banner-common-block.png' alt='img' />
              </div>
              <div className='container'>
                <div className='common-banner-block-inner'>
                  <h1>Educational Videos</h1>
                </div>
              </div>
            </div>
            <section className='videos'>
              <div className='container'>
                <div className='row common-padding-block'>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 '>
                    <div className='col-12 col-lg-8 col-md-8'>
                      <BreadcrumbMenu
                        breadcrumbs={breadcrumbs}
                        classNames={""}
                      />
                    </div>
                    <div className='videos_headings'>
                      <h2 className='detail_span'>
                        {/* YOUR BODY TYPE VIDEOS */}
                        Your Body Type <span>Videos</span>
                      </h2>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 '>
                    <div className='row'>
                      {program?.bodyTypeVideos?.length > 0 ? (
                        program?.bodyTypeVideos?.map((data, key) => {
                          if (data) {
                            return (
                              <div className='col-12 col-sm-12 col-md-6  col-lg-4 col-xl-3 ' key={key}>
                                <div className='test_videobox-education'>
                                  <div className='check_test'>
                                    <video
                                      className='video  '
                                      controls
                                      controlsList='nodownload'
                                      preLoad='auto'
                                      crossOrigin={cross_origin ? 'anonymous' : null}
                                      onContextMenu={(e) => e.preventDefault()}>
                                      <source src={data?.video ? data?.video + '#t=0.001' : ''} type='video/mp4' />
                                    </video>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })
                      ) : (
                        <div className='col-12 col-sm-12 col-md-12  col-lg-12 col-xl-12 '>
                          <div className={'empty-page'}>
                            <span className='itemsPerPagelabel'>Not any "YOUR BODY TYPE VIDEOS" uploaded yet</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <>
                  <div className='row second-video-block'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                      <div className='videos_headings'>
                        <h2 className='detail_span'>
                          {/* EDUCATIONAL VIDEOS CATEGORY */}
                          Educational <span>Videos</span> in Program
                        </h2>
                      </div>
                    </div>
                    {exercise_types &&
                      exercise_types.map((category, key) => {
                        if (category) {
                          return (
                            <Col className='mt-2' xs={12} sm={6} md={4} lg={4} xl={4} key={key}>
                              <ProductCard data={category} />
                            </Col>
                          );
                        }
                      })}
                  </div>
                </>
              </div>
            </section>
          </CommonPageData>
        </>
      )}

      <Footer />
    </>
  );
}

const mapStateToProps = state => {
  return {
    aptVideos: state.programReducer.aptVideos,
    program: state.programReducer.program,
    user: state.auth.user,
    exercise_types: state.programReducer.exercise_types,
    ExerciseTypeSuccess: state.programReducer.ExerciseTypeSuccess || null
  }
}
export default connect(mapStateToProps, { getAptVideos, getProgram, getExerciseType })(Education)