import React, { useEffect, useState } from 'react';
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import Footer from "../../Layout/Footer";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { getEducationalVideos, getAptVideos } from "../../../actions/user_program";
import CustomVideoPlayer from '../AssessmentTimer/CustomVideoPlayer';
import Header from "../Home/Header";
import {cross_origin} from "../../../utils/constants";
import { CommonPageData } from '../../../styles/common.style';

function EducationPublic({ getEducationalVideos, educational_video, aptVideos, getAptVideos, user }) {
    const [currentEdPage, setCurrentEdPage] = useState(educational_video ? parseInt(educational_video.meta.current_page) : 1);
    const [itemsEdPerPage, setItemsEdPerPage] = useState(8);
    const [totalEdPages, setEdTotalPages] = useState(0)
    const [bodyTypeVideos, setBodyTypeVideos] = useState(null)

    const [currentPage, setCurrentPage] = useState(aptVideos ? parseInt(aptVideos.meta.current_page) : 1);
    const [itemsPerPage, setItemsPerPage] = useState(8);
    const [totalAptPages, setAptTotalPages] = useState(0)
    const [programEdVideos, setProgramEdVideos] = useState(null)


    useEffect(() => {
        if (user) {
            getEducationalVideos(currentEdPage, itemsEdPerPage, 1);
        }
    }, [currentEdPage, itemsEdPerPage]);

    useEffect(() => {
        getAptVideos(currentPage, itemsPerPage, 1)
    }, [currentPage, itemsPerPage]);


    useEffect(() => {
        setAptTotalPages(aptVideos ? parseInt(aptVideos.meta.last_page) : 1)
        setBodyTypeVideos(aptVideos ? aptVideos.data : [])
    }, [aptVideos]);


    useEffect(() => {
        setEdTotalPages(educational_video ? parseInt(educational_video.meta.last_page) : 1)
        setProgramEdVideos(educational_video ? educational_video.data : [])
    }, [educational_video]);

    // useEffect(() => {
    //     if (educational_video && educational_video != undefined && educational_video != null) {
    //         let exercisesVideos = [];
    //         educational_video.data && educational_video.data.map((exercise) => {
    //             exercise.exerciseEducationVideo && exercise.exerciseEducationVideo.map((educationVideo) => {
    //                 exercisesVideos.push({
    //                     'video': educationVideo.video
    //                 })
    //             })
    //         })
    //         setAptVideos(exercisesVideos)
    //     }
    // }, [educational_video])

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(parseInt(e.target.value));
    };

    const handleItemsEdPerPageChange = (e) => {
        setItemsEdPerPage(parseInt(e.target.value));
    };

    return (
      <>
        <ScrollTopOnMount />
        <Header />
        {/* <div className={"banner-recomended"}>
                <Container className={"h-100"}>
                    <Row className={"h-100"}>
                        <Col className={"col-12 col-lg-6 col-md-6 m-auto"}>
                            <div className="box_banner_inner text-center m-auto">
                                <h3>Educational Videos</h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div> */}
        <div className={'clearfix'}></div>
        <CommonPageData>
          <section className='videos videos-front-public'>
            <div className='common-banner-block'>
              <div className='main-banner'>
                <img src='/images/banner-common-block.png' alt='img' />
              </div>
              <div className='container'>
                <div className='common-banner-block-inner'>
                  <h1> Example Educational Videos</h1>
                </div>
              </div>
            </div>
            <div className='videos-front-public'>
              <div className='container'>
                <div className='row'>
                  <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 '>
                    {/* <div className='videos_headings'>
                    <h2 className='detail_span' style={{ color: '#fff' }}>
                      Example Educational Videos
                    </h2>
                  </div> */}
                    <div className={'Educational-info d-flex flex-column text-start'}>
                      <h3 className={'text-start'}>
                        Each Program Includes:
                      </h3>
                      <ul>
                        <li>Many short educational videos to ensure you understand your Body Type </li>
                        <li>Exercises to avoid (temporarily)</li>
                        <li>Exercises to focus on</li>
                        <li>How to do each exercise and stretch correctly</li>
                        <li>Common exercise errors</li>
                      </ul>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 '>
                    <div className='row'>
                      {bodyTypeVideos &&
                        bodyTypeVideos.map((data, key) => {
                          if (data) {
                            return (
                              <div className='col-12 col-sm-12 col-md-12  col-lg-12 col-xl-12 col-flex-flex-end ' key={key}>
                                <div className='test_videobox-education'>
                                  <div className='check_test'>
                                    <video
                                      className='video'
                                      controls
                                      controlsList='nodownload'
                                      preLoad='auto'
                                      crossOrigin={cross_origin ? 'anonymous' : null}
                                      onContextMenu={(e) => e.preventDefault()}>
                                      <source src={data?.video ? data?.video + '#t=0.001' : ''} type='video/mp4' />
                                    </video>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3'>
                    <div className='pagination'>
                      <span className='itemsPerPagelabel'>Items Per Page:</span>
                      <div className={'perpagedropdown'}>
                        <select id='itemsPerPage' value={itemsPerPage} onChange={handleItemsPerPageChange}>
                          <option value={4}>4</option>
                          <option value={8}>8</option>
                          <option value={12}>12</option>
                          <option value={16}>16</option>
                          <option value={20}>20</option>
                        </select>
                        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                          <path d='M7 10l5 5 5-5z'></path>
                          <path d='M0 0h24v24H0z' fill='none'></path>
                        </svg>
                      </div>
                      <span className='totalpages'>
                        {currentPage}-{totalAptPages} of {totalAptPages}
                      </span>
                      <div className='paginationbuttons'>
                        {currentPage > 1 ? (
                          <button
                            className={'button-previous'}
                            onClick={() => setCurrentPage(currentPage - 1)}
                            disabled={currentPage === 1}>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              aria-hidden='true'
                              role='presentation'>
                              <path d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z'></path>
                              <path d='M0 0h24v24H0z' fill='none'></path>
                            </svg>
                          </button>
                        ) : (
                          ''
                        )}
                        {currentPage < totalAptPages ? (
                          <button
                            className={'button-next'}
                            onClick={() => setCurrentPage(currentPage + 1)}
                            disabled={currentPage === totalAptPages}>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              aria-hidden='true'
                              role='presentation'>
                              <path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'></path>
                              <path d='M0 0h24v24H0z' fill='none'></path>
                            </svg>
                          </button>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/*{programEdVideos && programEdVideos.length > 0 ?*/}
                {/*    <div className="row mt-4 mb-5">*/}
                {/*        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-5">*/}
                {/*            <div className="videos_headings">*/}
                {/*                <h2 className="detail_span" style={{ color: "#fff" }}>EDUCATIONAL VIDEOS IN PROGRAM</h2>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">*/}
                {/*            <div className="row">*/}
                {/*                {programEdVideos && programEdVideos.map((data, key) => {*/}
                {/*                    if (data) {*/}
                {/*                        return (*/}
                {/*                            <div className="col-6 col-sm-6 col-md-6  col-lg-3 col-xl-3 " key={key}>*/}
                {/*                                <div className="test_videobox-education pt-4">*/}
                {/*                                    <div className="check_test pb-2">*/}
                {/*                                        <CustomVideoPlayer videoUrl={data?.video ? data?.video : ''} className={'video'} />*/}
                {/*                                    </div>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        )*/}
                {/*                    }*/}
                {/*                })*/}
                {/*                }*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">*/}
                {/*            <div className="pagination">*/}
                {/*                <span className="itemsPerPagelabel">Items Per Page:</span>*/}
                {/*                <div className={'perpagedropdown'}>*/}
                {/*                    <select id="itemsPerPage" value={itemsEdPerPage} onChange={handleItemsEdPerPageChange}>*/}
                {/*                        <option value={4}>4</option>*/}
                {/*                        <option value={8}>8</option>*/}
                {/*                        <option value={12}>12</option>*/}
                {/*                        <option value={16}>16</option>*/}
                {/*                        <option value={20}>20</option>*/}
                {/*                    </select>*/}
                {/*                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>*/}
                {/*                </div>*/}
                {/*                <span className="totalpages">{currentEdPage}-{totalEdPages} of {totalEdPages}</span>*/}
                {/*                <div className="paginationbuttons">*/}
                {/*                    {currentEdPage > 1 ? <button*/}
                {/*                        className={'button-previous'}*/}
                {/*                        onClick={() => setCurrentEdPage(currentEdPage - 1)}*/}
                {/*                        disabled={currentEdPage === 1}*/}
                {/*                    >*/}
                {/*                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>*/}
                {/*                    </button> : ''}*/}
                {/*                    {currentEdPage < totalEdPages ? <button*/}
                {/*                        className={'button-next'}*/}
                {/*                        onClick={() => setCurrentEdPage(currentEdPage + 1)}*/}
                {/*                        disabled={currentEdPage === totalEdPages}*/}
                {/*                    >*/}
                {/*                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>*/}
                {/*                    </button> : ''}*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    : ''}*/}
              </div>
            </div>
          </section>
        </CommonPageData>
        <Footer />
      </>
    );
}

const mapStateToProps = state => {
    return {
        educational_video: state.programReducer.educational_video,
        aptVideos: state.programReducer.aptVideos,
        user: state.auth.user
    }
}
export default connect(mapStateToProps, { getEducationalVideos, getAptVideos })(EducationPublic)