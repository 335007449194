import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
import { connect } from "react-redux";
import EducationVideoModal from "../EducationalVideoModal";
import { getNextArrayElement } from "../../../utils/helpers";
import { getSkipReasons, submitTest } from "../../../actions/user_test";
import usePrevious from "../../Layout/usePrevious";
import TestCompleteModal from "../TestCompleteModal";
import SkipTestModal from "./SkipTestModal";
import { useForm } from "react-hook-form";
import CustomVideoPlayer from "../AssessmentTimer/CustomVideoPlayer";
import { CommonPageData } from '../../../styles/common.style';
const TestDetails = ({
  testDetails,
  questionDetails,
  getSkipReasons,
  getQuestion,
  submitTest,
  submitTestRequest,
  submitTestSuccess,
  skipTestSuccess,
  skipTestReason,
}) => {
  let { id } = useParams();
  const navigate = useNavigate();
  let prevSubmitTestSuccess = usePrevious({ submitTestSuccess });
  let prevSkipTestSuccess = usePrevious({ skipTestSuccess });
  const [showEducationalModal, setShowEducationalModal] = useState(false);
  const [showTestCompleteModal, setShowTestCompleteModal] = useState(false);
  const [showTestSkipModal, setShowTestSkipModal] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });
  const handleShow = () => setShowEducationalModal(true);
  const handleClose = () => setShowEducationalModal(false);
  const handleCloseTestCompleteModal = () => setShowTestCompleteModal(false);
  const handleCloseTestSkipModal = () => setShowTestSkipModal(false);
  let selectedTests = localStorage.getItem("selectedTests")
    ? JSON.parse(localStorage.getItem("selectedTests"))
    : [];
  let nextTest = getNextArrayElement(selectedTests, parseInt(id));

  // useEffect(() => {
  //     if (testDetails) {
  //         // getQuestion(testDetails.question.id)
  //     }
  // }, [testDetails, id])

  useEffect(() => {
    if (!skipTestReason) {
      getSkipReasons();
    }
  }, []);

  const onSubmit = async (data) => {
    let submitData = {
      test_feedback_option_id: testDetails?.testFeedbackOption[0]?.id || "",
      reason_text: selectedOptions,
      note: data.note,
      test_id: id,
    };
    submitTest(submitData);
  };

  if (
    prevSubmitTestSuccess &&
    !prevSubmitTestSuccess.submitTestSuccess &&
    submitTestSuccess
  ) {
    if (nextTest) {
      navigate(`/assessment-test/${nextTest}`, { replace: true });
    } else {
      if (!showTestCompleteModal) {
        setShowTestCompleteModal(true);
      }
    }
  }

  if (
    prevSkipTestSuccess &&
    !prevSkipTestSuccess?.skipTestSuccess &&
    skipTestSuccess
  ) {
    if (nextTest) {
      console.log("Next test available. Navigating to the next test...");
      navigate(`/assessment-test/${nextTest}`, { replace: true });
    } else {
      if (!showTestCompleteModal) {
        setShowTestCompleteModal(true);
        setShowTestSkipModal(false);
      }
    }
  }

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      // If checkbox is checked, add the value to the string
      setSelectedOptions((prevSelected) =>
        prevSelected ? prevSelected + "," + value : value
      );
    } else {
      // If checkbox is unchecked, remove the value from the string
      setSelectedOptions((prevSelected) =>
        prevSelected
          ? prevSelected
              .split(",")
              .filter((item) => item !== value)
              .join(",")
          : ""
      );
    }
  };

  if (!testDetails) {
    return (
      <section className="test1_001">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12 col-md-12">
              <div className="loader_custom">
                <FadeLoader color={"#FB1417"} height={10} />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <CommonPageData>
      <section className='test1_001'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-lg-6 col-md-6  '>
              <h3 className='test-title-block'>{testDetails?.title}</h3>
              <div className=' ssa box_video' style={{ textAlign: 'center' }} data-click='0'>
                <CustomVideoPlayer videoUrl={testDetails?.video} className={'video'} />
              </div>
              {/* {testDetails && testDetails.testEducationalVideos ? (
                <div className='education'>
                  <Link
                    to='#'
                    className='edu popup-youtube-custom'
                    onClick={(e) => {
                      e.preventDefault();
                      handleShow();
                    }}>
                    <svg width='28' height='24' viewBox='0 0 26 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M25.7266 5.55698C25.5718 5.86026 25.3072 6.00867 24.991 6.11837C21.2355 7.44118 17.48 8.7769 13.7309 10.1126C13.4664 10.2094 13.2276 10.203 12.9566 10.1126C9.1624 8.75755 5.36172 7.41537 1.56104 6.0732C1.28357 5.97641 1.1029 5.79573 0.960938 5.56343C0.960938 5.42147 0.960938 5.27305 0.960938 5.13109C1.01256 4.87298 1.18678 4.71812 1.41908 4.61487C1.76753 4.46001 2.12889 4.35676 2.49024 4.22771C5.16814 3.2856 7.83958 2.3435 10.5175 1.40785C11.3176 1.13038 12.1113 0.846462 12.9114 0.562541C13.2083 0.459296 13.4922 0.459296 13.7826 0.562541C16.0346 1.34978 18.2801 2.14347 20.5322 2.93716C21.9905 3.45338 23.4488 3.96314 24.9071 4.47936C25.0233 4.51808 25.133 4.5568 25.2491 4.60842C25.5072 4.72457 25.7073 4.87944 25.7331 5.18271C25.7266 5.30532 25.7266 5.43437 25.7266 5.55698Z'
                        fill='rgba(7, 72, 184, 0.4)'
                      />
                      <path
                        d='M6.05886 10.8741C6.05886 10.4095 6.05886 9.93841 6.05886 9.47381C6.05886 9.20279 6.05886 9.2028 6.31051 9.28668C8.36249 10.0158 10.4145 10.7386 12.46 11.4742C13.0601 11.6871 13.6344 11.6871 14.2281 11.4742C16.2736 10.7386 18.332 10.0158 20.3775 9.28023C20.5647 9.20925 20.6356 9.2157 20.6292 9.448C20.6163 10.3707 20.6163 11.2999 20.6292 12.2227C20.6356 12.7131 20.4485 13.0938 20.0807 13.4035C19.4871 13.9004 18.7837 14.1714 18.0545 14.3844C16.0284 14.9909 13.9635 15.1458 11.8599 14.978C10.4338 14.8619 9.04003 14.6038 7.71076 14.0553C7.29133 13.881 6.89772 13.6681 6.55572 13.3648C6.22018 13.068 6.0395 12.7002 6.0524 12.242C6.06531 11.7904 6.05886 11.3322 6.05886 10.8741Z'
                        fill='rgba(7, 72, 184, 0.4)'
                      />
                      <path
                        d='M23.9849 8.00903C23.9849 9.20279 23.9849 10.3707 23.9849 11.5387C23.9849 12.3969 23.9914 13.2616 23.9785 14.1198C23.9785 14.2618 24.0172 14.3456 24.1333 14.436C24.9141 15.0425 24.9077 16.1653 24.114 16.7525C23.9978 16.8429 23.9785 16.9332 23.9785 17.0558C23.9785 18.1657 23.9849 19.2756 23.9785 20.3854C23.9785 20.7274 23.772 21.0049 23.4687 21.1017C23.1719 21.192 22.8557 21.0888 22.675 20.8436C22.5653 20.6952 22.5266 20.5274 22.5266 20.3467C22.5266 19.2497 22.5266 18.1592 22.5331 17.0623C22.5331 16.9203 22.5008 16.8364 22.3782 16.7461C21.6168 16.1718 21.6039 15.0425 22.3588 14.4618C22.5008 14.3521 22.5331 14.2489 22.5331 14.0811C22.5266 12.313 22.5331 10.545 22.5266 8.77046C22.5266 8.57042 22.5911 8.49944 22.7718 8.44137C23.1654 8.31231 23.5591 8.1639 23.9849 8.00903Z'
                        fill='rgba(7, 72, 184, 0.4)'
                      />
                    </svg>
                    <h6>Educational Video</h6>
                  </Link>
                </div>
              ) : (
                ''
              )} */}
            </div>
            <div className='col-12 col-lg-6 col-md-6 test_one'>
              <div className='box_test'>
                {/* <h4>Feedback</h4> */}
                <h3 className='test-title-block'>Feedback</h3>
                <Form
                  onSubmit={handleSubmit(onSubmit)}
                  className='formSubmitTest common-box-bg-all'
                  id='formSubmitTest'
                  autoComplete='off'
                  role='presentation'>
                  {testDetails.testFeedbackOption &&
                    testDetails?.testFeedbackOption?.map((item, key) => {
                      return (
                        <Form.Group key={key} className='form-group checkbox_custom '>
                          <Form.Control
                            type='checkbox'
                            id={'check-' + key}
                            name='answer_id'
                            value={item.option}
                            {...register('answer_id', {
                              required: {
                                value: 'required',
                                message: 'Please select at least one option'
                              },
                              onChange: handleCheckboxChange
                            })}
                          />
                          <Form.Label className='' htmlFor={'check-' + key}>
                            {item.option}
                          </Form.Label>
                        </Form.Group>
                      );
                    })}
                  {errors.answer_id && (
                    <Form.Text className=' validationText hasError mb-1'>{errors.answer_id.message}</Form.Text>
                  )}
                  {/* <span className="show-error">{validationErrors['answer_id']}</span> */}
                  <Form.Group className='form-group text-area'>
                    <Form.Label>
                      <img src='/images/pencil.png' className='icon_pen' alt='Pencil Icon' />
                      <span> Notes</span>
                    </Form.Label>
                    <textarea
                      className='form-control form-2 text-left'
                      rows='5'
                      maxLength='500'
                      name='note'
                      id='note'
                      {...register('note', {
                        maxLength: {
                          value: 500,
                          message: 'Max length is 500'
                        }
                      })}></textarea>
                    {errors.note && <Form.Text className=' validationText hasError'>{errors.note.message}</Form.Text>}
                  </Form.Group>
                  <div className='border-b'>
                    {submitTestRequest ? (
                      <div className='loader_custom'>
                        <FadeLoader color={'#FB1417'} height={10} />
                      </div>
                    ) : (
                      <>
                        <input
                          type='submit'
                          className='input-btn theme-common-btn-small'
                          value={nextTest ? 'Next' : 'Continue'}
                        />
                        <Link
                          to='#'
                          className='theme-btn-link-black'
                          onClick={() => {
                            setShowTestSkipModal(true);
                          }}>
                          Skip for one week
                        </Link>
                      </>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <EducationVideoModal
          show={showEducationalModal}
          handleClose={handleClose}
          testVideos={testDetails ? testDetails.testEducationalVideos : null}
        />
        <TestCompleteModal show={showTestCompleteModal} handleClose={handleCloseTestCompleteModal} />
        <SkipTestModal
          show={showTestSkipModal}
          handleClose={handleCloseTestSkipModal}
          skipTestReasons={skipTestReason ? skipTestReason : null}
        />
      </section>
    </CommonPageData>
  );
};

const mapStateToProps = (state) => ({
  program: state.programReducer.program,
  questionDetails: state.programReducer.questionDetails,
  submitTestSuccess: state.testReducer.submitTestSuccess,
  submitTestRequest: state.testReducer.submitTestRequest,
  skipTestSuccess: state.testReducer.skipTestSuccess,
  skipTestReason: state.testReducer.skipTestReason,
});

export default connect(mapStateToProps, { submitTest, getSkipReasons })(
  TestDetails
);
