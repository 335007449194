import React from 'react'
import Men from '../../images/mens_new.png'

const Home11 = () => {
  return (
    //     <section className="home_0011">
    //     <div className="container">
    //         <div className="row">
    //             <div className="col-12 col-lg-6 col-md-8">
    //                 <div className="sec01 pb-50">
    //                     <h2>Your Body Type</h2>
    //                     <p>Our assessment may identify</p>
    //                     <p>Most people’s imbalances fall into 3 different body types.</p>
    //                     <p className="p-small">(which may include common variations)</p>
    //                 </div>
    //                 <div className="sec01">
    //                     <h2>Each body type
    //                         has similar:</h2>
    //                     <p>-Over-active (dominant) muscles</p>
    //                     <p>-Under-activate (lengthened) muscles</p>
    //
    //                 </div>
    //             </div>
    //
    //         </div>
    //     </div>
    // </section>

    <section className='home_0011 pt-4 p-4' style={{ display: 'none' }}>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center pt-5 pb-5 '>
            <div className='body_content'>
              <h3>
                "The body can help show us what it needs <br />
                if we just look at it from different angles and movements."
              </h3>
              <div className='angela'>
                <p>Angela Lavery</p>
              </div>
              <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mx-auto pt-4 pb-4'>
                <img src={Men} className='img-fluid' />
              </div>

              <p>
                Most people’s imbalances fall into common patterns that we call <br />
                <a href='javascript:void(0)'>BODY TYPES</a>
              </p>
            </div>
            <div className='row'>
              <div className='col-12 col-sm-12 col-md-8 offset-md-2 col-lg-8 offset-lg-2 col-xl-8 offset-xl-2 pt-4'>
                <div className='row'>
                  <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 '>
                    <div className='Body_type'>
                      <p>
                        Our assessment can help you identify your <span className='type_span'>BODY TYPE</span>
                      </p>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                    <div className='body_list'>
                      <h4>
                        Each <span className='body_span'>BODY TYPE</span> has similar
                      </h4>
                      <i className='fa fa-check text-white' aria-hidden='true'></i>
                      <span className='m-0'>Over-active (dominant) muscles</span>
                      <br />
                      <i className='fa fa-check text-white' aria-hidden='true'></i>
                      <span className='m-0'>Under-active (passive) muscles</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home11