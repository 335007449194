import React, { Navigate, useEffect } from "react";
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import { useNavigate } from "react-router-dom";

import { CommonPageData } from '../../../styles/common.style';
const AssessmentOverview = () => {
  let navigate = useNavigate();

  return (
    <>
      <Header />
      <CommonPageData>
        <div className='common-banner-block'>
          <div className='main-banner'>
            <img src='/images/banner-common-block.png' alt='img' />
          </div>
          <div className='container'>
            <div className='common-banner-block-inner'>
              <h1>Assessment Overview</h1>
            </div>
          </div>
        </div>
        <section className='assessment-block'>
          <div className='container'>
            <div className='col-12 col-lg-12 col-md-12'>
              <div className='assessment-block-inner'>
                <h3>Welcome to Body Solutions Assessment!</h3>
                <div className='assessment-block-inner-video'>
                  <video className='video' controls controlsList='nodownload' preLoad='auto'>
                    <source
                      src='https://d1rs42xew6aaeb.cloudfront.net/1. Program Management/1. General Videos (not body type specific)/Welcome Video TEMP COM.mp4#t=0.001'
                      type='video/mp4'
                    />
                  </video>
                </div>
                <h3>
                  This video will walk you through the Body Solutions assessment process. It should take you about 30
                  minutes to complete.
                </h3>
                <div className='btn-assessment'>
                  <button class='theme-common-btn-big ' onClick={() => navigate('/assessment-clothing')}>
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </CommonPageData>
      {/* <p>Assessment Overview</p> */}
      <Footer />
    </>
  );
};

export default AssessmentOverview

