import React, { useEffect, useState } from "react";
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Home/Header";
import Header2 from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { getPainCategories, getPainPoint } from "../../../actions/user_program";
import { connect } from "react-redux";

import front_side_1 from "../../images/front_side/1.jpg";
import front_side_2 from "../../images/front_side/2.jpg";
import front_side_3 from "../../images/front_side/3.jpg";
import front_side_4 from "../../images/front_side/4.jpg";
import front_side_5 from "../../images/front_side/5.jpg";
import front_side_6 from "../../images/front_side/6.jpg";
import front_side_7 from "../../images/front_side/7.jpg";
import front_side_8 from "../../images/front_side/8.jpg";
import front_side_9 from "../../images/front_side/9.jpg";
import front_side_10 from "../../images/front_side/10.jpg";
import front_side_11 from "../../images/front_side/11.jpg";

import back_side_1 from "../../images/back_side/1.jpg";
import back_side_2 from "../../images/back_side/2.jpg";
import back_side_3 from "../../images/back_side/3.jpg";
import back_side_4 from "../../images/back_side/4.jpg";
import back_side_5 from "../../images/back_side/5.jpg";
import back_side_6 from "../../images/back_side/6.jpg";
import back_side_7 from "../../images/back_side/7.jpg";
import back_side_8 from "../../images/back_side/8.jpg";
import back_side_9 from "../../images/back_side/9.jpg";
import back_side_10 from "../../images/back_side/10.jpg";
import back_side_11 from "../../images/back_side/11.jpg";

import FadeLoader from "react-spinners/FadeLoader";
import { cross_origin } from "../../../utils/constants";
import { CommonPageData } from '../../../styles/common.style';
const TriggerCategory = ({
  getPainCategories,
  painCategories,
  getPainPoint,
  pain_point,
}) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(false);
  const [loader, setLoader] = useState(true);
  const [musclesAnatomyData, setMusclesAnatomyData] = useState([]);

  const [painPointData, setPainPointData] = useState(null);

  const [currentPage, setCurrentPage] = useState(
    pain_point ? parseInt(pain_point.meta.current_page) : 1
  );
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [keyword, setKeyword] = useState(null);

  useEffect(() => {
    getPainPoint(currentPage, itemsPerPage, 1, keyword);
  }, [currentPage, itemsPerPage, keyword]);

  useEffect(() => {
    setTotalPages(pain_point ? parseInt(pain_point.meta.last_page) : 1);
    if (pain_point?.data) {
      setLoader(false);
    }
    setPainPointData(pain_point ? pain_point.data : []);
    // setPainPointData(array)
  }, [pain_point]);

  useEffect(() => {
    setTimeout(() => {
      const topElement = document.querySelector(".trigger-banner-recommended"); // Replace 'top-element' with the actual ID of your top element
      if (topElement) {
        topElement.scrollIntoView({
          behavior: "smooth",
          block: "start", // Scroll to the top of the element
        });
      }
    }, 2000);
  }, []);

  const handleItemsPerPageChange = (e) => {
    setLoader(true);
    setItemsPerPage(parseInt(e.target.value));
  };

  const handleSearchClick = () => {
    getPainPoint(currentPage, itemsPerPage, 1, keyword);
    setLoader(true);
    setCurrentPage(1);
  };
  let serchKey = "";
  const handleSearch = (value) => {
    console.log("value-->", value);
    console.log("keyword-->>", keyword);
    serchKey = value;
    console.log("serchKey-->", serchKey);

    // setVal(true);
    setKeyword(value);
    if (keyword && value.length == 0) {
      getPainPoint(currentPage, itemsPerPage, 1, value);
    }
  };

  useEffect(() => {
    getPainCategories();
  }, []);

  useEffect(() => {
    if (painCategories) {
      setLoader(false);
    }
    setMusclesAnatomyData(painCategories ? painCategories : []);
  }, [painCategories]);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setUser(true);
    } else {
      setUser(false);
    }
  }, []);

  const [frontBodyPartValue, setFrontBodyPartValue] = useState(front_side_1);
  const [backBodyPartValue, setBackBodyPartValue] = useState(back_side_1);

  const handleMouseEnter = (frontArea, backArea) => {
    setFrontBodyPartValue(frontArea);
    setBackBodyPartValue(backArea);
  };

  const handleMouseLeave = () => {
    setFrontBodyPartValue(front_side_1);
    setBackBodyPartValue(back_side_1);
  };

  const [step, setStep] = useState(0);

  const toggleVisibility = () => {
    setStep((prev) => (prev === 1 ? 0 : prev + 1)); // Cycle through 0, 1, and 2
  };

  const onClickBodyParts = (item) => {
    if (musclesAnatomyData.length > 0) {
      musclesAnatomyData.map((itemData) => {
        if (itemData.name == item) {
          navigate(`/trigger-categories/${itemData.id}`, {
            state: { pain_point_data: itemData },
          });
        }
      });
    }
  };

  const frontBodyPart = () => {
    return (
      <div style={{ position: "relative", width: "350px" }}>
        <img src={frontBodyPartValue} alt="Body" style={{ width: "100%" }} />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Head and Neck Front")}
          style={{
            top: "3%",
            left: "39%",
            width: "21%",
            height: "14%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_2, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Head and Neck Front")}
          style={{
            top: "17%",
            left: "32%",
            width: "36%",
            height: "3%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_2, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Shoulder and Arm Front")}
          style={{
            top: "18%",
            left: "19%",
            width: "13%",
            height: "16%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_3, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Shoulder and Arm Front")}
          style={{
            top: "18%",
            right: "19%",
            width: "13%",
            height: "16%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_3, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Elbow Front")}
          style={{
            top: "34%",
            left: "17%",
            width: "11%",
            height: "3.2%",
            transform: "rotate(6deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_4, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Elbow Front")}
          style={{
            top: "34%",
            right: "19%",
            width: "11%",
            height: "3.2%",
            transform: "rotate(-6deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_4, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Forearm wrist and hand front")}
          style={{
            top: "37%",
            left: "15%",
            width: "12%",
            height: "11%",
            transform: "rotate(6deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_5, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Forearm wrist and hand front")}
          style={{
            top: "47.5%",
            left: "8.5%",
            width: "14.5%",
            height: "10.5%",
            transform: "rotate(6deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_5, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Forearm wrist and hand front")}
          style={{
            top: "47.5%",
            right: "6.5%",
            width: "14.5%",
            height: "10.5%",
            transform: "rotate(-6deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_5, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Forearm wrist and hand front")}
          style={{
            top: "37%",
            right: "15%",
            width: "12%",
            height: "11%",
            transform: "rotate(-8deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_5, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Chest")}
          style={{
            top: "20%",
            left: "32%",
            width: "35%",
            height: "9%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_6, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Abdomen")}
          style={{
            top: "29%",
            left: "32%",
            width: "35%",
            height: "13%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_7, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Pelvis Front")}
          style={{
            top: "42%",
            left: "32%",
            width: "36%",
            height: "10%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_8, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Upper Leg Front")}
          style={{
            top: "52%",
            left: "30%",
            width: "19%",
            height: "10%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_9, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Upper Leg Front")}
          style={{
            top: "52%",
            right: "31%",
            width: "19%",
            height: "10%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_9, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Upper Leg Front")}
          style={{
            top: "62%",
            left: "32%",
            width: "17%",
            height: "6.5%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_9, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Upper Leg Front")}
          style={{
            top: "62%",
            right: "33%",
            width: "17%",
            height: "6.5%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_9, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Knees Front")}
          style={{
            top: "68.5%",
            left: "35%",
            width: "13%",
            height: "6.5%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_10, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Knees Front")}
          style={{
            top: "68.5%",
            right: "36%",
            width: "13%",
            height: "6.5%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_10, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Lower Legs and Feet front")}
          style={{
            top: "75%",
            left: "35%",
            width: "13%",
            height: "12%",
            transform: "rotate(-3deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_11, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Lower Legs and Feet front")}
          style={{
            top: "75%",
            right: "36%",
            width: "13%",
            height: "12%",
            transform: "rotate(3deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_11, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Lower Legs and Feet front")}
          style={{
            top: "86.8%",
            left: "39%",
            width: "8%",
            height: "7%",
            transform: "rotate(-3deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_11, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Lower Legs and Feet front")}
          style={{
            top: "86.8%",
            right: "40%",
            width: "8%",
            height: "7%",
            transform: "rotate(3deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_11, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Lower Legs and Feet front")}
          style={{
            top: "93.6%",
            left: "36%",
            width: "11%",
            height: "4%",
            transform: "rotate(-3deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_11, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Lower Legs and Feet front")}
          style={{
            top: "93.6%",
            right: "37%",
            width: "11%",
            height: "4%",
            transform: "rotate(3deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_11, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />
      </div>
    );
  };

  const backBodyPart = () => {
    return (
      <div
        style={{
          position: "relative",
          width: "350px",
          borderWidth: 1,
          borderColor: "black",
        }}
      >
        <img src={backBodyPartValue} alt="backBody" style={{ width: "100%" }} />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Head and Neck Back")}
          style={{
            top: "2%",
            left: "38.7%",
            width: "21.1%",
            height: "15%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_2)}
          onMouseLeave={handleMouseLeave}
        />
        {/* <div
          style={{
            position: "absolute",
            top: "17%",
            left: "32%",
            width: "36%",
            height: "3%",
            cursor: "pointer",
            backgroundColor: "rgba(255, 255, 0, 0.6)",
          }}
          onMouseEnter={() => handleMouseEnter(back_side_2)}
          onMouseLeave={handleMouseLeave}
        /> */}
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Shoulder and Arm Back")}
          style={{
            top: "18%",
            left: "19%",
            width: "13%",
            height: "16%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_3)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Shoulder and Arm Back")}
          style={{
            top: "18%",
            right: "19%",
            width: "13%",
            height: "16%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_3)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Elbows Back")}
          style={{
            top: "34%",
            left: "17%",
            width: "11%",
            height: "3.2%",
            transform: "rotate(6deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_4)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Elbows Back")}
          style={{
            position: "absolute",
            top: "34%",
            right: "19%",
            width: "11%",
            height: "3.2%",
            cursor: "pointer",
            transform: "rotate(-6deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_4)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Forearm wrist and hand back")}
          style={{
            top: "37%",
            left: "13%",
            width: "12%",
            height: "11%",
            transform: "rotate(6deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_5)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Forearm wrist and hand back")}
          style={{
            top: "37%",
            right: "15%",
            width: "12%",
            height: "11%",
            transform: "rotate(-8deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_5)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Forearm wrist and hand back")}
          style={{
            top: "47.5%",
            left: "5.5%",
            width: "14.5%",
            height: "10.5%",
            transform: "rotate(6deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_5)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Forearm wrist and hand back")}
          style={{
            top: "47.5%",
            right: "8.5%",
            width: "14.5%",
            height: "10.5%",
            transform: "rotate(-6deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_5)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Upper Back")}
          style={{
            top: "15%",
            left: "38%",
            width: "22%",
            height: "1.5%",
            borderTopLeftRadius: "70%",
            borderTopRightRadius: "70%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_6)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Upper Back")}
          style={{
            top: "16.5%",
            left: "30%",
            width: "38%",
            height: "15.5%",
            borderTopLeftRadius: "25%",
            borderTopRightRadius: "25%",
            borderBottomLeftRadius: "16%",
            borderBottomRightRadius: "16%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_6)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Lower Back")}
          style={{
            top: "31%",
            left: "32%",
            width: "35%",
            height: "11%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_7)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Pelvis Back")}
          style={{
            top: "42%",
            left: "32%",
            width: "36%",
            height: "9.5%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_8)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Upper Leg Back")}
          style={{
            top: "51.5%",
            left: "30%",
            width: "19%",
            height: "10%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_9)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Upper Leg Back")}
          style={{
            top: "51.5%",
            right: "31%",
            width: "19%",
            height: "10%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_9)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Upper Leg Back")}
          style={{
            top: "61.5%",
            left: "32%",
            width: "17%",
            height: "8%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_9)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Upper Leg Back")}
          style={{
            top: "61.5%",
            right: "33%",
            width: "17%",
            height: "8%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_9)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Knees Back")}
          style={{
            top: "69.5%",
            left: "35%",
            width: "13%",
            height: "5.5%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_10)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Knees Back")}
          style={{
            top: "69.5%",
            right: "36%",
            width: "13%",
            height: "5.5%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_10)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Lower Legs and feet back")}
          style={{
            top: "75%",
            left: "35%",
            width: "13%",
            height: "12%",
            transform: "rotate(-3deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_11)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Lower Legs and feet back")}
          style={{
            top: "75%",
            right: "36%",
            width: "13%",
            height: "12%",
            transform: "rotate(3deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_11)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Lower Legs and feet back")}
          style={{
            top: "86.8%",
            left: "39%",
            width: "8%",
            height: "7%",
            transform: "rotate(-3deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_11)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Lower Legs and feet back")}
          style={{
            top: "86.8%",
            right: "40%",
            width: "8%",
            height: "7%",
            transform: "rotate(3deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_11)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Lower Legs and feet back")}
          style={{
            top: "93.6%",
            left: "36%",
            width: "11%",
            height: "2.5%",
            transform: "rotate(-3deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_11)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Lower Legs and feet back")}
          style={{
            top: "93.6%",
            right: "37%",
            width: "11%",
            height: "2.5%",
            transform: "rotate(3deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_11)}
          onMouseLeave={handleMouseLeave}
        />
      </div>
    );
  };

  return (
    <div>
      <ScrollTopOnMount />
      {user ? <Header2 /> : <Header />}
      <div className={'clearfix'}></div>
      <CommonPageData>
        <div className='common-banner-block'>
          <div className='main-banner'>
            <img src='/images/banner-common-block.png' alt='img' />
          </div>
          <div className='container'>
            <div className='common-banner-block-inner'>
              <h1>Trigger Point Library</h1>
            </div>
          </div>
        </div>
        <section className={'recomended-products'}>
          <Container>
            <Row>
              {/* <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 '>
                <div className='videos_headings'>
                  <h2 className='detail_span' style={{ color: '#fff' }}>
                    TRIGGER POINT LIBRARY
                  </h2>
                </div>
              </div> */}
              <div className='search-block-custom'>
                <Form className='' onSubmit={(e) => e.preventDefault()}>
                  <Form.Control
                    value={keyword}
                    type='search'
                    placeholder='Search'
                    className='me-2 rounded-pill'
                    aria-label='Search'
                    onChange={(e) => {
                      handleSearch(e.target.value);
                      handleSearchClick(e.target.value);
                    }}
                  />
                  <button>
                    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M15.2713 14.9121L12.357 12.0064C13.2973 10.8085 13.8075 9.32921 13.8056 7.80636C13.8056 6.45015 13.4034 5.12439 12.65 3.99674C11.8965 2.86909 10.8255 1.99019 9.57257 1.47119C8.31959 0.95219 6.94085 0.816396 5.61069 1.08098C4.28054 1.34556 3.05871 1.99864 2.09972 2.95763C1.14073 3.91662 0.487654 5.13845 0.22307 6.4686C-0.0415146 7.79876 0.0942797 9.1775 0.61328 10.4305C1.13228 11.6835 2.01118 12.7544 3.13883 13.5079C4.26648 14.2613 5.59224 14.6635 6.94845 14.6635C8.4713 14.6654 9.95056 14.1552 11.1485 13.2149L14.0542 16.1292C14.1339 16.2096 14.2287 16.2733 14.3331 16.3168C14.4376 16.3604 14.5496 16.3828 14.6627 16.3828C14.7759 16.3828 14.8879 16.3604 14.9924 16.3168C15.0968 16.2733 15.1916 16.2096 15.2713 16.1292C15.3516 16.0495 15.4154 15.9547 15.4589 15.8503C15.5024 15.7458 15.5249 15.6338 15.5249 15.5206C15.5249 15.4075 15.5024 15.2955 15.4589 15.191C15.4154 15.0866 15.3516 14.9918 15.2713 14.9121ZM1.8056 7.80636C1.8056 6.7892 2.10722 5.79488 2.67232 4.94915C3.23743 4.10341 4.04063 3.44423 4.98037 3.05498C5.9201 2.66573 6.95416 2.56389 7.95178 2.76233C8.94939 2.96076 9.86576 3.45057 10.585 4.16982C11.3042 4.88906 11.7941 5.80543 11.9925 6.80304C12.1909 7.80066 12.0891 8.83472 11.6998 9.77445C11.3106 10.7142 10.6514 11.5174 9.80567 12.0825C8.95993 12.6476 7.96561 12.9492 6.94845 12.9492C5.58448 12.9492 4.27638 12.4074 3.31191 11.4429C2.34743 10.4784 1.8056 9.17033 1.8056 7.80636Z'
                        fill='black'
                      />
                    </svg>
                  </button>
                </Form>
              </div>

              {keyword ? (
                <>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 '>
                    <div className={'Educational-info d-flex flex-column text-start mb-3'}>
                      <h3 className={'text-center'}>
                        <p>Search by pain area (i.e. knee, hip, low back, etc.)</p>
                      </h3>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 '>
                    <hr />
                    <div className='row'>
                      {loader ? (
                        <div className='col-12 col-sm-12 col-md-12  col-lg-12 col-xl-12 '>
                          <div className={'empty-page'}>
                            <div className='spin-loader'>
                              <FadeLoader color={'#EC1246'} height={10} />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          {painPointData?.length > 0 ? (
                            painPointData.map((data, key) => {
                              if (data) {
                                return (
                                  <div
                                    className='col-6 col-sm-6 col-md-3  col-lg-2 col-xl-2 common-box-height'
                                    key={key}>
                                    <Link
                                      className={'nav-link library-nav'}
                                      data-toggle='tab'
                                      to={'/trigger-pain-point/detail/' + data?.id}
                                      role='tab'>
                                      <div className='library-img-container'>
                                        <div className='library-img-container-inner'>
                                          <div className='title-one'>{data?.title}</div>
                                          <div className='title-two'>{data?.title2}</div>
                                          <div />
                                        </div>
                                        <div className='library-img-box'>
                                          <img
                                            src={data?.image ? data?.image : ''}
                                            alt={data?.title ? data?.title : ''}
                                            crossOrigin={cross_origin ? 'anonymous' : null}
                                          />
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                );
                              }
                            })
                          ) : (
                            <div className='col-12 col-sm-12 col-md-12  col-lg-12 col-xl-12 '>
                              <div className={'library-empty-page'}>
                                <span className='itemsPerPagelabel'>Not Found</span>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  {painPointData?.length > 0 && (
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3'>
                      <div className='pagination'>
                        <span className='itemsPerPagelabel'>Items Per Page:</span>
                        <div className={'perpagedropdown'}>
                          <select id='itemsPerPage' value={itemsPerPage} onChange={handleItemsPerPageChange}>
                            <option value={4}>4</option>
                            <option value={8}>8</option>
                            <option value={12}>12</option>
                            <option value={16}>16</option>
                            <option value={20}>20</option>
                          </select>
                          <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                            <path d='M7 10l5 5 5-5z'></path>
                            <path d='M0 0h24v24H0z' fill='none'></path>
                          </svg>
                        </div>
                        <span className='totalpages'>
                          {currentPage}-{totalPages} of {totalPages}
                        </span>
                        <div className='paginationbuttons'>
                          {currentPage > 1 ? (
                            <button
                              className={'button-previous'}
                              onClick={() => {
                                setCurrentPage(currentPage - 1);
                                setLoader(true);
                              }}
                              disabled={currentPage === 1}>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                aria-hidden='true'
                                role='presentation'>
                                <path d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z'></path>
                                <path d='M0 0h24v24H0z' fill='none'></path>
                              </svg>
                            </button>
                          ) : (
                            ''
                          )}
                          {currentPage < totalPages ? (
                            <button
                              className={'button-next'}
                              onClick={() => {
                                setCurrentPage(currentPage + 1);
                                setLoader(true);
                              }}
                              disabled={currentPage === totalPages}>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                aria-hidden='true'
                                role='presentation'>
                                <path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'></path>
                                <path d='M0 0h24v24H0z' fill='none'></path>
                              </svg>
                            </button>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className='human-body-container'>
                  <div className='human-body-container-inner'>
                    <h3>Select Pain Area</h3>
                    <button onClick={toggleVisibility}>
                      <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <circle cx='40' cy='40' r='40' fill='#C00000' />
                        <path
                          d='M53.3838 25.884C53.6181 25.6496 53.7497 25.3317 53.7497 25.0003C53.7497 24.6688 53.6181 24.3509 53.3838 24.1165L45.8838 16.6165C45.648 16.3888 45.3322 16.2628 45.0045 16.2657C44.6768 16.2685 44.3632 16.4 44.1315 16.6317C43.8997 16.8635 43.7683 17.177 43.7654 17.5048C43.7626 17.8325 43.8886 18.1483 44.1163 18.384L49.4825 23.7503H27.5C27.1685 23.7503 26.8505 23.882 26.6161 24.1164C26.3817 24.3508 26.25 24.6687 26.25 25.0003C26.25 25.3318 26.3817 25.6497 26.6161 25.8841C26.8505 26.1186 27.1685 26.2503 27.5 26.2503H49.4825L44.1163 31.6165C43.8886 31.8523 43.7626 32.168 43.7654 32.4958C43.7683 32.8235 43.8997 33.137 44.1315 33.3688C44.3632 33.6005 44.6768 33.732 45.0045 33.7349C45.3322 33.7377 45.648 33.6117 45.8838 33.384L53.3838 25.884Z'
                          fill='white'
                        />
                        <path
                          d='M26.6162 55.884C26.3819 55.6496 26.2503 55.3317 26.2503 55.0003C26.2503 54.6688 26.3819 54.3509 26.6162 54.1165L34.1162 46.6165C34.352 46.3888 34.6678 46.2628 34.9955 46.2657C35.3232 46.2685 35.6368 46.4 35.8685 46.6317C36.1003 46.8635 36.2317 47.177 36.2346 47.5048C36.2374 47.8325 36.1114 48.1483 35.8837 48.384L30.5175 53.7503H52.5C52.8315 53.7503 53.1495 53.882 53.3839 54.1164C53.6183 54.3508 53.75 54.6687 53.75 55.0003C53.75 55.3318 53.6183 55.6497 53.3839 55.8841C53.1495 56.1186 52.8315 56.2503 52.5 56.2503H30.5175L35.8837 61.6165C36.1114 61.8523 36.2374 62.168 36.2346 62.4958C36.2317 62.8235 36.1003 63.137 35.8685 63.3688C35.6368 63.6005 35.3232 63.732 34.9955 63.7349C34.6678 63.7377 34.352 63.6117 34.1162 63.384L26.6162 55.884Z'
                          fill='white'
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="common-front-trigger">
                    {step === 0 && <div className="common-front-trigger-inner">{frontBodyPart()}</div>}
                    {step === 1 && <div className="common-front-trigger-inner">{backBodyPart()}</div>}
                  </div>
                </div>
              )}
            </Row>
          </Container>
        </section>
      </CommonPageData>
      <Footer />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    pain_point: state.programReducer.pain_point,
    painCategories: state.programReducer.musclesCategories,
  };
};
export default connect(mapStateToProps, { getPainCategories, getPainPoint })(
  TriggerCategory
);
